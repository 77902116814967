import React, { createContext, useState } from 'react';

export const SharedDataContext = createContext();

export const SharedDataProvider = ({ children }) => {

  const [exam, setExam] = useState({});
  const [subject, setSubject] = useState({});

  return (
    <SharedDataContext.Provider value={{ exam, setExam, subject, setSubject }}>
      {children}
    </SharedDataContext.Provider>
  );
};