import React from 'react';
import { useNavigate } from 'react-router-dom';

const LogoutButton = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Perform logout actions here, such as clearing user data from local storage or making a logout API request

    // After performing logout actions, navigate to the home page
    navigate('/mock-test-portal');
  };

  return (
    <>
    <button onClick={handleLogout}>Logout</button>
    <div style={{marginBottom: '10px'}}></div>
    </>
  );
};

export default LogoutButton;
