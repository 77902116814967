import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import UserInfoComponent from './helper/UserInfoComponent';


const ExamSummaryComponent = ({ section, questionsStatus, onYesClick, onNoClick }) => {

    return (
        <>
        {!section && <UserInfoComponent />}
        <div className="col-md-12 exam-summery" style={{}}>
            <div className="panel panel-body panel-default">
                <div className="table-responsive">
                    <h3 className="text-center">Summary</h3>
                    <table className="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th>No of Questions</th>
                                <th>Answered</th>
                                <th>Not Answered</th>
                                <th>Marked for Review</th>
                                <th>Answered &amp; Marked for Review (will be considered for evaluation)</th>
                                <th>Not Visited</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="align_centre">
                                <td className="lblTotalQuestion">{questionsStatus.length}</td>
                                <td className="lblTotalSaved">{questionsStatus.filter(status => status === 'answered').length}</td>
                                <td className="lblNotAttempted">{questionsStatus.filter(status => status === 'not-answered').length}</td>
                                <td className="lblTotalMarkForReview">{questionsStatus.filter(status => status === 'marked-for-review').length}</td>
                                <td className="lblTotalSaveMarkForReview">{questionsStatus.filter(status => status === 'answered-marked-for-review').length}</td>
                                <td className="lblNotVisited">{questionsStatus.filter(status => status === 'not-visited').length}</td>
                            </tr>
                        </tbody>
                    </table>
                    <hr />
                    <div className="col-md-12 text-center">
                        <h4> Are you sure you want to submit for final marking?<br />No changes will be allowed after submission. <br /> </h4>
                        <button className="btn btn-light btn-lg btn-outline-dark" style={{ marginRight: "12px"}} id="btnYesSubmitConfirm" onClick={onYesClick}>Yes</button>
                        <button className="btn btn-light btn-lg btn-outline-dark" id="btnNoSubmitConfirm" onClick={onNoClick}>No</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default ExamSummaryComponent;
