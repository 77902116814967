const questions = [
    {
        qid: 1,
        question: "Find out the missing number",
        image: {
            src: "images/questions/cuet-2023-shift2-reasoning/q1.png",
            height: "100",
            width: "350"
        },
        choices: [
            "$40$",
            "$44$",
            "$46$",
            "$48$"
        ],
        correctAnswer: 3,
        videoLink: "https://www.youtube.com/watch?v=R1HaIfQvTB8&t=294s"
    },
    {
        qid: 2,
        question: "Ajay said, 'This girl is the wife of the grandson of my mother'. Who is Ajay to the girl?",
        choices: [
            "Father",
            "Father-in-law",
            "Cousin",
            "Brother"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=R1HaIfQvTB8&t=493s"
    },
    {
        qid: 3,
        question: "The monthly income and expenditure of a person were Rs.10,000 and Rs. 6,000 respectively. Next year, his income increased by 15% and his expenditure by 8%. Then the percentage increase in his saving is",
        choices: [
            "$20%$",
            "$25%$",
            "$25.5%$",
            "$52.5%$"
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=R1HaIfQvTB8&t=642s"
    },
    {
        qid: 4,
        question: "The area of rhombus is 120 $cm^{2}$ and length of its one diagonal is 24 cm. Find the perimeter of the rhombus (in cm)",
        choices: [
            "$50$",
            "$52$",
            "$54$",
            "$56$"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=R1HaIfQvTB8&t=958s"
    },
    {
        qid: 5,
        question: "An athlete takes as much time in running 200 m as a car takes covering 500 m. The distance covered by the athlete during the time the car covers 2 km is",
        choices: [
            "500 m",
            "600 m",
            "750 m",
            "800 m"
        ],
        correctAnswer: 3,
        videoLink: "https://www.youtube.com/watch?v=R1HaIfQvTB8&t=1285s"
    },
    {
        qid: 6,
        question: "Given below are two statements: Statements: 	I. Rabindranath Tagore wrote many poems. II. Every poet has aesthetic knowledge III. Aesthetic is a part of axiological study. Conclusion:	I. Rabindranath Tagore did different axiological studies. II. He followed the base of logic and ethics.",
        choices: [
            "If only conclusion I follow",
            "If only conclusion II follow",
            "If either conclusion I or II follow",
            "If neither conclusion I nor II follow"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=7cEHUMQlaj0&t=68s"
    },
    {
        qid: 7,
        question: "Two boys and two girls are playing cards and are seated at North. East, South and West of a table. No boy is facing East. Persons sitting opposite to each other are not of the same sex. One girl is facing South. Which directions are the boys facing?",
        choices: [
            "North and West",
            "East and North",
            "East and West",
            "East and South"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=7cEHUMQlaj0&t=693s"
    },
    {
        qid: 8,
        question: "Statement I: When a ray of white light is passed through a prism, it gets splitted into its constituents colours. This phenomenon is called dispersion of light. Statement II: Rainbow is formed due to dispersion of sunlight by water droplets. In the light of the above statements, choose the most appropriate answer from the options given below:",
        choices: [
            "Both statement I and Statement II are correct",
            "Both Statement I and Statement II are incorrect",
            "Statement I is correct but Statement II is incorrect",
            "Statement I is the incorrect but Statement II is correct"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=7cEHUMQlaj0&t=970s"
    },
    {
        qid: 9,
        question: "Find the missing term in the given number series: $–1, 0, 7, 26, 63, ?, 215, 342, …$",
        choices: [
            "$172$",
            "$142$",
            "$124$",
            "$134$"
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=7cEHUMQlaj0&t=1150s"
    },
    {
        qid: 10,
        question: "A sum of money doubles itself on simple interest in 10 years. Find the rate of interest in annum.",
        choices: [
            "$10%$",
            "$12%$",
            "$12.5%$",
            "$8%$"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=7cEHUMQlaj0&t=1318s"
    },
    {
        qid: 11,
        question: "Match List – I with List – II",
        extraDiv: '<table class="table table-bordered" border="1" cellspacing="1" cellpadding="1"><tbody><tr><td>&nbsp;List - I</td><td>&nbsp;List - II</td></tr><tr><td>&nbsp;Dog : Rabies : : Mosquito</td><td>&nbsp;Bacteria</td></tr><tr><td>&nbsp;Amnesia : Memory : : Paralysis</td><td>Liver</td></tr><tr><td>Meningitis : Brain : : Cirrhosis&nbsp;</td><td>&nbsp;Movement</td></tr><tr><td>Influenza : Virus : : Typhoid&nbsp;</td><td>Malaria&nbsp;</td></tr></tbody></table>',
        choices: [
            "A-II, B-III, C-I, D-IV",
            "A-III, B-IV, C-II, D-I",
            "A-IV, B-III, C-II, D-I",
            "A-IV, B-III, C-I, D-II"
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=VzfH20sxPPU&t=142s"
    },
    {
        qid: 12,
        question: "Count the number of triangles and square in the given figure",
        image: {
            src: "images/questions/cuet-2023-shift2-reasoning/q12.png",
            height: "125",
            width: "175"
        },
        choices: [
            "26 triangles, 5 squares",
            "28 triangles, 5 squares",
            "26 triangle, 6 squares",
            "28 triangles, 6 squares"
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=VzfH20sxPPU&t=440s"
    },
    {
        qid: 13,
        question: "Choose the figure which is different from the rest?",
        choices: [
          {
            text: "choice 1",
            src: "images/questions/cuet-2023-shift2-reasoning/q13-option1.png"
          },
          {
            text: "choice 2",
            src: "images/questions/cuet-2023-shift2-reasoning/q13-option2.png"
          },
          {
            text: "choice 3",
            src: "images/questions/cuet-2023-shift2-reasoning/q13-option3.png"
          },
          {
            text: "choice 4",
            src: "images/questions/cuet-2023-shift2-reasoning/q13-option4.png"
          }
        ],
        correctAnswer: 3,
        videoLink: "https://www.youtube.com/watch?v=VzfH20sxPPU&t=1087s"
    },
    {
        qid: 14,
        question: "Which of the following will be the next figure in sequence?",
        image: {
            src: "images/questions/cuet-2023-shift2-reasoning/q14.png",
            height: "80",
            width: "300"
        },
        choices: [
            {
              text: "choice 1",
              src: "images/questions/cuet-2023-shift2-reasoning/q14-option1.png"
            },
            {
              text: "choice 2",
              src: "images/questions/cuet-2023-shift2-reasoning/q14-option2.png"
            },
            {
              text: "choice 3",
              src: "images/questions/cuet-2023-shift2-reasoning/q14-option3.png"
            },
            {
              text: "choice 4",
              src: "images/questions/cuet-2023-shift2-reasoning/q14-option4.png"
            }
          ],
        correctAnswer: 3,
        videoLink: "https://www.youtube.com/watch?v=VzfH20sxPPU&t=1319s"
    },
    {
        qid: 15,
        question: "Observe the following premises and select the correct conclusion: Major Premise: All engineers are innovative. Minor Premise: All students are engineers. Conclusions:",
        choices: [
            "All innovative are students",
            "All students are innovative",
            "No innovative are students",
            "No engineers are students"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=VzfH20sxPPU&t=1462s"
    },
    {
        qid: 16,
        question: "Find the missing term in the given series: $4, 10, ?, 82, 244, 730$.",
        choices: [
            "$24$",
            "$28$",
            "$77$",
            "$218$"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=-OXOp5iBBas&t=137s"
    },
    {
        qid: 17,
        question: "Match List – I with List – II",
        tableData: {
            columnHeaders: ["List - I", "List - II"],
            rows: [
                ["$8 : 81 : : 64 : ?$", "$290$"],
                ["$182 : ? : : 210 : 380$", "$132$"],
                ["$42 : 56 : : 110 : ?$", "$342$"],
                ["$48 : 122 : : 168 : ?$", "$625$"],
            ]
        },
        choices: [
            "A-II, B-I, C-IV, D-III",
            "A-III, B-II, C-I, D-IV",
            "A-II, B-III, C-IV, D-I",
            "A-IV, B-III, C-II, D-I"
        ],
        correctAnswer: 3,
        videoLink: "https://www.youtube.com/watch?v=-OXOp5iBBas&t=332s"
    },
    {
        qid: 18,
        question: "There are eight members in the family. Bravo and Priya are siblings. Angel is Kajal’s granddaughter, Kajal who is Priya’s mother–in–law. Ziva is a married woman and is older than Tim. Tim is the son of Sam who is the brother–in–law of Bravo. Smith is eldest male in the family. Angel is not Ziva’s daughter. So how is Bravo related to Ziva?",
        choices: [
            "Son",
            "Husband",
            "Brother–in–law",
            "Son–in–law"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=-OXOp5iBBas&t=837s"
    },
    {
        qid: 19,
        question: "Find out the trend and choose the missing character from given alternative",
        tableData: {
            columnHeaders: [],
            rows: [
                ["2", "5", "10"],
                ["17", "?", "37"],
                ["50", "65", "82"]
            ]
        },
        choices: [
            "$20$",
            "$26$",
            "$27$",
            "$32$"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=-OXOp5iBBas&t=1181s"
    },
    {
        qid: 20,
        question: "Consider the adjoining diagram: What is the minimum number of different colours required to paint the figure such that no two adjacent regions have the same colour?",
        image: {
            src: "images/questions/cuet-2023-shift2-reasoning/q20.png",
            height: "150",
            width: "150"
        },
        choices: [
            "$3$",
            "$4$",
            "$5$",
            "$6$"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=-OXOp5iBBas&t=1264s"
    }
];

export default questions;