import React, { useEffect } from 'react';
import Countdown from 'react-countdown';

const CountdownComponent = ({ data, setData, handleTimeUp }) => {

    const getLocalStorageValue = (s) => localStorage.getItem(s);
    const wantedDelay = 120000;

    // Renderer callback with condition
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            //handleTimeUp();
            // Render a complete state
            return (<div>Time's up</div>)
        } else {
            // Render a countdown
            return (
                <div className='question-numbers' style={{ marginLeft: "24px" }}>
                    <div style={{ marginRight: "10px", fontSize: "16px", fontWeight: "bold" }}>
                        Remaining Time:
                    </div>
                    <div className="time-started" style={{ fontSize: "16px" }}>
                        {hours.toString().padStart(2, '0')}:{minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
                    </div>
                </div>
            );
        }
    };

    useEffect(() => {
        const savedDate = getLocalStorageValue("end_date");
        if (savedDate != null && !isNaN(savedDate)) {
            const currentTime = Date.now();
            const delta = parseInt(savedDate, 10) - currentTime;

            //Do you reach the end?
            if (delta > wantedDelay) {
            //Yes we clear uour saved end date
            if (localStorage.getItem("end_date").length > 0)
                localStorage.removeItem("end_date");
            } else {
            //No update the end date  
            setData({ date: currentTime, delay: delta });
            }
        }
    }, [setData]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            // Custom logic to handle the refresh
            // Display a confirmation message or perform necessary actions
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    return (
        <Countdown
            date={data.date + data.delay}
            renderer={renderer}
            onStart={(delta) => {
                // Save the end date
                if (localStorage.getItem('end_date') == null)
                    localStorage.setItem('end_date', JSON.stringify(data.date + data.delay));
            }}
            onComplete={() => {
                if (localStorage.getItem('end_date') != null)
                    localStorage.removeItem('end_date');
                handleTimeUp();
            }}
        />
    );
};

export default CountdownComponent;
