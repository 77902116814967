const questions = [
    {
        qid: 1,
        question: "If $(25)^{7.5} \\times(5)^{2.5} \\div(125)^{1.5}=5^{x}$, then $x=$",
        choices: [
          "$13$",
          "$8.5$",
          "$16$",
          "$17.5$"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=i_DFJpObkwo&t=261s"
      },
      {
        qid: 2,
        question: "If $A=\\left[\\begin{array}{cc}\\cos B & -\\sin B \\\\ \\sin B & \\cos B\\end{array}\\right]$, then $A+A^{T}=I$ for $B$ equal to",
        choices: [
          "$\\frac{\\pi}{3}$",
          "$\\frac{\\pi}{6}$",
          "$\\pi$",
          "$\\frac{3 \\pi}{2}$"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=i_DFJpObkwo&t=401s"
      },
      {
        qid: 3,
        question: "The number of 7-digit numbers whose sum of the digits equals to 10 and which is formed by using the digits 1,2 and 3 only is",
        choices: [
          "$55$",
          "$66$",
          "$77$",
          "$88$"
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=i_DFJpObkwo&t=540s"
      },
      {
        qid: 4,
        question: "If from each of the three boxes containing 3 white and 1 black, 2 white and 2 black, 1 white and 3 black balls, one ball is drawn at random, then the probability that 2 white and 1 black balls will be drawn is",
        choices: [
          "$\\frac{13}{32}$",
          "$\\frac{1}{4}$",
          "$\\frac{1}{32}$",
          "$\\frac{3}{16}$"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=i_DFJpObkwo&t=887s"
      },
      {
        qid: 5,
        question: "Let $f(x)=|| x \\mid-1|$, then point(s) where $f(x)$ is not differentiable is (are):",
        choices: [
          "$0, \\pm 1$",
          "$\\pm 1$",
          "$0$",
          "$1$"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=i_DFJpObkwo&t=1331s"
      },
      {
        qid: 6,
        question: "Let $f:[2, \\infty) \\rightarrow \\mathbb{R}$ be the function defined by $f(x)=x^{2}-4 x+5$, then the range of $f$",
        choices: [
          "$\\mathbb{R}$",
          "$[1, \\infty)$",
          "$[4, \\infty)$",
          "$(-\\infty, 0]$"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=wnde9pbvjnQ&t=82s"
      },
      {
        qid: 7,
        question: "The function $f(x)=\\frac{\\ln (1+a x)-\\ln (1-b x)}{x}$ is not defined at $x=0$. What value may be assigned to $f$ at $x=0$, so that it is continuous?",
        choices: [
          "$a+b$",
          "$a-b$",
          "$b-a$",
          "$\\ell n a+\\ell n b$"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=wnde9pbvjnQ&t=388s"
      },
      {
        qid: 8,
        question: "The area enclosed between the graphs of $y=x^{3}$ and the lines $x=0, y=1, y=8$ is",
        choices: [
          "$7$",
          "$12$",
          "$\\frac{45}{4}$",
          "$\\frac{21}{8}$"
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=wnde9pbvjnQ&t=680s"
      },
      {
        qid: 9,
        question: "If the vertices of a triangle are $O(0,0), A(a, 0)$ and $B(0, a)$. Then, the distance between its circum-center and orthocenter is",
        choices: [
          "$\\frac{a}{2}$",
          "$\\frac{a}{\\sqrt{2}}$",
          "$\\sqrt{2} a$",
          "$\\frac{a}{4}$"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=wnde9pbvjnQ&t=949s"
      },
      {
        qid: 10,
        question: "The straight lines $x+y=0,3 x+y-4=0$ and $x+3 y-4=0$ form a triangle which is",
        choices: [
          "Right- Angled",
          "Equilateral",
          "Isosceles",
          "Isosceles and right angled"
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=wnde9pbvjnQ&t=1110s"
      },
      {
        qid: 11,
        question: "If one of the lines of $a x^{2}+2 h x y+b y^{2}=0$ bisects the angle between the axes in the first quadrant, then",
        choices: [
          "$h^{2}-a b=0$",
          "$h^{2}+a b=0$",
          "$(a+b)^{2}=h^{2}$",
          "$(a+b)^{2}=4 h^{2}$"
        ],
        correctAnswer: 3,
        videoLink: "https://www.youtube.com/watch?v=AtaspEmZMxY&t=85s"
      },
      {
        qid: 12,
        question: "What is the value of $\\left(\\tan ^{2}(90-\\theta)-\\sin ^{2}(90-\\theta)\\right) \\operatorname{cosec}(90-\\theta) \\cot ^{2}(90-\\theta)$",
        choices: [
          "$0$",
          "$1$",
          "$-1$",
          "$2$"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=AtaspEmZMxY&t=322s"
      },
      {
        qid: 13,
        question: "If $A+B=45^{\\circ}$, then $(1+\\tan A)(1+\\tan B)$ is equal to",
        choices: [
          "$4$",
          "$2$",
          "$3$",
          "$1$"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=AtaspEmZMxY&t=503s"
      },
      {
        qid: 14,
        question: "If $\\vec{a}$ and $\\vec{b}$ are two unit vectors such that $\\vec{a}+2 \\vec{b}$ and $5 \\vec{a}-4 \\vec{b}$ are perpendicular to each other, then the angle between $\\vec{a}$ and $\\vec{b}$ is",
        choices: [
          "$45^{\\circ}$",
          "$60^{\\circ}$",
          "$\\cos ^{-1}\\left(\\frac{1}{3}\\right)$",
          "$\\cos ^{-1}\\left(\\frac{2}{7}\\right)$"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=AtaspEmZMxY&t=660s"
      },
      {
        qid: 15,
        question: "Let $\\vec{a}=\\hat{i}-\\hat{j}$ and $\\vec{b}=\\hat{i}+\\hat{j}+\\hat{k}$ and $\\vec{c}$ be a vector such that $(\\vec{a} \\times \\vec{c})+\\vec{b}=0$ and $\\vec{a} \\cdot \\vec{c}=4$, then $|\\vec{c}|^{2}$ is equal to",
        choices: [
          "$8$",
          "$\\frac{19}{2}$",
          "$9$",
          "$\\frac{17}{2}$"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=AtaspEmZMxY&t=911s"
      },
      {
        qid: 16,
        question: "If $\\vec{a}, \\vec{b}, \\vec{c}$ and $\\vec{d}$ are the unit vectors such that $(\\vec{a} \\times \\vec{b}) \\cdot(\\vec{c} \\times \\vec{d})=1$ and $(\\vec{a} \\cdot \\vec{c})=\\frac{1}{2}$, then",
        choices: [
          "Only $\\vec{a}, \\vec{b}, \\vec{c}$ are non-coplanar",
          "Only $\\vec{a}, \\vec{b}, \\vec{d}$ are non-coplanar",
          "Both $\\vec{a}, \\vec{b}, \\vec{c}$ and $\\vec{a}, \\vec{b}, \\vec{d}$ are non-coplanar",
          "Both $\\vec{a}, \\vec{b}, \\vec{c}$ and $\\vec{a}, \\vec{b}, \\vec{d}$ are coplanar"
        ],
        correctAnswer: 3,
        videoLink: "https://www.youtube.com/watch?v=TasbGZ1AIV8&t=37s"
      },
      {
        qid: 17,
        question: "Let $A=\\{1,2,3\\}$ and consider the relation $R=\\{(1,1),(2,2),(3,3),(1,2),(2,3),(1,3)\\}$, then $R$ is",
        choices: [
          "Reflexive but not symmetric",
          "Reflexive but not transitive",
          "Symmetric and Transitive",
          "Equivalence relation"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=TasbGZ1AIV8&t=555s"
      },
      {
        qid: 18,
        question: "A spring is being moved up and down. An object is attached to the end of the spring that undergoes a vertical displacement. The displacement is given by the equation $y=3.50 \\sin t+1.20 \\sin 2 t$. Find the first two values of $t$ (in seconds) for which $y=0$",
        choices: [
          "$t=0, \\frac{\\pi}{4}$",
          "$t=0, \\frac{\\pi}{2}$",
          "$t=0, \\pi$",
          "$t=0, \\frac{\\pi}{6}$"
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=TasbGZ1AIV8&t=834s"
      },
      {
        qid: 19,
        question: "A ball is thrown off the edge of a building at an angle of $60^{\\circ}$ and with an initial velocity of 5 meters per seconds. The equation that represents the horizontal distance of the ball $x$ is $x=v_{0}(\\cos \\theta) t$, where $v_{0}$ is the initial velocity, $\\theta$ is the angle at which it is thrown and $t$ is the time in seconds. About how far will the ball travel in 10 seconds?",
        choices: [
          "$25 \\sqrt{3} \\mathrm{~m}$",
          "$50 \\sqrt{2} \\mathrm{~m}$",
          "$25 \\mathrm{~m}$",
          "$\\frac{25}{\\sqrt{3}} m$"
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=TasbGZ1AIV8&t=1019s"
      },
      {
        qid: 20,
        question: "Let $n$ be a positive integer and $R=\\{(a, b) \\in \\mathbb{Z} \\times \\mathbb{Z} \\mid a-b=n m$ for some $m \\neq 0 \\in \\mathbb{Z}\\}$. Then $R$ is",
        choices: [
          "Reflexive on $\\mathbb{Z}$",
          "Symmetric",
          "Transitive",
          "Equivalence relation on $\\mathbb{Z}$"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=TasbGZ1AIV8&t=1141s"
      },
      {
        qid: 21,
        question: "The $a, b, c, d$ are in G.P. and are in ascending order such that $a+d=112$ and $b+c=48$. If the G.P. is continued with ' $a$ ' as the first term, then the sum of the first six terms is",
        choices: [
          "$1156$",
          "$1256$",
          "$1356$",
          "$1456$"
        ],
        correctAnswer: 3,
        videoLink: "https://www.youtube.com/watch?v=MykDm3P0_0g&t=74s"
      },
      {
        qid: 22,
        question: "Given below are two statements: Statement I: If $A \\subset B$ then $B$ can be expressed as $B=A \\cup(\\bar{A} \\cap B)$ and $P(A)>P(B)$. Statement II : If $A$ and $B$ are independent events, then $(A$ and $\\bar{B}),(\\bar{A}$ and $B)$ and $(\\bar{A}$ and $\\bar{B})$ are also independent. In the light of the above statements, choose the most appropriate answer from the options given below.",
        choices: [
          "Both statement I and Statement II are true",
          "Both Statement I and Statement II are false",
          "Statement I is true but Statement II is false",
          "Statement I is false but Statement II is true"
        ],
        correctAnswer: 3,
        videoLink: "https://www.youtube.com/watch?v=MykDm3P0_0g&t=400s"
      },
      {
        qid: 23,
        question: "If $\\vec{a}, \\vec{b}$ and $\\vec{c}$ are unit vectors, then $|\\vec{a}-\\vec{b}|^{2}+|\\vec{b}-\\vec{c}|^{2}+|\\vec{c}-\\vec{a}|^{2}$ does not exceed",
        choices: [
          "$4$",
          "$9$",
          "$8$",
          "$6$"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=MykDm3P0_0g&t=669s"
      },
      {
        qid: 24,
        question: "If $\\vec{a}=\\hat{i}+\\hat{j}+\\hat{k}, \\vec{a} \\cdot \\vec{b}=1$ and $\\vec{a} \\times \\vec{b}=\\hat{j}-\\hat{k}$, then $\\vec{b}$ is equal to",
        choices: [
          "$\\hat{i}-\\hat{j}+\\hat{k}$",
          "$2 \\hat{j}-\\hat{k}$",
          "$\\hat{i}$",
          "$2 \\hat{i}$"
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=MykDm3P0_0g&t=1009s"
      },
      {
        qid: 25,
        question: "Consider the diagram given below and the following two Statements: Statement I: Events $A$ and $B$ can be expressed as: $A=(A \\cap \\bar{B}) \\cup Y, B=(A \\cap B) \\cup Z$ Statement II: Events $A$ and $B$ can be expressed as: $A=X-Y, B=Y+Z$ In the light of the above statements, choose the most appropriate answer from the options given below:",
        image: {
          src: "images/questions/cuet-2022-math/q25.png",
          height: "150",
          width: "250"
        },
        choices: [
          "Both Statement I and Statement II are True",
          "Both Statement I and Statement II are False",
          "Statement I is True but Statement II is False",
          "Statement I is False but Statement II is True"
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=MykDm3P0_0g&t=1462s"
      },
      {
        qid: 26,
        question: "Given below are two statements: One is labelled as Assertion A and the other is labelled as Reason R. Assertion A: In a class of 40 students 22 drink Sprite, 10 drink Sprite but not Pepsi. Then the number of students who drink both Sprite and Pepsi is 15. Reason R: For any two finite sets $\\mathrm{A}$ and $\\mathrm{B}, n(A)=n(A-B)+n(A \\cap B)$. In the light of the above statements, choose the most appropriate answer from the options given below.",
        choices: [
          "Both $\\mathrm{A}$ and $\\mathrm{R}$ are correct and $\\mathrm{R}$ is the correct explanation of $\\mathrm{A}$.",
          "Both $A$ and $R$ are correct but $R$ is not the correct explanation of $A$.",
          "$A$ is correct but $R$ is not correct.",
          "A is not correct but $\\mathrm{R}$ is correct."
        ],
        correctAnswer: 3,
        videoLink: "https://www.youtube.com/watch?v=n0rRzRfiXVA&t=39s"
      },
      {
        qid: 27,
        question: "Match the List",
        tableData: {
          columnHeaders: ["List - I", "List - II"],
          rows: [
              ["A. If $4^{\\text {th }}$ term of a G.P. is square of its seconds term, and its first term is <br> 3, then common ratio is", "I. 5"],
              ["B. The first term of an A.P. is 5 and the last term is 45 and the sum of the <br> terms is 400. The number of terms is", "II. $-\\frac{5}{2}$"],
              ["C. The sum of three numbers which are in A.P. in 27 and sum of their <br> squares is 293. Then the common difference is", "III. 16"],
              ["D. The fourth and $54^{\text {th }}$ terms of an A.P. are, respectively, 64 and -61. <br> The common difference is", "IV. 3"],
          ]
        },
        choices: [
          "$A-IV, B-III, C-I, D-II$",
          "$A-III, B-II, C-I, D-IV$",
          "$A-II, B-III, C-I, D-IV$",
          "$A-II, B-I, C-III, D-IV$"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=n0rRzRfiXVA&t=271s"
      },
      {
        qid: 28,
        question: "Given below are two statements: One is labelled as Assertion $A$ and the other is labelled as Reason $R$. Assertion A: The system of equations $x+y+z=4,2 x-y+2 z=5, x-2 y-z=3$ has unique solution. Reason R: If $A$ is $3 \\times 3$ matrix and $B$ is a $3 \\times 1$ non-zero column matrix, then the equation $A X=B$ has unique solution if $A$ is non-singular. In the light of the above statements, choose the most appropriate answer from the options given below:",
        choices: [
          "Both $A$ and $R$ are correct and $R$ is the correct explanation of $A$.",
          "Both $A$ and $R$ are correct but $R$ is not the correct explanation of $A$.",
          "$A$ is correct but $R$ is not correct.",
          "$A$ is not correct but $R$ is correct."
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=n0rRzRfiXVA&t=809s"
      },
      {
        qid: 29,
        question: "Consider the diagram given below and the following two statements: Statement I: Regions $X, Y$ and $Z$ can be expressed as $A \\cap \\bar{B}, A \\cap B$ and $\\bar{A} \\cap B$ respectively. Statement I: $P(Y)=P(A)-P(X)=P(B)-P(Z)$. In the light of the above statements. Choose the correct answer from the options given below:",
        image: {
          src: "images/questions/cuet-2022-math/q25.png",
          height: "150",
          width: "250"
        },
        choices: [
          "Both Statement I and Statement II are True",
          "Both Statement I and Statement II are False",
          "Statement I is True but Statement II is False",
          "Statement I False but Statement II is True"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=n0rRzRfiXVA&t=1049s"
      },
      {
        qid: 30,
        question: "In a class there are 400 students, the following table shows the number of students studying one or more of the subjects: A. The number of students who study only Mathematics is 100 . B. The number of students who study only Physics is 40 . C. The number of students who study only Chemistry is 40 . D. The number of students who do not study Mathematics, Physics and Chemistry is 70 . Choose the correct answer from the options given below:",
        tableData: {
          columnHeaders: ["Subject", "Number of Students"],
          rows: [
            ["Mathematics", "250"],
            ["Physics", "150"],
            ["Chemistry", "100"],
            ["Mathematics and Physics", "100"],
            ["Mathematics and Chemistry", "60"],
            ["Physics and Chemistry", "40"],
            ["Mathematics, Physics and Chemistry", "30"]
          ]
        },
        choices: [
          "B and D Only",
          "A and B Only",
          "A Only",
          "C Only"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=n0rRzRfiXVA&t=1178s"
      },
      {
        qid: 31,
        question: "The arithmetic means of two observations is 125 and their geometric mean is 60 . Find the harmonic mean of the two observations.",
        choices: [
          "$4.17$",
          "$8.35$",
          "$28.8$",
          "$57.6$"
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=QmDDatqPGrk&t=41s"
      },
      {
        qid: 32,
        question: "The arithmetic mean and standard deviation of series of 20 times were calculated by a student as $20 \\mathrm{~cm}$ and $5 \\mathrm{~cm}$ respectively. But while calculating them an item 15 was misread as 30 . Find the correct standard deviation.",
        choices: [
          "$4.10$",
          "$4.40$",
          "$4.54$",
          "$4.66$"
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=QmDDatqPGrk&t=143s"
      },
      {
        qid: 33,
        question: "Given the marks of 25 students in the class as $\\left\\{m_{1}, m_{2}, m_{3}, \\ldots, m_{25}\\right\\}$. Marks lie in the range of $[1-100]$ and $\\bar{m}$ is the mean. Which of the following quantity has the value zero?",
        choices: [
          "$\\sum_{i=1}^{25}\\left|m_{i}-\\bar{m}\\right|$",
          "$\\sum_{i=1}^{25}\\left(m_{i}-\\bar{m}\\right)$",
          "$\\sum_{i=1}^{25}\\left(m_{i}-\\bar{m}\\right)^{2}$",
          "$\\sum_{i=1}^{25} \\frac{m_{i}}{\\bar{m}}$"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=QmDDatqPGrk&t=689s"
      },
      {
        qid: 34,
        question: "The terms $1, \\log _{y}(x), \\log _{z}(y)$ and $-15 \\log _{x}(z)$ are in A.P. The common difference of A.P. is",
        choices: [
          "$2$",
          "$-2$",
          "$\\frac{1}{2}$",
          "$-\\frac{1}{2}$"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=QmDDatqPGrk&t=785s"
      },
      {
        qid: 35,
        question: "The terms $1, \\log _{y}(x), \\log _{z}(y)$ and $-15 \\log _{x}(z)$ are in A.P. The value of $x y$ is",
        choices: [
          "$1$",
          "$-1$",
          "$z^{2}$",
          "$z^{3}$"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=QmDDatqPGrk&t=1175s"
      },
      {
        qid: 36,
        question: "The terms $1, \\log _{y}(x), \\log _{z}(y)$ and $-15 \\log _{x}(z)$ are in A.P. The value of $yz$ is",
        choices: [
          "$x$",
          "$x^{2}$",
          "$z^{-2}$",
          "$z^{-3}$"
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=r4eF49h_Htw&t=55s"
      },
      {
        qid: 37,
        question: "Consider $n$ events $E_{1}, E_{2}, \\ldots, E_{n}$ with respectively probabilities $p_{1}, p_{2}, \\ldots, p_{n}$. If $P\\left(E_{1}, E_{2} \\ldots E_{n}\\right)=\\prod_{i=1}^{n} p_{i}$, then",
        choices: [
          "The events are mutually exclusive",
          "The events are independent",
          "The events are dependent",
          "The events are mutually exclusive and independent"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=r4eF49h_Htw&t=213s"
      },
      {
        qid: 38,
        question: "Given $a$ set of events $E_{1}, E_{2} \\ldots E_{n}$ defined on the sample space $S$ such that $(i) \\forall i$ and $j, i \\neq j, E_{i} \\cap E_{j}=\\phi$ (ii) $\\bigcup_{i=1}^{n} E_{i}=S$ (iii) $P\\left(E_{i}\\right)>0, \\forall i=1,2, \\ldots, n$. Then the events are",
        choices: [
          "Pair-wise disjoint and exhaustive",
          "Pair-wise disjoint and independent",
          "Dependent and mutually exclusive",
          "Independent and mutually exclusive"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=r4eF49h_Htw&t=287s"
      },
      {
        qid: 39,
        question: "4 Indians, 3 Americans and 2 Britishers are to be arranged around a round table. The number of ways of arranging them is",
        choices: [
          "$9!$",
          "$\\frac{9 !}{2}$",
          "$8!$",
          "$\\frac{8 !}{2}$"
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=r4eF49h_Htw&t=361s"
      },
      {
        qid: 40,
        question: "4 Indians, 3 Americans and 2 Britishers are to be arranged around a round table. The number of ways of arranging them so that the two Britishers should never come together is",
        choices: [
          "$7 ! \\times 2$ !",
          "$6 ! \\times 2$ !",
          "$7 !$",
          "$6 !{ }^{6} P_{2}$"
        ],
        correctAnswer: 3,
        videoLink: "https://www.youtube.com/watch?v=r4eF49h_Htw&t=463s"
      },
      {
        qid: 41, 
        question: "4 Indians, 3 Americans and 2 Britishers are to be arranged around a round table. The number of ways of arranging them so that the three Americans should sit together is",
        choices: [
          "$7 ! \\times 3$ !",
          "$6 ! \\times 3$ !",
          "$6 !{ }^{6} P_{3}$",
          "$6 !^{7} P_{3}$"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=4_OjfyzHxkU&t=37s"
      },
      {
        qid: 42,
        question: "Given three identical boxes $B_{1}, B_{2}$ and $B_{3}$ each containing two balls. $B_{1}$ contains two golden balls, $B_{2}$ contains two silver balls and $B_{3}$ contains one silver and one golden ball. Conditional probabilities that the golden ball is drawn from $B_{1}, B_{2}, B_{3}$ are respectively",
        choices: [
          "$0,1, \\frac{1}{2}$",
          "$\\frac{1}{2}, 0,1$",
          "$1,0, \\frac{1}{2}$",
          "$1, \\frac{1}{2}, 0$"
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=4_OjfyzHxkU&t=144s"
      },
      {
        qid: 43,
        question: "Match List - I with List - II. Choose the correct answer from the options given below:",
        tableData: {
          columnHeaders: ["List - I", "List - II"],
          rows: [
              ["A. In a G.P., the third term is 24 and $6^{\\text {th }}$ term is 192. The common ratio is", "I. 78"],
              ["B. Let $S_{n}$ denotes the sum of first $n$ terms of an A.P. If $S_{2 n}=3 S_{n^{\\prime}}$ then $\\frac{S_{3 n}}{S_{n}}$ equals to", "II. 6"],
              ["C. The sum of 3 terms of a G.P. is $\\frac{13}{12}$ and their product is -1 . The first term is", "III. -1"],
              ["D. The least value of $n$ for which the sum $3+6+9+\\ldots+n$ is greater than 1000 is", "IV. 2"],
          ]
        },
        choices: [
          "A-III, B-I, C-II, D-IV",
          "A-III, B-IV, C-I, D-II",
          "A-IV, B-II, C-III, D-I",
          "A-IV, B-III, C-II, D-I"
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=4_OjfyzHxkU&t=304s"
      },
      {
        qid: 44,
        question: "Match List - I with List - II: $\\omega \\neq 1$ is a cube root of unity. Choose the correct answer from the given options.",
        tableData: {
          columnHeaders: ["List - I", "List - II"],
          rows: [
              ["A. The value of $\\frac{1}{9}(1-\\omega)\\left(1-\\omega^{2}\\right)\\left(1-\\omega^{4}\\right)\\left(1-\\omega^{8}\\right)$ is", "I. 0"],
              ["B. $\\omega\\left(1+\\omega-\\omega^{2}\\right)^{7}$ is equal to", "II. 1"],
              ["C. The least positive integer $n$-such that $\\left(1+\\omega^{2}\\right)^{n}=\\left(1+\\omega^{4}\\right)^{n}$ is", "III. -128"],
              ["D. $\\left(1+\\omega+\\omega^{2}\\right)$ is equal to", "IV. 3"],
          ]
        },
        choices: [
          "A-II, B-III, C-IV, D-I",
          "A-II, B-III, C-I, D-IV",
          "A-III, B-II, C-IV, D-I",
          "A-III, B-II, C-I, D-IV"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=4_OjfyzHxkU&t=1075s"
      },
      {
        qid: 45,
        question: "Match List - I with List - II. Then, choose the correct answer from the given options.",
        tableData: {
          columnHeaders: ["List - I", "List - II"],
          rows: [
              ["A. $\\log _{4}\\left(\\log _{3}(81)\\right)=$", "I. 0"],
              ["B. $3^{4 \\log _{9}(7)}=7^{k}$, then $k=$", "II. 3"],
              ["C. $2^{\\log _{3}(5)}-5^{\\log _{3}(2)}=$", "III. 1"],
              ["D. $\\log _{2}\\left[\\log _{2}(256)\\right]=$", "IV. 2"],
          ]
        },
        choices: [
          "A-I, B-III, C-II, D-IV",
          "A-I, B-III, C-IV, D-II",
          "A-III, B-IV, C-II, D-I",
          "A-III, B-IV, C-I, D-II"
        ],
        correctAnswer: 3,
        videoLink: "https://www.youtube.com/watch?v=4_OjfyzHxkU&t=1611s"
      }
];

export default questions;  