const questions = [
    {
        qid: 1,
        question: "A triangle can have:	",
        choices: [
            "all acute angles",
            "two obtuse angles",
            "two right angles",
            "one obtuse angle and one right angle"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=tbxAKqbwzpg&t=518s"
    },
    {
        qid: 2,
        question: "If the mean of $18$, $19$, $x$, $25$ and $26$ is $21$, then find the mean of $5$, $11$, $13$, $19$ and $(3x – 1)$:",
        choices: [
            "$21$",
            "$20.6$",
            "$19.6$",
            "$18.8$"
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=tbxAKqbwzpg&t=633s"
    },
    {
        qid: 3,
        question: "Find a single discount equivalent to successive discounts of 10 %, 20 % and 25 %:	",
        choices: [
            "$55 %$",
            "$54 %$",
            "$46 %$",
            "$52 %$"
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=tbxAKqbwzpg&t=814s"
    },
    {
        qid: 4,
        question: "A man is walking at a speed of 6 km/h. After every km he takes rest for 6 minutes. How much time will he take to cover a distance of 24 km?",
        choices: [
            "6 h 24 min",
            "6 h 18 min",
            "5 h 18 min",
            "4 h 24 min"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=tbxAKqbwzpg&t=1402s"
    },
    {
        qid: 5,
        question: "If $\\frac{\\sin \\theta+\\cos \\theta}{\\sin \\theta-\\cos \\theta}=\\frac{17}{7}$, then find the value of $\\left(\\sin^4 \\theta-\\cos^4 \\theta\\right)$",
        choices: [
            "$119$",
            "$\\frac{12}{5}$",
            "$\\frac{119}{169}$",
            "$\\frac{2}{3}$",
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=tbxAKqbwzpg&t=1569s"
    },
    {
        qid: 6,
        question: "Identify which of the given conclusions follows from the given statements: Statements: 	I. No perfume is a fragrance. II. Some perfume are deodorants. III. All deodorants are cologne. Conclusions:	I. At least some perfume are colognes. II. No fragrance is deodorant.",
        choices: [
            "only conclusion I is true",
            "only conclusion II is true",
            "Both conclusion I and II are true",
            "Neither conclusion I nor II is true"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=SGCYrXRXNUU&t=89s"
    },
    {
        qid: 7,
        question: "Read the following information carefully and answer the question given below: A is the son of B.  C, B’s Sister, has a son D and a daughter E. F is the maternal uncle of D. How is E related to F?",
        choices: [
            "Sister",
            "Neice",
            "Daughter",
            "wife"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=SGCYrXRXNUU&t=550s"
    },
    {
        qid: 8,
        question: "Figure (x) is embedded in which one of the four alternatives:",
        image: {
            src: "images/questions/cuet-2023-shift3-reasoning/q8.png",
            height: "100",
            width: "100"
        },
        choices: [
            {
              text: "choice 1",
              src: "images/questions/cuet-2023-shift3-reasoning/q8-option1.png"
            },
            {
              text: "choice 2",
              src: "images/questions/cuet-2023-shift3-reasoning/q8-option2.png"
            },
            {
              text: "choice 3",
              src: "images/questions/cuet-2023-shift3-reasoning/q8-option3.png"
            },
            {
              text: "choice 4",
              src: "images/questions/cuet-2023-shift3-reasoning/q8-option4.png"
            }
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=SGCYrXRXNUU&t=765s"
    },
    {
        qid: 9,
        question: "Choose the group of letters which is different from others: POCG, BUDX, ATRG, ZIKL, FQMV",
        choices: [
            "POCG",
            "BUDX",
            "FQMV",
            "ZIKL"
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=SGCYrXRXNUU&t=954s"
    },
    {
        qid: 10,
        question: "Given below are two statements: One is labelled as Assertion (A) and the other is labelled as Reason (R). Assertion (A): Marathas emerged as the strongest native power in India after the decline of Mughal Empire. Reasons (R):	Marathas were the first to have a clear concept of United Indian Nation. In the light of the above statements, choose the most appropriate answer from the options given below:",
        choices: [
            "Both (A) and (R) are correct and (R) is the correct explanation of (A)",
            "Both (A) and (R) are correct but (R) is NOT the correct explanation of (A)",
            "(A) is correct but (R) is not correct",
            "(A) is not correct but (R) is correct"
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=SGCYrXRXNUU&t=1073s"
    },
    {
        qid: 11,
        question: "The sum of two digits of a number is 10. On reversing the digits of the number, the number is decreased by 36. Find the number.",
        choices: [
            "$73$",
            "$37$",
            "$28$",
            "$46$"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=71KXJnNLgtQ&t=63s"
    },
    {
        qid: 12,
        question: "If $A = 26$, $SUN = 27$, then $CAT=?$",
        choices: [
            "$24$",
            "$27$",
            "$57$",
            "$58$"
        ],
        correctAnswer: 2 ,
        videoLink: "https://www.youtube.com/watch?v=71KXJnNLgtQ&t=375s"
    },
    {
        qid: 13,
        question: "Find the sum of two consecutive numbers in which four times are first number is 12 more than the thrice of the second number",
        choices: [
            "$27$",
            "$31$",
            "$24$",
            "$25$"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=71KXJnNLgtQ&t=856s"
    },
    {
        qid: 14,
        question: "A is the son of B. C, B’s sister, has a son D and a daughter E. F is the maternal uncle of D.",
        choices: [
            "A is the cousin of D",
            "E is the niece of F",
            "F has 2 Nephews",
            "C is the mother of E"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=71KXJnNLgtQ&t=959s"
    },
    {
        qid: 15,
        question: "Match List – I with List – II. Choose the correct answer from the options given below.",
        tableData: {
            columnHeaders: ["List - I", "List - II"],
            rows: [
                ["ADE : FGJ : : KNO : ?", "CLL"],
                ["CAT : DDY : : BIG : ?", "AKB"],
                ["AKJ : GNM : : EMD : ?", "PJG"],
                ["HNP : PDA : : DLP : ?", "PQT"],
            ]
        },
        choices: [
            "(A) – (IV), (B) – (I), (C) – (II), (D) – (III)",
            "(A) – (IV), (B) – (III), (C) – (II), (D) – (I)",
            "(A) – (I), (B) – (II), (C) – (III), (D) – (IV)",
            "(A) – (I), (B) – (IV), (C) – (III), (D) – (II)"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=71KXJnNLgtQ&t=1225s"
    }
];

export default questions;