import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { SharedDataContext } from '../context/SharedDataContext';
import axios from '../api/axios';
import PurchaseModal from './PurchaseComponent';
import LoadingComponent from './helper/LoadingComponent';
import BackButton from './buttons/BackButtonComponent';

const ExamSelectorComponent = ({ setMockSelected }) => {

    const { user } = useContext(UserContext);
    const { exam, setExam, subject, setSubject } = useContext(SharedDataContext);

    const [exams, setExams] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [mocks, setMocks] = useState([]);

    const [subjectDropdownDisabled, setSubjectDropdownDisabled] = useState(true);
    const [mockDropdownDisabled, setMockDropdownDisabled] = useState(true);

    const [selectedMock, setSelectedMock] = useState({});

    const [showBuyNowModal, setShowBuyNowModal] = useState(false);
    const [showLoadingOnBuyNowModal, setShowLoadingOnBuyNowModal] = useState(false);

    const fetchExams = () => {
        axios.get('/exams/list')
            .then(response => {
                setExams(response.data);
            })
            .catch(error => {
                console.error('Error fetching exams:', error);
            });
    };

    const fetchSubjects = () => {
        axios.get(`/exams/${exam.id}/subjectList`)
            .then(response => {
                setSubjects(response.data);
            })
            .catch(error => {
                console.error('Error fetching exams:', error);
            });
    };

    const fetchMocks = () => {
        axios.get(`/exams/${exam.id}/${subject._id}/retrievePracticeSetIds`)
            .then(response => {
                setMocks(response.data.practiceSetsObj);
            })
            .catch(error => {
                console.error('Error fetching exams:', error);
            });
    };

    useEffect(() => {
        fetchExams();
    }, []);

    useEffect(() => {
        if (subject && subject._id) {
            fetchMocks()
        }
    }, [subject])

    const handleExamChange = (event) => {
        const selectedExam = event.target.value;
        const [id, name] = selectedExam.split('-');
        const exam = { id, name };
        setExam(exam);
        setSubjectDropdownDisabled(false);
        setSubject(null);
    };

    const handleSubjectChange = (event) => {
        const selectedSubject = event.target.value;
        const [id, name] = selectedSubject.split('-');
        const selectedSubjectObj = subjects.find(subject => subject._id === id);
        setSubject(selectedSubjectObj);
        setMockDropdownDisabled(false);
    };

    const handleMockChange = (event) => {
        const selectedMockId = event.target.value;
        const selectedMock = mocks.find(mock => mock._id === selectedMockId);
        setSelectedMock(selectedMock);
    };

    const handleSubmit = () => {
        setMockSelected(exam, subject, selectedMock._id);
    }

    const handleBuyNow = (e) => {
        e.preventDefault();
        setShowBuyNowModal(true);
    }

    return (
        <>
        <BackButton />
        <div className="quiz-box">
            <div className="container-fluid">
                <form style={{ padding: "0px 96px"}}>
                    <div className="form-group">
                        <label className="pull-left">Select exam you would like to appear in</label>
                        <select className="form-control" onClick={fetchExams} onChange={handleExamChange} >
                            <option value="">--Select--</option>
                            {exams.map(exam => (
                                <option key={exam._id} value={`${exam._id}-${exam.name}`}>{exam.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label className="pull-left">Paper</label>
                        <select className="form-control" onClick={fetchSubjects} onChange={handleSubjectChange} disabled={subjectDropdownDisabled} >
                            <option value="">--Select--</option>
                            {subjects.map(subject => (
                                <option key={subject._id} value={`${subject._id}-${subject.subjectCode}`}>{subject.subjectCode} - {subject.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label className="pull-left">Mock Tests</label>
                        <select className="form-control" onChange={handleMockChange} disabled={mockDropdownDisabled} >
                            <option value="no-mock">--Select--</option>
                            {mocks.map((mock, index) => (
                                <option 
                                    key={mock._id} 
                                    value={mock._id} 
                                >
                                    { /* {(index === 0) ? "Free Mock Test" : `Full-Length Mock Test - ${index}`} */ }
                                    {mock.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <br></br>
                    {
                        (Object.keys(selectedMock).length === 0 || selectedMock === "no-mock")
                                ?   (<div className="buy-now-form-group">
                                        <button className="btn btn-primary btn-block" id="btnStart" disabled={true} onClick={handleSubmit}>Start Mock Test</button>
                                    </div>)
                                :
                        (selectedMock._id === mocks[0]._id || (user.mockTestsState.mocksPurchased && user.mockTestsState.mocksPurchased.includes(selectedMock._id)))
                                ?   (<div className="buy-now-form-group">
                                        <button className="btn btn-primary btn-block" id="btnStart" disabled={mockDropdownDisabled} onClick={handleSubmit}>Start Mock Test</button>
                                    </div>)
                                :   (<div className="buy-now-form-group">
                                        <button className="btn btn-primary btn-block" id="btnStart" disabled={mockDropdownDisabled} onClick={handleBuyNow}>Buy Mock Test</button>
                                    </div>)
                    }
                </form>
            </div>
        </div>

        {showBuyNowModal && <PurchaseModal setShowBuyNowModal={setShowBuyNowModal} setProcessing={setShowLoadingOnBuyNowModal} user={user} subject={subject} mockTests={mocks.slice(1)} />}
        {showLoadingOnBuyNowModal && <LoadingComponent displayText={"Please close this window and re-login once you are redirected to our homepage."} />}
        </>
    );
};

export default ExamSelectorComponent;
