import React from 'react';
import '../../css/normalize.css';
import '../../css/webflow.css';
import '../../css/sanmacs.webflow.css';

const NavBar = () => {
    const content = (
      <div
      data-collapse="medium"
      data-animation="over-left"
      data-duration="400"
      data-w-id="58db7844-5919-d71b-dd74-2323ed8dffe9"
      data-easing="ease"
      data-easing2="ease"
      role="banner"
      className="header w-nav"
      style={{ opacity: 1 }}
      >
        <div className="container-default w-container">
          <div className="header-wrapper">
            <div className="split-content header-right">
              <a href="https://www.sanmacs.com" aria-current="page" className="brand w-nav-brand">
                <div className="logo">SANMACS</div>
              </a>
            </div>
            <div className="split-content header-center">
              <nav role="navigation" className="nav-menu w-nav-menu" style={{}}>
                <ul role="list" className="header-navigation" style={{}}>
                  <li className="nav-item-wrapper">
                    <a href="https://www.sanmacs.com" aria-current="page" className="nav-link">
                      Home
                    </a>
                  </li>
                  <li className="nav-item-wrapper">
                    <a href="https://www.sanmacs.com/courses" aria-current="page" className="nav-link">
                      Courses
                    </a>
                  </li>
                  <li className="nav-item-wrapper">
                    <a href="https://www.sanmacs.com/#home-success-stories" className="nav-link">
                      Success Stories
                    </a>
                  </li>
                  <li className="nav-item-wrapper">
                    <a href="https://www.sanmacs.com/resource-library" className="nav-link">
                      Resources
                    </a>
                  </li>
                  <li className="nav-item-wrapper">
                    <a href="https://www.sanmacs.com/about" className="nav-link">
                      About
                    </a>
                  </li>
                  <li className="nav-item-wrapper">
                    <a href="https://www.sanmacs.com/contact" className="nav-link">
                      Contact
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="split-content header-left">
              <a href="https://www.sanmacs.com/courses" className="button-primary header-button w-button">
                Explore Courses
              </a>
              <div
                className="menu-button w-nav-button"
                style={{ WebkitUserSelect: 'text' }}
                aria-label="menu"
                role="button"
                tabIndex="0"
                aria-controls="w-nav-overlay-0"
                aria-haspopup="menu"
                aria-expanded="false"
              >
                <div className="header-menu-button-icon-wrapper">
                  <div className="icon-wrapper">
                    <div className="header-menu-button-icon-top"></div>
                    <div className="header-menu-button-icon-medium"></div>
                    <div className="header-menu-button-icon-bottom"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0"></div>
      </div>
    );
    return content
}

export default NavBar