import React from 'react';

import '../../css/loadingspinner.css'

const LoadingComponent = ({ displayText }) => {
    return (
        <div className="loading-modal">
            <div className="loader"></div>
            <p style={{paddingTop: "16px", width: "120px", wordWrap: "break-word" }}>{displayText}</p>
        </div>
    );
};

export default LoadingComponent;
