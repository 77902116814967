import '../css/sanmacs.webflow.css';
import React, { useState, useEffect } from 'react';
import BackButton from './buttons/BackButtonComponent';

const MockResultsComponent = ({ scoreCard, questions }) => {

  // Assume the questions data is imported from a file named 'questionsData.js'
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://polyfill.io/v3/polyfill.min.js?features=es6'; // Load Polyfill.io for ES6 support
    script.onload = () => {
      // Load MathJAX script
      const mathJaxScript = document.createElement('script');
      mathJaxScript.async = true;
      mathJaxScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.7/MathJax.js?config=TeX-MML-AM_CHTML';
      mathJaxScript.onload = () => {
        window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub]); // Typeset equations once MathJAX is fully loaded
      };
      document.body.appendChild(mathJaxScript);
    };
    document.body.appendChild(script);
  }, []);

  const createQuestionDiv = (question, index) => {

    const isCorrect = question.correctAnswer === question.userSelectedChoice;
    
    return (
      <div key={index} id={`question${index}`} className="question-container">
        <hr />
        <label style={{ fontSize: '16px' }}>Question.{index + 1}</label>
        <label className="pull-right" style={{ marginLeft: '15px' }}></label>
        <span style={{ display: 'none' }}>1</span>
        <hr />
        <div className="question-text">{question.question}</div>
        {/* Render table before answer choices */}
        {question.tableData && (Object.keys(question.tableData).length !== 0) && (
          <>
          <table border="1">
            <thead>
              <tr>
                {question.tableData.columnHeaders.map((header, index) => (
                  <th key={index} style={{ textAlign: 'center', padding: '0px 5px 0px 5px' }}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {question.tableData.rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td key={cellIndex} style={{ textAlign: 'center', padding: '0px 5px 0px 5px' }}>{cell}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <br />
          </>
        )}
        {/* Render image before the question if image path exists */}
        {question.image && (Object.keys(question.image).length !== 0) && (
          <>
            <img src={`/${question.image.src}`} alt={`Question ${index + 1}`} height={question.image.height} width={question.image.width} />
            <br /><br />
          </>
        )}
        <div>
          {question.choices.map((choice, i) => (
            <>
            <div className="choice-container">
                <label className="answer-choice">
                    <input className="input-radio-button" type="radio" name={`answer${index}`} value={index} disabled={true} />
                    {typeof choice === 'string' ? choice : <img src={`/${choice.src}`} alt={`Choice ${index}`} height="75" width="75" />}
                    { ((i === question.correctAnswer)) && (<span className="checkmark">{'✔'}</span>)}
                    { ((i === question.userSelectedChoice) && !isCorrect) && (<span className="cross">{'❌'}</span>)}
                </label>
            </div>
            </>
          ))}
        </div>
        <br />
      </div>
    );
  };

  return (
    <>
    <BackButton />
    <div className="panel-body table-responsive">
        <h3 className="text-center">Your ScoreCard</h3>
        <table className="table table-bordered" id="tbl">
            <tbody>
                <tr>
                    <td>Total Question</td>
                    <th id="lblRTotalQuestion">{questions.length}</th>
                    <td>Total Attempted</td>
                    <th id="lblRTotalAttempted">{scoreCard.questionsAttempted}</th>
                </tr>
                <tr>
                    <td>Correct Answers</td>
                    <th id="lblRTotalCorrect">{scoreCard.correctAnswerCount}</th>
                    <td>Incorrect Answers</td>
                    <th id="lblRTotalWrong">{scoreCard.incorrectAnswerCount}</th>
                </tr>
                <tr>
                    <td>Score</td>
                    <td id="lblRScore">{scoreCard.totalScore}</td>
                </tr>
            </tbody>
        </table>
    </div>
    
    <section class="section resources-hero">
        <div class="container-default w-container">
            <div class="well"> 
                <div id="qus-pallete">
                    <div id="questions-container">{questions.map((question, index) => createQuestionDiv(question, index))}</div>
                </div>
            </div>
        </div>
    </section>
    </>
  );
};

export default MockResultsComponent;