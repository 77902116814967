import React from 'react';

const QuestionNumberBox = ({ questions, handleQuestionBoxClick, questionsStatus }) => {
    const count = questions.length
    const numbers = Array.from(Array(count).keys());
  
    const handleClick = (index) => {
      handleQuestionBoxClick(index);

    };

    const getStatusClassName = (index) => {
      switch (questionsStatus[index]) {
        case 'not-visited':
          return 'question-not-visited';
        case 'not-answered':
          return 'question-not-answered';
        case 'answered':
          return 'question-answered';
        case 'marked-for-review':
          return 'question-marked-for-review';
        case 'answered-marked-for-review':
          return 'question-answered-marked-for-review';
        default:
          return '';
      }
    };
  
    return (
      <div>
        <div className="legend">
          <div className="legend-item">
            <div className="question-number-box question-not-visited">1</div>
            <span className="legend-text">Not Visited</span>
          </div>
          <div className="legend-item">
            <div className="question-number-box question-not-answered">1</div>
            <span className="legend-text">Not Answered</span>
          </div>
          <div className="legend-item">
            <div className="question-number-box question-answered">1</div>
            <span className="legend-text">Answered</span>
          </div>
          <div className="legend-item">
            <div className="question-number-box question-marked-for-review">1</div>
            <span className="legend-text">Marked For Review</span>
          </div>
          <div className="legend-item">
            <div className="question-number-box question-answered-marked-for-review">1</div>
            <span className="legend-text">Answered & Marked for Review</span>
          </div>
        </div>
        <div className="question-numbers">
          {numbers.map((index) => (
            <div  key={index} 
                  className={`question-number-box ${getStatusClassName(index)}`}
                  onClick={() => handleClick(index)}
            >
              {index + 1}
            </div>
          ))}
        </div>
      </div>
    );
};

export default QuestionNumberBox;