import { Button } from 'react-bootstrap';
import React, { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import axios from '../api/axios';
import BackButton from './buttons/BackButtonComponent';

const PreviousTestResultsComponent = ({ showMockResults }) => {

    const { user } = useContext(UserContext);

    const mocksBySubject = {};
    Object.entries(user.mocksAttempted).forEach(([testId, mockData]) => {
        const subjectName = mockData.subjectName;
        if (!mocksBySubject[subjectName]) {
            mocksBySubject[subjectName] = [];
        }
        mocksBySubject[subjectName].push({ testId, mockData });
    });

    const handleMockButtonClick = async (testId) => {
        
        axios.get(`/exams/previousMockResults/${user._id}/${testId}`)
                .then(response => {
                    showMockResults(response.data);
                })
                .catch(error => {
                    console.error('Error fetching exams:', error);
                });
    };

    return (
        <>
        <BackButton />
        <div className="horizontal-bars-container">
            {Object.entries(mocksBySubject).map(([subjectName, mocks], subjectIndex) => (
                mocks.map(({testId, mockData }, index) => (
                    <Button
                        key={testId} // Use the test ID as the key
                        className="horizontal-bar"
                        onClick={() => handleMockButtonClick(testId)}
                    >
                        {`View Results - ${mockData.examName} - ${mockData.subjectName} - Mock Test ${index + 1}`} {/* Use the test ID as the button label */}
                    </Button>
                ))
            ))}
        </div>
        </>
    );
}

export default PreviousTestResultsComponent;