const questions = [
  {
    question: "Let $R$ be a reflexive relation on a set having 10 elements. If $m$ is the number of ordered pairs in $R$, then",
    choices: [
      "$m=10$",
      "$m \\leq 10$",
      "$m=100$",
      "$m \\geq 10$"
    ],
    correctAnswer: 3,
    videoLink: "https://www.youtube.com/watch?v=xSA428hpEKQ&t=136s"
  },
  {
    question: "A point $P$ in the first quadrant, lies on $y^{2}=4 a x, a>0$ and keeps a distance of $5 a$ units from its focus. Which of the following point lies on the locus of $P$?",
    choices: [
      "$(0,1)$",
      "$(1,1)$",
      "$(2,0)$",
      "$(1,0)$"
    ],
    correctAnswer: 1,
    videoLink: "https://www.youtube.com/watch?v=xSA428hpEKQ&t=399s"
  },
  {
    question: "The value of $\\int_{-\\frac{\\pi}{3}}^{\\frac{\\pi}{3}} \\frac{x \\sin x}{\\cos ^{2} x} d x$ is",
    choices: [
      "$\\frac{1}{3}(4 \\pi+1)$",
      "$\\frac{4 \\pi}{3}-2 \\log \\tan \\frac{5 \\pi}{12}$",
      "$\\frac{4 \\pi}{3}+\\log \\frac{5 \\pi}{12}$",
      "$\\frac{4 \\pi}{3}-\\log \\tan \\frac{5 \\pi}{12}$"
    ],
    correctAnswer: 1,
    videoLink: "https://www.youtube.com/watch?v=xSA428hpEKQ&t=716s"
  },
  {
    question: "Let $a, b, c$ and $d$ be non-zero numbers. If the point of intersection of the lines $4 a x+2 a y+c=0$ and $5 b x+2 b y+d=0$ lies in the fourth quadrant and is equidistance from the two axes, then",
    choices: [
      "$2 a d-3 b c=0$",
      "$a d-b c=0$",
      "$a d-b c=0$",
      "$a+b+c+d=0$"
    ],
    correctAnswer: 0,
    videoLink: "https://www.youtube.com/watch?v=xSA428hpEKQ&t=1314s"
  },
  {
    question: "Which of the following number is the coefficient of $x^{100}$ in the expansion of $\\log _{e}\\left(\\frac{1+x}{1+x^{2}}\\right),|x| < 1$ ?",
    choices: [
      "$-0.03$",
      "$-0.01$",
      "$0.01$",
      "None of these"
    ],
    correctAnswer: 2,
    videoLink: "https://www.youtube.com/watch?v=xSA428hpEKQ&t=1474s"
  },
  {
    question: "A line segment $A B$ of length 10 meters is passing through the foot of the perpendicular of a pillar, which is standing at right angle to the ground. Tip of the pillar subtends angles $\\tan ^{-1} 3 \\operatorname{and}^{\\tan ^{-1}} 2$ at $A$ and $B$ respectively, which of the following choice represents the height of the pillar?",
    choices: [
      "$15$",
      "$12$",
      "$10$",
      "$8$"
    ],
    correctAnswer: 1,
    videoLink: "https://www.youtube.com/watch?v=0yyQCyE9bOI&t=124s"
  },
  {
    question: "The sum of infinite terms of decreasing G.P. is equal to the greatest value of the function $f(x)=x^{3}+3 x-9$ in the interval $[-2,3]$ and the difference between the first two terms is $f^{\\prime}(0)$ then the common ratio of the G.P. is",
    choices: [
      "$\\frac{2}{3}$",
      "$\\frac{4 \\pi}{3}-2 \\log \\tan \\frac{5 \\pi}{12}$",
      "$\\frac{4 \\pi}{3}+\\log \\frac{5 \\pi}{12}$",
      "$\\frac{4 \\pi}{3}-\\log \\tan \\frac{5 \\pi}{12}$"
    ],
    correctAnswer: 0,
    videoLink: "https://www.youtube.com/watch?v=0yyQCyE9bOI&t=466s"
  },
  {
    question: "If $A$ and $B$ are square matrices such that $B=-A^{-1} B A$, then $(A+B)^{2}=$",
    choices: [
      "$A^{2}+B^{2}$",
      "$A^{2}-B^{2}$",
      "$A+B$",
      "$A-B$"
    ],
    correctAnswer: 0,
    videoLink: "https://www.youtube.com/watch?v=0yyQCyE9bOI&t=1168s"
  },
  {
    question: "In an examination of 9 papers, a candidate has to pass in more papers than the number of papers in which he fails in order to get the success. The number of ways in which he can be fail, is",
    choices: [
      "$255$",
      "$256$",
      "$9 \\times 8!$",
      "$128$"
    ],
    correctAnswer: 1,
    videoLink: "https://www.youtube.com/watch?v=0yyQCyE9bOI&t=1351s"
  },
  {
    question: "If $\\vec{a}$ and $\\vec{b}$ are two unit vectors and $2 \\vec{a}+\\vec{b}$ is 3 units, then which of following statement is true?",
    choices: [
      "$\\vec{a} \\text{ and } \\vec{b} \\text{ are parallel }$",
      "$\\vec{a}$ and $\\vec{b}$ are perpendicular to each other",
      "$\\vec{a}$ is perpendicular to $2 \\vec{a}+\\vec{b}$",
      "$\\vec{b}$ is parallel to $2 \\vec{a}+\\vec{b}$"
    ],
    correctAnswer: 0,
    videoLink: "https://www.youtube.com/watch?v=0yyQCyE9bOI&t=1710s"
  },
  {
    question: "Box A contains 4 green, 5 black and 6 white balls. Box $B$ contains 5 green, 3 black and 2 white balls. $A$ ball is chosen at random from the box $A$ and placed in box $B$ and then a ball is chosen at random from box B. What is the probability that a black ball was transferred from box A to box B given that the final ball chosen from box B is green?",
    choices: [
      "$\\frac{25}{79}$",
      "$-\\frac{30}{79}$",
      "$-\\frac{24}{79}$",
      "None of these"
    ],
    correctAnswer: 0,
    videoLink: "https://www.youtube.com/watch?v=ioXiZ383u8U&t=41s"
  },
  {
    question: "If $n_{1}$ and $n_{2}$ are the number of real valued solutions of equations $x=\\left|\\sin ^{-1} x\\right|$ and $x=\\sin (x)$ respectively, then the value of $n_{2}-n_{1}$ is",
    choices: [
      "$1$",
      "$0$",
      "$2$",
      "$3$"
    ],
    correctAnswer: 1,
    videoLink: "https://www.youtube.com/watch?v=ioXiZ383u8U&t=807s"
  },
  {
    question: "The negation of $\\sim s \\vee(\\sim r \\wedge s)$ is equivalent to",
    choices: [
      "$s \\vee(r \\vee \\sim s)$",
      "$s \\wedge r$",
      "$s \\wedge \\sim r$",
      "$s \\wedge(r \\wedge \\sim s)$"
    ],
    correctAnswer: 1,
    videoLink: "https://www.youtube.com/watch?v=ioXiZ383u8U&t=1172s"
  },
  {
    question: "If the numbers $a, b, c$ and $d$ are in harmonic progression and arithmetic mean of $a b, b c$ and $c d$ is 9 , then which of the following number is the value of $a d$ ?",
    choices: [
      "$3$",
      "$9$",
      "$12$",
      "$4$"
    ],
    correctAnswer: 1,
    videoLink: "https://www.youtube.com/watch?v=ioXiZ383u8U&t=1441s"
  },
  {
    question: "If a vector having magnitude of 5 units, makes equal angle with each of the three mutually perpendicular axes, then the sum of the magnitude of the projections on each of the axis is",
    choices: [
      "$\\frac{15}{3}$ unit",
      "$5 \\sqrt{3}$ unit",
      "$\\frac{15 \\sqrt{3}}{2}$ unit",
      "None of these"
    ],
    correctAnswer: 1,
    videoLink: "https://www.youtube.com/watch?v=ioXiZ383u8U&t=1722s"
  },
  {
    question: "The range of values of $\\theta$ in the interval $(0, \\pi)$ such that the points $(3,2)$ and $(\\cos \\theta, \\sin \\theta)$ lie on the same side of the line $x+y-1=0$ is",
    choices: [
      "$\\left(0, \\frac{\\pi}{4}\\right)$",
      "$\\left(0, \\frac{3 \\pi}{4}\\right)$",
      "$\\left(0, \\frac{\\pi}{2}\\right)$",
      "$\\left(0, \\frac{\\pi}{3}\\right)$"
    ],
    correctAnswer: 2,
    videoLink: "https://www.youtube.com/watch?v=3XRFCG_R2TA&t=45s"
  },
  {
    question: "Let $f(x)=\\frac{x^{2}-1}{(|x|-1)}$. Then, the value of $\\lim _{x \\rightarrow-1} f(x)$ is",
    choices: [
      "$-1$",
      "$1$",
      "$2$",
      "$-2$"
    ],
    correctAnswer: 2,
    videoLink: "https://www.youtube.com/watch?v=3XRFCG_R2TA&t=688s"
  },
  {
    question: "The largest value of $\\cos ^{2} \\theta-6 \\sin \\theta \\cos \\theta+3 \\sin ^{2} \\theta+2$ is",
    choices: [
      "$4$",
      "$0$",
      "$4-\\sqrt{10}$",
      "$4+\\sqrt{10}$"
    ],
    correctAnswer: 3,
    videoLink: "https://www.youtube.com/watch?v=3XRFCG_R2TA&t=822s"
  },
  {
    question: "If $\\prod_{i=1}^{n} \\tan \\left(a_{i}\\right)=1$ where $a_{i} \\in\\left[0, \\frac{\\pi}{2}\\right]$ for all $i=1,2, \\ldots n$, then the maximum value of $\\prod_{i=1}^{n} \\sin \\left(a_{i}\\right)$ is",
    choices: [
      "$2^{-n}$",
      "$2^{-\\frac{n}{2}}$",
      "$1$",
      "None of these"
    ],
    correctAnswer: 1,
    videoLink: "https://www.youtube.com/watch?v=3XRFCG_R2TA&t=1038s"
  },
  {
    question: "The foci of the graph represented by $x^{2}-4 y^{2}+2 x+8 y-7=0$ is",
    choices: [
      "$(-1,-1 \\pm \\sqrt{5})$",
      "$(1,-1 \\pm \\sqrt{5})$",
      "$(1 \\pm \\sqrt{5}, 1)$",
      "$(-1 \\pm \\sqrt{5}, 1)$"
    ],
    correctAnswer: 3,
    videoLink: "https://www.youtube.com/watch?v=3XRFCG_R2TA&t=1382s"
  },
  {
    question: "Let $\\vec{a}=2 \\hat{i}+\\hat{j}-2 \\hat{k}$ and $\\vec{b}=\\hat{i}+\\hat{j}$. Let $\\vec{c}$ be a vector such that $|\\vec{c}-\\vec{a}|=3,|(\\vec{a} \\times \\vec{b}) \\times \\vec{c}|=3$ and the angle between $\\vec{c}$ and $\\vec{a} \\times \\vec{b}$ is $30^{\\circ}$, then $\\vec{a} . \\vec{c}$ is equal to",
    choices: [
      "$2$",
      "$\\frac{1}{8}$",
      "$\\frac{25}{8}$",
      "$5$"
    ],
    correctAnswer: 0,
    videoLink: "https://www.youtube.com/watch?v=midjGVzzgh8&t=14s"
  },
  {
    question: "If $\\int x \\cdot \\sin x \\cdot \\sec ^{3} x d x=\\frac{1}{2}\\left[f(x) \\sec ^{2} x+g(x)\\left(\\frac{\\tan x}{x}\\right)\\right]+c$, where $f$ and $g$ are real valued functions of $x$ and $c$ is an arbitrary constant, then which of the following statement is true?",
    choices: [
      "$f(x)=g(x)$",
      "$f(x) \\cdot g(x)=-1$",
      "$f(x)+g(x)=c$",
      "$f(x)-g(x)=c$"
    ],
    correctAnswer: 2,
    videoLink: "https://www.youtube.com/watch?v=midjGVzzgh8&t=422s"
  },
  {
    question: "Let $f(x)$ be a polynomial of degree 4 and $f(n)=n+1$ for $n=1,2,3,4$. Now, if $f(0)=25$, then $f(5)$ is equal to",
    choices: [
      "$20$",
      "$25$",
      "$30$",
      "None of these"
    ],
    correctAnswer: 2,
    videoLink: "https://www.youtube.com/watch?v=midjGVzzgh8&t=904s"
  },
  {
    question: "An urn contains 5 yellow, 4 black and 3 white balls. 3 balls are drawn at random. The probability that no black ball is selected is",
    choices: [
      "$\\frac{14}{55}$",
      "$\\frac{1}{66}$",
      "$\\frac{2}{9}$",
      "None of these"
    ],
    correctAnswer: 0,
    videoLink: "https://www.youtube.com/watch?v=midjGVzzgh8&t=1174s"
  },
  {
    question: "If $\\cos ^{-1}\\left(\\frac{3}{\\sqrt{20}}\\right)$ is the angle between the vectors $\\hat{i}-2 x \\hat{j}+3 y \\hat{k}$ and $x \\hat{i}+\\hat{j}+y \\hat{k}$, where $x, y$ are real numbers then which of the following points satisfies the locus of $(x, y)$ ?",
    choices: [
      "$(0,1)$",
      "$(-1,-1)$",
      "$(1,0)$",
      "$(1,1)$"
    ],
    correctAnswer: 0,
    videoLink: "https://www.youtube.com/watch?v=midjGVzzgh8&t=1342s"
  },
  {
    question: "A real valued function is defined as $f(x)=\\left\\{\\begin{array}{ll}-1, & -2 \\leq x \\leq 0 \\\\ x-1, & 0 < x \\leq 2\\end{array}\\right.$. Which of the following statement is FALSE?",
    choices: [
      "$f(|x|)=|x|-1$, if $0 \\leq x \\leq 1$",
      "$|f(x)|=x-1$, if $1 \\leq x \\leq 2$",
      "$f(|x|)+|f(x)|=1$, if $0 \\leq x \\leq 1$",
      "$f(|x|)-|f(x)|=0$, if $1 \\leq x \\leq 2$"
    ],
    correctAnswer: 2,
    videoLink: "https://www.youtube.com/watch?v=8t7oNvQO1Ic&t=33s"
  },
  {
    question: "A circle $C$ touches the $x$-axis and touches another circle with center at $(0,3)$ and radius 2 . Then, the locus of the center of the circle $C$ is",
    choices: [
      "an ellipse",
      "a circle",
      "a parabola",
      "a hyperbola"
    ],
    correctAnswer: 2,
    videoLink: "https://www.youtube.com/watch?v=8t7oNvQO1Ic&t=466s"
  },
  {
    question: "The value $\\sum_{k=1}^{n} x_{k}$, where $x_{k}=\\cos \\left(\\frac{2 \\pi k}{n}\\right)+i \\sin \\left(\\frac{2 \\pi k}{n}\\right)$ is equal to",
    choices: [
      "$1$",
      "$-1$",
      "$0$",
      "None of these"
    ],
    correctAnswer: 2,
    videoLink: "https://www.youtube.com/watch?v=8t7oNvQO1Ic&t=891s"
  },
  {
    question: "Let $\\vec{a}=\\frac{\\hat{i}-2 \\hat{j}}{\\sqrt{5}}$ and $\\vec{b}=\\frac{2 \\hat{i}+\\hat{j}+3 \\hat{k}}{\\sqrt{14}}$ be two vectors, then the value of $(2 \\hat{a}+\\hat{b})[(\\hat{a} \\times \\hat{b}) \\times(\\hat{a}-2 \\hat{b})]$ is",
    choices: [
      "$5$",
      "$6$",
      "$3$",
      "$4$"
    ],
    correctAnswer: 0,
    videoLink: "https://www.youtube.com/watch?v=8t7oNvQO1Ic&t=1324s"
  },
  {
    question: "A speaks truth in $60 \\%$ and $B$ in $50 \\%$ of the cases. The probability that they contradict each other while narrating some incident is",
    choices: [
      "$\\frac{1}{4}$",
      "$\\frac{1}{3}$",
      "$\\frac{2}{3}$",
      "$\\frac{1}{2}$"
    ],
    correctAnswer: 3,
    videoLink: "https://www.youtube.com/watch?v=8t7oNvQO1Ic&t=1767s"
  },
  {
    question: "Let $f(x)=\\frac{6^{x}-3^{x}-2^{x}+1}{\\log _{e} 9(1-\\cos x)}$ be a real function. Then, $\\lim _{x \\rightarrow 0} f(x)$ equals",
    choices: [
      "$2$",
      "$3$",
      "$\\log _{e} 2$",
      "$\\log _{e} 3$"
    ],
    correctAnswer: 2,
    videoLink: "https://www.youtube.com/watch?v=7nukHERo7xw&t=31s"
  },
  {
    question: "If $\\lim _{x \\rightarrow 1} \\frac{x^{4}-1}{x-1}=\\lim _{x \\rightarrow k} \\frac{x^{3}-k^{3}}{x^{2}-k^{2}}$, then $k=$",
    choices: [
      "$1$",
      "$\\frac{2}{3}$",
      "$\\frac{8}{3}$",
      "$\\frac{4}{3}$"
    ],
    correctAnswer: 2,
    videoLink: "https://www.youtube.com/watch?v=7nukHERo7xw&t=456s"
  },
  {
    question: "The mean of 5 observations is 5 and their variance is 12.4 . If three of the observation are $1,2,6$ then the mean deviation from the mean of the data is",
    choices: [
      "$2.8$",
      "$2.4$",
      "$2.6$",
      "$2.5$"
    ],
    correctAnswer: 0,
    videoLink: "https://www.youtube.com/watch?v=7nukHERo7xw&t=602s"
  },
  {
    question: "The equation of the tangent at any point of the curve $x=a \\cos 2 t, y=2 \\sqrt{2} a \\sin t$, with $m$ as its slope, is",
    choices: [
      "$y=m x+a\\left(m-\\frac{1}{m}\\right)$",
      "$y=m x-a\\left(m+\\frac{1}{m}\\right)$",
      "$y=m x+a\\left(m+\\frac{1}{m}\\right)$",
      "$y=a m x+a\\left(m-\\frac{1}{m}\\right)$"
    ],
    correctAnswer: 2,
    videoLink: "https://www.youtube.com/watch?v=7nukHERo7xw&t=1208s"
  },
  {
    question: "For a group of 100 candidates, the mean and standard deviation of scores were found to be 40 and 15 respectively. Later on it was found that the scores 25 and 35 were misread as 52 and 53, respectively. Then the corrected mean and standard deviation corresponding to the corrected figures are",
    choices: [
      "$39.5,14$",
      "$39.55,14.97$",
      "$39.9,14.97$",
      "$40.19,15.1$"
    ],
    correctAnswer: 2,
    videoLink: "https://www.youtube.com/watch?v=7nukHERo7xw&t=1657s"
  },
  {
    question: "Odds in favour of an event $A$ are 2 to 1 and odds in favour of $A \\cup B$ are 3 to 1 . Consider with this information, the bound for $P(B)$ is given by",
    choices: [
      "$\\frac{1}{3} \\leq P(B) \\leq \\frac{1}{2}$",
      "$\\frac{1}{12} \\leq P(B) \\leq \\frac{3}{4}$",
      "$0 \\leq P(B) \\leq \\frac{3}{4}$",
      "$\\frac{1}{6} \\leq P(B) \\leq \\frac{1}{3}$"
    ],
    correctAnswer: 2,
    videoLink: "https://www.youtube.com/watch?v=WKRoLSwbBmw&t=23s"
  },
  {
    question: "The coefficient of $x^{50}$ in the expansion of $(1+x)^{1000}+2 x(1+x)^{999}+3 x^{2}(1+x)^{998}+\\ldots+1001 x^{1000}$",
    choices: [
      "${ }^{1002} C_{50}$",
      "${ }^{1002} C_{51}$",
      "${ }^{1005} C_{50}$",
      "${ }^{1005} C_{48}$"
    ],
    correctAnswer: 0,
    videoLink: "https://www.youtube.com/watch?v=WKRoLSwbBmw&t=323s"
  },
  {
    question: "If $\\int f(x) d x=g(x)$, then $\\int x^{5} f\\left(x^{3}\\right) d x$ is equal to",
    choices: [
      "$\\frac{1}{3} x^{3} g\\left(x^{3}\\right)-\\int x^{3} g\\left(x^{3}\\right) d x+c$",
      "$\\frac{1}{3} x^{3} g\\left(x^{3}\\right)-\\int x^{2} g\\left(x^{3}\\right) d x+c$",
      "$\\frac{1}{3} x^{3} g\\left(x^{3}\\right)-3 \\int x^{4} g\\left(x^{3}\\right) d x+c$",
      "None of these"
    ],
    correctAnswer: 2,
    videoLink: "https://www.youtube.com/watch?v=WKRoLSwbBmw&t=928s"
  },
  {
    question: "Number of non-differentiable points of the function $f(x)=3+|\\cos x|$ in the interval $[-\\pi, \\pi]$ is",
    choices: [
      "$1$",
      "$3$",
      "$4$",
      "None of these"
    ],
    correctAnswer: 3,
    videoLink: "https://www.youtube.com/watch?v=WKRoLSwbBmw&t=1211s"
  },
  {
    question: "Between any two real roots of the equation $e^{x} \\sin x-1=0$, the equation $e^{x} \\cos x+1=0$ has",
    choices: [
      "at least one root",
      "at most one root",
      "no root",
      "exactly one root"
    ],
    correctAnswer: 0,
    videoLink: "https://www.youtube.com/watch?v=WKRoLSwbBmw&t=1412s"
  },
  {
    question: "If the maximum value of the function $f(x)=(x-1)^{2}(x+1)^{3}$ is $\\frac{2^{p} 3^{q}}{3125}$ then the value of $(p, q)$ will be",
    choices: [
      "$(7,3)$",
      "$(4,4)$",
      "$(5,5)$",
      "$(3,7)$"
    ],
    correctAnswer: 0,
    videoLink: "https://www.youtube.com/watch?v=PcRRFRp7vBs&t=46s"
  },
  {
    question: "If $\\vec{a}=\\hat{i}-\\hat{k}, \\vec{b}=x \\hat{i}+\\hat{j}+(1-x) \\hat{k}$ and $\\vec{c}=y \\hat{i}+x \\hat{j}+(1+x-y) \\hat{k}$, then $[\\hat{a} \\hat{b} \\hat{c}]$ depends on",
    choices: [
      "Only $x$",
      "Neither $x$ nor $y$",
      "Both $x$ nor $y$",
      "Only $y$"
    ],
    correctAnswer: 1,
    videoLink: "https://www.youtube.com/watch?v=PcRRFRp7vBs&t=813s"
  },
  {
    question: "A computer producing factory has only two plants $T_{1}$ and $T_{2}$. Plant $T_{1}$ produces $20 \\%$ and plant $T_{2}$ produces $80 \\%$ of the total computers. $7 \\%$ of the computers produced in the factory turn out to be defective. It is known that $P$ (computer turns out to be defective given that it is produced in plant $T_{1}$ ) $=10 P$ (computer turns out to be defective given that it is produced in plant $T_{2}$ ). A computer produced in the factory is randomly selected and it does not turn out to be defective. Then, the probability that is produced in plant $T_{2}$ is",
    choices: [
      "$\\frac{47}{79}$",
      "$\\frac{78}{93}$",
      "$\\frac{36}{73}$",
      "$\\frac{75}{83}$"
    ],
    correctAnswer: 1,
    videoLink: "https://www.youtube.com/watch?v=PcRRFRp7vBs&t=944s"
  },
  {
    question: "If the equation $\\left|x^{2}-6 x+8\\right|=a$ has four real solutions, then choose the correct option",
    choices: [
      "$a=1$",
      "$a=0$",
      "$a \\in(0,1)$",
      "$a \\in[1,2]$"
    ],
    correctAnswer: 2,
    videoLink: "https://www.youtube.com/watch?v=PcRRFRp7vBs&t=1857s"
  },
  {
    question: "If the total frequency is 685 and the median is 42.6 then the possible values of $F_{1}$ and $F_{2}$ are",
    extraDiv: '<table class="table table-bordered" border="1" cellspacing="1" cellpadding="1"><tbody><tr><td>&nbsp;Class interval</td><td>&nbsp;10-20</td><td>&nbsp;20-30</td><td>30-40&nbsp;</td><td>40-50&nbsp;</td><td>&nbsp;50-60</td><td>&nbsp;60-70</td><td>70-80&nbsp;</td></tr><tr><td>&nbsp;Frequency</td><td>&nbsp;180</td><td>$$F_{1}$$</td><td>&nbsp;34</td><td>180&nbsp;</td><td>&nbsp;136</td><td>$$F_{2}$$</td><td>50&nbsp;</td></tr></tbody></table><br>',
    choices: [
      "$82, 83$",
      "$80, 25$",
      "$83, 22$",
      "$79, 26$"
    ],
    correctAnswer: 1,
    videoLink: "https://www.youtube.com/watch?v=PcRRFRp7vBs&t=2260s"
  },
  {
    question: "If $|x-6|=\\left|x^{2}-4 x\\right|-\\left|x^{2}-5 x+6\\right|$, where $x$ is a real variable, then choose the correct option",
    choices: [
      "$x \\in(2,5)$",
      "$x \\in[2,3] \\cup[6, \\infty)$",
      "$x \\in^{\\sim}-[2,6]$",
      "None of these"
    ],
    correctAnswer: 1,
    videoLink: "https://www.youtube.com/watch?v=-ft1EIj6eZo&t=67s"
  },
  {
    question: "In a $\\triangle A B C(a, b, c$ are the lengths of the opposite sides of angles $A, B, C$ respectively), the perimeter of a $\\triangle A B C$ is 6 times the arithmetic mean of the sine values of its angles. if the side $a=1$, then the angle $A$ is",
    choices: [
      "$\\frac{\\pi}{6}$",
      "$\\pi$",
      "$\\frac{\\pi}{3}$",
      "$\\frac{\\pi}{4}$"
    ],
    correctAnswer: 0,
    videoLink: "https://www.youtube.com/watch?v=-ft1EIj6eZo&t=753s"
  },
  {
    question: "The graph of the real function $f(x)=\\log _{a}\\left(x^{3}+\\sqrt{x^{6}+1}\\right)$ is symmetric about the",
    choices: [
      "$x$-axis",
      "Origin",
      "$y$-axis",
      "$y=x$ line"
    ],
    correctAnswer: 1,
    videoLink: "https://www.youtube.com/watch?v=-ft1EIj6eZo&t=974s"
  },
  {
    question: "Let $A$ and $B$ be two sets such that $A \\cap X=B \\cap X=\\phi$ and $A \\cup X=B \\cup X$ for some set $X$. Then",
    choices: [
      "$A \\cup B=X$",
      "$B=X$",
      "$A=B$",
      "$A=X$"
    ],
    correctAnswer: 2,
    videoLink: "https://www.youtube.com/watch?v=-ft1EIj6eZo&t=1168s"
  },
  {
    question: "The locus of mid-points of all chords of the parabola $y^{2}=4 x$ which are drawn through its vertex, is",
    choices: [
      "$y^{2}=8 x$",
      "$y^{2}=2 x$",
      "$x^{2}+4 y^{2}=16$",
      "$x^{2}=2 y$"
    ],
    correctAnswer: 1,
    videoLink: "https://www.youtube.com/watch?v=-ft1EIj6eZo&t=1324s"
  }
];
  
  export default questions;  