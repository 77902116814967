import React, { useState, useEffect } from 'react';
import UserInfoComponent from './helper/UserInfoComponent';
import QuestionPanel from './QuestionPanel';
import ExamSummaryComponent from './ExamSummaryComponent';

// First question of the next section is not rendering when the section changes. -- fixed
// Section summary of the next section is not rendering correctly when the section changes.
// Section 4 summary is showing up when there are only 3 sections
// When Exam Summary shows up, then the questionStatus list gets a total original length + length of last section
// Check userId when calling ComputeResult for the exam

const TestSectionComponent = ({ questions,
    questionsStatus,
    setQuestionsStatus,
    selectedAnswers,
    setSelectedAnswers,
    onSubmit
}) => {

    //console.log(selectedAnswers);

    const sectionBoundaries = [0, 50, 90, 120];
    const sectionNames = ["Mathematics", "Logical Reasoning", "Computers & English"]
    const sectionTimer = [4200000, 1800000, 1200000];

    const [section, setSection] = useState(1);
    const [startIndex, setStartIndex] = useState(sectionBoundaries[section - 1]);
    const [endIndex, setEndIndex] = useState(sectionBoundaries[section] || questions.length);

    const [sectionQuestions, setSectionQuestions] = useState(questions.slice(startIndex, endIndex));
    const [qStatus, setQStatus] = useState(questionsStatus.slice(startIndex, endIndex));
    const [selectedAns, setSelectedAns] = useState({});

    const [showSectionSummary, setShowSectionSummary] = useState(false);

    const onSubmitSection = () => {
        if (section < sectionBoundaries.length) {

            // Update questionsStatus at specific indices
            const updatedQuestionsStatus = [...questionsStatus];
            updatedQuestionsStatus.splice(startIndex, qStatus.length, ...qStatus);
            setQuestionsStatus(updatedQuestionsStatus);

            // Update selectedAnswers at specific indices
            const updatedSelectedAnswers = { ...selectedAnswers, ...selectedAns };
            setSelectedAnswers(updatedSelectedAnswers);

            setSection(section => section + 1);

            setShowSectionSummary(false);
        }
    }

    const onClickSectionSummary = () => {
        setShowSectionSummary(true);

    }

    useEffect(() => {
        // Calculate the new start and end index based on the current section
        const newStartIndex = sectionBoundaries[section - 1];
        const newEndIndex = sectionBoundaries[section] || questions.length;
    
        // Update the state values for startIndex, endIndex, sectionQuestions, and qStatus
        setStartIndex(newStartIndex);
        setEndIndex(newEndIndex);
        setSectionQuestions(questions.slice(newStartIndex, newEndIndex));
        setQStatus(questionsStatus.slice(newStartIndex, newEndIndex));

    }, [section]);

    if (section === sectionBoundaries.length) {
        onSubmit();
    }

    return (
        <>
            <UserInfoComponent />
            <h2 style={{ textAlign: 'center' }}>{sectionNames[section - 1]}</h2>
            {showSectionSummary ? 
                (<ExamSummaryComponent 
                    section={section}
                    questionsStatus={qStatus} 
                    onYesClick={onSubmitSection} 
                    onNoClick={() => setShowSectionSummary(false)}/>
                )
                :
                (<QuestionPanel
                    section={section}
                    questions={sectionQuestions}
                    questionsStatus={qStatus}
                    setQuestionsStatus={setQStatus}
                    selectedAnswers={selectedAns}
                    setSelectedAnswers={setSelectedAns}
                    onSubmit={onClickSectionSummary}
                    sectionTimer={sectionTimer[section - 1]}
                />)
            }
        </>
    )
};

export default TestSectionComponent;