import React from 'react';
import DashHeader from './DashHeader';
import DashFooter from './DashFooter';
import { Outlet } from 'react-router-dom';

const DashLayout = () => {
    return (
        <>
            <DashHeader />
            <section className="section test-portal">
                <Outlet />
            </section>
            <DashFooter />
        </>
    );
};

export default DashLayout;