const questions = [
    {
        qid: 1,
        question: "The area of a rhombus is $120 \\mathrm{~cm}^{2}$ and length of its one diagonal is $24 \\mathrm{~cm}$. Find the perimeter of the rhombus (in $\\mathrm{cm}$)",
        choices: [
            "$50$",
            "$52$",
            "$54$",
            "$56$"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=XaPPak3zbkk&t=25s"
    },
    {
        qid: 2,
        question: "If $\\cot ^{2} 45^{\\circ}-\\sin ^{2} 45^{\\circ}=k \\sin ^{2} 30^{\\circ} \\times \\tan ^{2} 45^{\\circ} \\times \\sec ^{2} 45^{\\circ}$, then the value of $k$ is",
        choices: [
            "$0$",
            "$1$",
            "$1.5$",
            "$2$"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=XaPPak3zbkk&t=245s"
    },
    {
        qid: 3,
        question: "If $A, B$ and $C$ are acute positive angles such that $A+B+C=\\pi$ and $\\cot A \\cot B \\cot C=K$, then",
        choices: [
            "$K \\leq \\frac{1}{3 \\sqrt{3}}$",
            "$K \\geq \\frac{1}{3 \\sqrt{3}}$",
            "$K<\\frac{1}{9}$",
            "$K>\\frac{1}{9}$"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=XaPPak3zbkk&t=320s"
    },
    {
        qid: 4,
        question: "The two adjacent sides of a cyclic 'QUADRILATERAL' are 2,5 and the angle between them is $60^{\\circ}$. If the third side is 3 , the remaining fourth side is",
        choices: [
            "$2$",
            "$3$",
            "$4$",
            "$5$"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=XaPPak3zbkk&t=794s"
    },
    {
        qid: 5,
        question: "The top of a hill observed from the top and bottom of a building of height $h$ is at angles of elevation $p$ and $q$ respectively. The height of the hill is",
        choices: [
            "$\\frac{h \\cot q}{\\cot q-\\cot p}$",
            "$\\frac{h \\cot p}{\\cot p-\\cot q}$",
            "$\\frac{h \\tan p}{\\tan p-\\tan q}$",
            "$\\frac{h \\sec p}{\\tan p-\\tan q}$"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=XaPPak3zbkk&t=1215s"
    },
    {
        qid: 6,
        question: "Given below are two statements: Statement I: If the roots of the quadratic equation $x^{2}-4 x-a=0$ are real, then the least value of ' $a$ ' is $\\frac{1}{81}$. Statement II: The harmonic mean of the roots of the equation $(5+\\sqrt{2}) x^{2}-(4+\\sqrt{5}) x+(8+2 \\sqrt{5})=0$ is 2. below: In the light of the above statements, choose the correct answer from the options given",
        choices: [
            "Both Statement I and Statement II are True",
            "Both Statement I and Statement II are false",
            "Statement I is the true but Statement II is false",
            "Statement I is false but Statement II is true"
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=wIFC8EuVYOk&t=49s"
    },
    {
        qid: 7,
        question: "The mean deviation from the mean of the A.P. $a, a+d, a+2 d, \\ldots a+2 n d$ is",
        choices: [
            "$n(n+1) d$",
            "$\\frac{n(n+1) d}{2 n+1}$",
            "$\\frac{n(n+1) d}{2 n}$",
            "$\\frac{n(n-1) d}{2 n+1}$"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=wIFC8EuVYOk&t=514s"
    },
    {
        qid: 8,
        question: "Match List - I with List - II",
        tableData: {
            columnHeaders: ["List - I", "List - II"],
            rows: [
                ["A. Addition Theorem of probability", "$ P\\left(\\frac{E_{i}}{A}\\right)=\\frac{P\\left(E_{i}\\right) P\\left(\\frac{A}{E_{i}}\\right)}{\\sum_{i=1}^{n} P\\left(E_{i}\\right) P\\left(\\frac{A}{E_{i}}\\right), i=1,2} $"],
                ["B. Binomial distribution", "II. $P(A \\cap B)=P(A) P\\left(\\frac{B}{A}\\right)$, if $P(A) \\neq 0$"],
                ["C. Bayes' rule", "III. $P(A \\cup B)=P(A)+P(B)-P(A \\cap B)$"],
                ["D. Multiplication theorem on probability", "IV. $P(x=r)={ }^{n} C_{r} p^{r} q^{n-r}, r=0,1, \\ldots, n$"],
            ]
        },
        choices: [
            "A-II, B-IV, C-II, D-I",
            "A-III, B-IV, C-I, D-II",
            "A-II, B-IV, C-I, D-II",
            "A-III, B-I, C-IV, D-II"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=wIFC8EuVYOk&t=1020s"
    },
    {
        qid: 9,
        question: "Given below are two statements: One is labelled as Assertion A and the other is labeled as Reason R. Assertion A: If the A.M. and G.M. between two numbers are in the ratio $\\mathrm{m}: \\mathrm{n}$, then the numbers are in the ratio $m+\\sqrt{m^{2}-n^{2}} m-\\sqrt{m^{2}-n^{2}}$. Reason R: If each term of a G.P. be raised to the same power, the resulting sequence also forms a G.P. In the light of the above statements, choose the correct answer from the options given below:",
        choices: [
            "Both $A$ and $R$ are true and $R$ is the correct explanation of $A$",
            "Both $A$ and $R$ are true but $R$ is not the correct explanation of $A$",
            "$A$ is true but $R$ is false",
            "$A$ is false but $R$ is true"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=wIFC8EuVYOk&t=1105s"
    },
    {
        qid: 10,
        question: "Let $\\mathrm{E}$ be the ellipse $\\frac{x^{2}}{9}+\\frac{y^{2}}{4}=1$ and $\\mathrm{C}$ be the circle $x^{2}+y^{2}=9$. Let $\\mathrm{P}$ and $\\mathrm{Q}$ be the points (1, 2 ) and $(2,1)$ respectively, then",
        choices: [
            "$Q$ lies inside $C$ but outside E",
            "$Q$ lies outside both $C$ and $E$",
            "$\\mathrm{P}$ lies inside both $\\mathrm{C}$ and $\\mathrm{E}$",
            "$\\mathrm{P}$ lies inside $\\mathrm{C}$ but outside $\\mathrm{E}$"
        ],
        correctAnswer: 3,
        videoLink: "https://www.youtube.com/watch?v=wIFC8EuVYOk&t=1617s"
    },
    {
        qid: 11,
        question: "If a chord which is normal to the parabola $y^{2}=4 a x$ at one end subtends a right angle at the vertex, then its slope is",
        choices: [
            "$1$",
            "$\\sqrt{3}$",
            "$\\sqrt{2}$",
            "$2$"
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=umN69AxE3xs&t=54s"
    },
    {
        qid: 12,
        question: "A straight line has equation $y=-x+6$. Which of the following line is parallel to it?",
        choices: [
            "$2 y+3 x=-5$",
            "$-3 x-3 y+7=0$",
            "$2 y=-x+12$",
            "$y-x=\\frac{1}{10}$"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=umN69AxE3xs&t=503s"
    },
    {
        qid: 13,
        question: "Match List - I with List- II",
        tableData: {
            columnHeaders: ["List - I", "List - II"],
            rows: [
                ["A. Value of $\\lim _{x \\rightarrow 0}\\left(\\frac{\\sin x}{x}\\right)^{\\frac{\\sin x}{x-\\sin x}}$ is", "I. $e^{3}$"],
                ["B. Value of is $\\lim _{x \\rightarrow 0} \\frac{\\int_{0}^{x} \\sin t^{2} d t}{x^{2}}$ is", "II. 0"],
                ["C. Value of $\\lim _{x \\rightarrow 0}\\left(e^{2 x}+x\\right)^{\\frac{1}{x}}$ is", "III. 1"],
                ["D. Value $\\lim _{x \\rightarrow a} \\frac{\\log (x-a)}{\\log \\left(e^{x}-e^{a}\\right)}$ of", "IV. $e^{-1}$"]
            ]
        },
        choices: [
            "A-II, B-III, C-I, D-IV",
            "A-II, B-IV, C-III, D-I",
            "A-IV, B-II, C-III, D-I",
            "A-IV, B-II, C-I, D-III"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=umN69AxE3xs&t=577s"
    },
    {
        qid: 14,
        question: "Match List-I with List- II",
        tableData: {
            columnHeaders: ["List - I", "List - II"],
            rows: [
                ["A. The angle between the straight lines $2 x^{2}+3 y^{2}-7 x y=0$ is", "I. $\\tan ^{-1} \\frac{3}{5}$"],
                ["B. The circle $x^{2}+y^{2}+x+y=0$ and $x^{2}+y^{2}+x-y=0$ intersect at angle", "II. $25 \\pi$"],
                ["C. The area of circle centered at $(1,2)$ and passing through $(4,6)$ is", "III. $\\frac{\\pi}{4}$"],
                ["D. The parabolas $y^{2}=4 x$ and $x^{2}=32 y$ intersect at point $(16,8)$ at angle", "IV. $\\frac{\\pi}{2}$"]
            ]
        },
        choices: [
            "A-IV, B-III, C-I, D-II",
            "A-IV, B-III, C-II, D-I",
            "A-III, B-IV, C-II, D-I",
            "A-III, B-IV, C-I, D-II"
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=umN69AxE3xs&t=1405s"
    },
    {
        qid: 15,
        question: "Given below are two statements: One is labelled as Assertion A and the other is labelled as Reason R. Assertion A: An elevator starts with $m$ passengers and stops at $n$ floors $(m \\leq n)$. The probability that no two passengers alight at the same floor is $\\frac{{ }^{n} P_{m}}{m^{n}}$. Reason R: If $(n+1) p$ is an integer, say $m$, then $P(x=r)={ }^{n} C_{r} p^{r}(1-p)^{n-r}$ is maximum when $r=m$ or $r=m-1$. In the light of the above statements, choose the most appropriate answer from the options given below:",
        choices: [
            "Both $A$ and $R$ are correct and $R$ is the correct explanation of $A$",
            "Both $A$ and $R$ are correct but $R$ is not the correct explanation of $A$",
            "$A$ is correct but $R$ is not correct",
            "A is not correct but $R$ is correct"
        ],
        correctAnswer: 3,
        videoLink: "https://www.youtube.com/watch?v=umN69AxE3xs&t=1969s"
    },
    {
        qid: 16,
        question: "The tangent to the hyperbola $x^{2}-y^{2}=3$ is parallel to the straight line $2 x+y+8=0$ at the following points:",
        choices: [
            "$(2,2),(1,2)$",
            "$(2,-1),(-2,1)$",
            "$(-2,-1),(1,2)$",
            "$(-2,-1),(-1,-2)$"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=vM93XnTR-Oc&t=21s"
    },
    {
        qid: 17,
        question: "A circle $S$ passes through the point $(0,1)$ and is orthogonal to the circles $(x-1)^{2}+y^{2}=16$ and $x^{2}+y^{2}=1$, then",
        choices: [
            "Radius of $S$ is 8",
            "Radius of $S$ is 7",
            "Centre of $S$ is $(-7,1)$",
            "Centre of $S$ is $(-8,1)$"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=vM93XnTR-Oc&t=248s"
    },
    {
        qid: 18,
        question: "Given below are two statements: One is labelled as Assertion A and the other is labeled as Reason R. Assertion A: The number of parallelograms in a chess board is 1296. Reason R: The number of parallelograms when a set of $m$ parallel lines is intersected by another set of $n$ parallel liners is ${ }^{m} C_{2} \\cdot{ }^{n} C_{2}$. In the light of the above statements, choose the correct answer from the options given below:",
        choices: [
            "Both $A$ and $R$ are true and $R$ is the correct explanation of $A$",
            "Both $A$ and $R$ are true but $R$ is not the correct explanation of $A$",
            "$A$ is true but $R$ is false",
            "$A$ is false but $R$ is true"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=vM93XnTR-Oc&t=624s"
    },
    {
        qid: 19,
        question: "Given below are two statements: One is labelled as Assertion $A$ and the other is labelled as Reason R. Assertion A: If two circles intersect at two points, then the line joining their centers is perpendicular to the common chord. Reason R: The perpendicular bisector of two chords of a circle intersects at its center. In the light of the above statements, choose the correct answer from the options given below",
        choices: [
            "Both $A$ and $R$ are true and $R$ is the correct explanation of $A$",
            "Both $A$ and $R$ are true but $R$ is not the correct explanation of $A$",
            "$\\mathrm{A}$ is true but is false",
            "A is false but is true"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=vM93XnTR-Oc&t=877s"
    },
    {
        qid: 20,
        question: "A triangle with vertices $(4,0),(-1,-1),(3,5)$ is",
        choices: [
            "Isosceles and right angled",
            "Isosceles but not right angled",
            "Right angled but not isosceles",
            "Neither right angled nor isosceles"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=vM93XnTR-Oc&t=1145s"
    },
    {
        qid: 21,
        question: "Given below are two statements: Statement I: $\\int_{-a}^{a} f(x) d x=\\int_{0}^{a}[f(x)+f(-x)] d x$ Statement II: $\\int_{0}^{1} \\sqrt{(1+x)\\left(1+x^{3}\\right)} d x$ is less than or equal to $\\frac{15}{8}$. In the light of the above statements, choose the most appropriate answer from the options given below:",
        choices: [
            "Both Statement I and Statement II are True",
            "Both Statement I and Statement II are false",
            "Statement I is true but Statement II is false",
            "Statement I is false but Statement II is true"
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=ZcT6VjD0QQM&t=18s"
    },
    {
        qid: 22,
        question: "Given below are two statements: One is labelled as Assertion A and the other is labelled as Reason R. Assertion A: $\\int_{-3}^{3}\\left(x^{3}+5\\right) d x=30$ Reason R: $f(x)=x^{3}+5$ is an odd function. In the light of the above statements, choose the correct answer from the options given below:",
        choices: [
            "Both $A$ and $R$ are true and $R$ is the correct explanation of $A$",
            "Both $A$ and $R$ are true and $R$ is not the correct explanation of $A$",
            "$A$ is true but $R$ is false",
            "$A$ is false but $R$ is true"
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=ZcT6VjD0QQM&t=279s"
    },
    {
        qid: 23,
        question: "Which of the following functions is differentiable at $x=0$ ?",
        choices: [
            "$\\cos (|x|)+|x|$",
            "$\\cos (|x|)-|x|$",
            "$\\sin (|x|)+|x|$",
            "$\\sin (|x|)-|x|$"
        ],
        correctAnswer: 3,
        videoLink: "https://www.youtube.com/watch?v=ZcT6VjD0QQM&t=416s"
    },
    {
        qid: 24,
        question: "If $f$ and $g$ are differentiable functions in $(0,1)$ satisfying $f(0)=2=g(1), g(0)=0$ and $f(1)=6$, then for some $c \\in] 0,1[$",
        choices: [
            "$2 f^{\\prime}(c)=g^{\\prime}(c)$",
            "$2 f^{\\prime}(c)=3 g^{\\prime}(c)$",
            "$f^{\\prime}(c)=g^{\\prime}(c)$",
            "$f^{\\prime}(c)=2 g^{\\prime}(c)$"
        ],
        correctAnswer: 3,
        videoLink: "https://www.youtube.com/watch?v=ZcT6VjD0QQM&t=843s"
    },
    {
        qid: 25,
        question: "If the curve $a y+x^{2}=7$ and $x^{3}=y$ cut orthogonally at $(1,1)$ then the value of '$a$' is",
        choices: [
            "$1$",
            "$6$",
            "$-6$",
            "$0$"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=ZcT6VjD0QQM&t=1181s"
    },
    {
        qid: 26,
        question: "Given below are two statements: One is labelled as Assertion A and the other is labelled as Reason R. Assertion A: If $a \\neq b$, then $(a, b) \\neq(b, a)$. Reason R: $(4,-3)$ lies in quadrant IV. In the light of the above statements, choose the correct answer from the options given below:",
        choices: [
            "Both $A$ and $R$ are true and $R$ is the correct explanation of $A$",
            "Both $A$ and $R$ are true and $R$ is not the correct explanation of $A$",
            "$A$ is true but $R$ is false",
            "$A$ is false but $R$ is true"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=aNz1kBbyelw&t=20s"
    },
    {
        qid: 27,
        question: "The point(s) at which function $f$ given by $f(x)=\\left\\{\\begin{array}{ll}\\frac{x}{|x|}, & x < 0 \\\\ -1, & x \\geq 0\\end{array}\\right.$ is continuous, is/are",
        choices: [
            "$x \\in \\mathbb{R}$",
            "$x=0$",
            "$x \\in \\mathbb{R} \\backslash\\{0\\}$",
            "$-1$ and $1$"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=aNz1kBbyelw&t=128s"
    },
    {
        qid: 28,
        question: "Match List - I with List - II",
        tableData: {
            columnHeaders: ["List - I", "List - II"],
            rows: [
                ["A. No. of triangles formed using 5 points in a line and 3 points on parallel line is", "I. 20"],
                ["B. No. of diagonals drawn using the vertices of an octagon", "II. 10"],
                ["C. The no. of diagonals in a regular polygon of 100 sides is", "III. 45"],
                ["D. A polygon with 35 diagonals has sides", "IV. 4850"]
            ]
        },
        choices: [
            "A-I, B-II, C-III, D-IV",
            "A-II, B-III, C-I, D-IV",
            "A-III, B-IV, C-I, D-II",
            "A-III, B-I, C-IV, D-II"
        ],
        correctAnswer: 3,
        videoLink: "https://www.youtube.com/watch?v=aNz1kBbyelw&t=225s"
    },
    {
        qid: 29,
        question: "Which of the following is true: A. If $a \\cos A=b \\cos B$, then the triangle is isosceles or right angled. B. If in a triangle $A B C, \\cos A \\cos B+\\sin A \\sin B \\sin C=1$, then the triangle is isosceles right angled. C. If the ex-radii $r_{1}, r_{2}, r_{3}$ of $\\triangle A B C$ are in the H.P., then it's sides are not in A.P. Choose the correct answer from the options given below.",
        choices: [
            "A and B only",
            "B and C only",
            "C only",
            "B only"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=aNz1kBbyelw&t=660s"
    },
    {
        qid: 30,
        question: "Given below are two statements: One is labelled as Assertion A and the other is labelled as Reason R. Assertion A: $f(x)=\\tan ^{2} x$ is continuous at $x=\\frac{\\pi}{2}$. Reason R: $g(x)=x^{2}$ is continuous at $x=\\frac{\\pi}{2}$. In the light of the above statements, choose the correct answer from the options given below:",
        choices: [
            "Both $A$ and $R$ are true and $R$ is the correct explanation of $A$",
            "Both $A$ and $R$ are true and $R$ is not the correct explanation of $A$",
            "$A$ is true but $R$ is false",
            "$A$ is false but $R$ is true"
        ],
        correctAnswer: 3,
        videoLink: "https://www.youtube.com/watch?v=aNz1kBbyelw&t=1459s"
    },
    {
        qid: 31,
        question: "For $0<\\theta<\\frac{\\pi}{2}$, the solution(s) of $\\sum_{m=1}^{6} \\operatorname{cosec}\\left(\\theta+(m-1) \\frac{\\pi}{4}\\right) \\operatorname{cosec}\\left(\\theta+\\frac{m \\pi}{4}\\right)=4 \\sqrt{2}$ is/are",
        choices: [
            "$\\frac{\\pi}{4}$ and $\\frac{\\pi}{6}$ only",
            "$\\frac{\\pi}{12}$ and $\\frac{5 \\pi}{12}$ only",
            "$\\frac{\\pi}{4}$ and $\\frac{\\pi}{12}$ only",
            "$\\frac{\\pi}{6}$ and $\\frac{5 \\pi}{12}$ only"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=vNs3_wJrej8&t=102s"
    },
    {
        qid: 32,
        question: "If $\\sin \\beta$ is the G.M. between $\\sin \\alpha$ and $\\cos \\alpha$, then $\\cos 2 \\beta$ is equal to",
        choices: [
            "$2 \\sin ^{2}\\left(\\frac{\\pi}{4}-\\alpha\\right)$",
            "$2 \\cot ^{2}\\left(\\frac{\\pi}{4}-\\alpha\\right)$",
            "$2 \\cos ^{2}\\left(\\frac{\\pi}{4}-\\alpha\\right)$",
            "$2 \\sin ^{2}\\left(\\frac{\\pi}{4}+\\alpha\\right)$"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=vNs3_wJrej8&t=739s"
    },
    {
        qid: 33,
        question: "The value of $e^{\\log _{10} \\tan 1^{0}+\\log _{10} \\tan 2^{0}+\\log _{10} \\tan 3^{0}+\\ldots+\\log _{10} \\tan 89^{0}}$ is",
        choices: [
            "$0$",
            "$e$",
            "$\\frac{1}{e}$",
            "$1$"
        ],
        correctAnswer: 3,
        videoLink: "https://www.youtube.com/watch?v=vNs3_wJrej8&t=1012s"
    },
    {
        qid: 34,
        question: "Given below are two statements: One is labelled as Assertion A and the other is labelled as Reason R. Assertion A: If dot product and cross product of $\\vec{A}$ and $\\vec{B}$ are zero, it implies that one of the vectors $\\vec{A}$ and $\\vec{B}$ must be null vector. Reason R: Null vector is a vector with a zero magnitude. In the light of the above statements, chose the correct answer from the options given below:",
        choices: [
            "Both $A$ and $R$ are true and $R$ is the correct explanation of $A$",
            "Both $A$ and $R$ are true but $R$ is not correct explanation of $A$",
            "$A$ is the true but $R$ is the false",
            "A is false but $R$ is the true"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=vNs3_wJrej8&t=1190s"
    },
    {
        qid: 35,
        question: "If $\\hat{n}_{1}, \\hat{n}_{2}$ are two-unit vectors and $\\theta$ is the angle between them, then $\\cos \\frac{\\theta}{2}$ is equal to",
        choices: [
            "$\\frac{1}{2}\\left|\\hat{n}_{1}+\\hat{n}_{2}\\right|$",
            "$\\frac{1}{2}\\left|\\hat{n}_{1}-\\hat{n}_{2}\\right|$",
            "$\\frac{1}{2}\\left|\\hat{n}_{1} \\cdot \\hat{n}_{2}\\right|$",
            "$\\frac{\\hat{n}_{1} \\times \\hat{n}_{2}}{2\\left|\\hat{n}_{1}\\right|\\left|\\hat{n}_{2}\\right|}$"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=vNs3_wJrej8&t=1318s"
    },
    {
        qid: 36,
        question: "The moment of the couple formed by the forces $5 \\hat{i}+\\hat{k}$ and $-5 \\hat{i}-\\hat{k}$ acting at the point $(9,-1,2)$ and $(3,-2,1)$ respectively, is",
        choices: [
            "$11 \\hat{i}-\\hat{j}+5 \\hat{k}$",
            "$-\\hat{i}+11 \\hat{j}-5 \\hat{k}$",
            "$-\\hat{i}+11 \\hat{j}+5 \\hat{k}$",
            "$\\hat{i}-\\hat{j}-5 \\hat{k}$"
        ],
        correctAnswer: 3,
        videoLink: "https://www.youtube.com/watch?v=O2y_xRtP-tI&t=15s"
    },
    {
        qid: 37,
        question: "Which of the following is true: A. Two vectors are said to be identical if their difference is zero. B. Velocity is not a vector quantity. C. Projection of one vector on another is not an application of dot product. D. The maximum space rate of change of the function which is increasing direction of line function is known as gradient of scalar function. Choose the most appropriate answer from the options given below:",
        choices: [
            "B and C only",
            "A and C only",
            "A and D only",
            "B and D only"
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=O2y_xRtP-tI&t=189s"
    },
    {
        qid: 38,
        question: "The unit vector orthogonal to the vector $-\\hat{i}+2 \\hat{j}+2 \\hat{k}$ and making equal angles with the $x$ and $y$-axis is (are)",
        choices: [
            "$\\pm \\frac{1}{3}(2 \\hat{i}+2 \\hat{j}-\\hat{k})$",
            "$\\pm \\frac{1}{3}(\\hat{i}+\\hat{j}-\\hat{k})$",
            "$\\pm \\frac{1}{3}(2 \\hat{i}-2 \\hat{j}-2 \\hat{k})$",
            "$\\pm \\frac{1}{3}(\\hat{i}-2 \\hat{j}-2 \\hat{k})$"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=O2y_xRtP-tI&t=344s"
    },
    {
        qid: 39,
        question: "Given below are two statements: Statement I: The angle between the vectors $2 \\hat{i}+3 \\hat{j}+\\hat{k}$ and $2 \\hat{i}-\\hat{j}-\\hat{k}$ is $\\frac{\\pi}{2}$. Statement II: The vector $\\vec{a} \\times(\\vec{b} \\times \\vec{c})$ is coplanar with $\\vec{a}$ and $\\vec{b}$. In the light of the above statements, choose the correct answer from the options given below:",
        choices: [
            "Both Statement I and Statement II are true",
            "Both Statement I and Statement II are false",
            "Statement I is the true but Statement II is false",
            "Statement I is false but Statement II is not true"
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=O2y_xRtP-tI&t=521s"
    },
    {
        qid: 40,
        question: "If the unit vectors $\\vec{a}$ and $\\vec{b}$ are inclined at an angle $2 \\theta$ such that $|\\vec{a} - \\vec{b}| < 1$ and $0 \\leq \\theta \\leq \\pi$ then $\\theta$ lies in the interval",
        choices: [
            "$\\left[0, \\frac{\\pi}{2}\\right]$",
            "$\\left(\\frac{5 \\pi}{6}, \\pi\\right]$",
            "$\\left[\\frac{\\pi}{6}, \\frac{\\pi}{2}\\right]$",
            "$\\left[\\frac{\\pi}{2}, \\frac{5 \\pi}{6}\\right]$"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=O2y_xRtP-tI&t=668s"
    },
    {
        qid: 41,
        question: "Each of the angle between vectors and $\\vec{a}, \\vec{b}$ and $\\vec{c}$ is equal to $60^{\\circ}$. If $|\\vec{a}|=4,|\\vec{b}|=2$ and $|\\vec{c}|=6$ then the modulus of $\\vec{a}+\\vec{b}+\\vec{c}$ is",
        choices: [
            "$10$",
            "$15$",
            "$12$",
            "$20$"
        ],
        correctAnswer: 0,
        videoLink: ""
    },
    {
        qid: 42,
        question: "If $\\vec{a} = 2\\hat{i}+ 2\\hat{j} + 2\\hat{k}$, $\\vec{b} = -\\hat{i}+ 2\\hat{j} + \\hat{k}$ and $\\vec{c} = 3\\hat{i}+ \\hat{j}$ are such that $\\vec{a} + \\gamma \\vec{b}$ is perpendicular to $\\vec{c}$, then determine the value of $\\gamma$?",
        choices: [
            "$3$",
            "$0$",
            "$4$",
            "$8$"
        ],
        correctAnswer: 3,
        videoLink: ""
    },
    {
        qid: 43,
        question: "Given below are two statements: Statement I: The number of different numbers each of 6 digits that can be formed by using all the digits 1, 2, 1, 0, 2, 2 is 50 . Statement II: There are 4536 possibilities of writing the four-digit numbers which have all distinct digits. In the light of the above statements, choose the correct answer from the options given below:",
        choices: [
            "Both Statement I and Statement II are true",
            "Both Statement I and Statement II are false",
            "Statement I is true but Statement II is false",
            "Statement I is false but Statement II is true"
        ],
        correctAnswer: 0,
        videoLink: ""
    },
    {
        qid: 44,
        question: "A person goes in for an examination in which there are four papers with a maximum of $m$ marks from each paper. The number of ways in which one can get $2 m$ marks is",
        choices: [
            "$\\frac{1}{3}(m+1)\\left(2 m^{2}+4 m+1\\right)$",
            "$\\frac{1}{3}(m+1)\\left(2 m^{2}+4 m+2\\right)$",
            "$\\frac{1}{3}(m+1)\\left(2 m^{2}+4 m+3\\right)$",
            "${ }^{2 m+3} C_{3}$"
        ],
        correctAnswer: 0,
        videoLink: ""
    },
    {
        qid: 45,
        question: "If $A, B$ and $C$ are any three sets, then A. $A-(B \\cap C)=(A \\cap B)-(A \\cap C)$ B. $A-(B \\cup C)=(A-B) \\cap(A-C)$ C. $n(A-B)=n(A)-n(A \\cap B)$ D. $A \\cap(B-C)=(A \\cap B) \\cap(A-C)$ Choose the most appropriate answer from the options given below:",
        choices: [
            "A, B, C only",
            "B, C, D only",
            "C, D only",
            "B, C only"
        ],
        correctAnswer: 1,
        videoLink: ""
    },
    {
        qid: 46,
        question: "If each of $n$ numbers $x_{i}=i$ is replaced by $(i+1) x_{i}$, then the new mean is",
        choices: [
            "$\\frac{(n+1)(n+2)}{n}$",
            "$n+1$",
            "$\\frac{(n+1)(n+2)}{3}$",
            "$\\frac{(n+1)(5 n+4)}{6}$"
        ],
        correctAnswer: 2,
        videoLink: ""
    },
    {
        qid: 47,
        question: "The H.P. of two numbers is 4 and the arithmetic mean $\\mathrm{A}$ and geometric mean $\\mathrm{G}$ satisfy the relation $2 A+G^{2}=27$, the numbers are",
        choices: [
            "$6,3$",
            "$5,4$",
            "$5,-25$",
            "$-3,1$"
        ],
        correctAnswer: 0,
        videoLink: ""
    },
    {
        qid: 48,
        question: "If every pair from among the equation $x^{2}+p x+q r=0, x^{2}+q x+r p=0$ and $x^{2}+r x+p q=0$ has a common root, then the product of three common root is",
        choices: [
            "$p q r$",
            "$2 p q r$",
            "$p^{2} q^{2} r^{2}$",
            "$p^{2} q r^{2}$"
        ],
        correctAnswer: 0,
        videoLink: ""
    },
    {
        qid: 49,
        question: "A. If $A$ and $B$ are two invertible matrices, then $(A B)^{-1}=A^{-1} B^{-1}$. B. Every skew symmetric matrix of odd order is invertible. C. If $A$ is non-singular matrix, then $\\left(A^{T}\\right)^{-1}=\\left(A^{-1}\\right)^{T}$. D. If $A$ is an involuntary matrix, then $(I+A)(I-A)=O$. E. A diagonal matrix is both an upper triangular and a lower triangular. Choose the correct answer from the options given below:",
        choices: [
            "A, B, C only",
            "B, D, E only",
            "A, D, E only",
            "C, D, E only"
        ],
        correctAnswer: 3,
        videoLink: ""
    },
    {
        qid: 50,
        question: "If $x, y, z$ are all distinct and $\\left|\\begin{array}{lll}x & x^{2} & 1+x^{3} \\\\ y & y^{2} & 1+y^{3} \\\\ z & z^{2} & 1+z^{3}\\end{array}\\right|=0$, then the value of $x y z$ is",
        choices: [
            "$-2$",
            "$-1$",
            "$-3$",
            "$0$"
        ],
        correctAnswer: 1,
        videoLink: ""
    }
];

export default questions;