import React, { useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { SharedDataContext } from '../../context/SharedDataContext';

const UserInfoComponent = () => {

    const { user } = useContext(UserContext);
    const { exam, subject } = useContext(SharedDataContext);

    return (
        <>
        <div style={{ border: "1px solid black", margin: "0px 12px 12px", padding: "8px 0px" }}>
            <div className='question-numbers' style={{ marginLeft: "24px" }}>
                <div style={{ marginRight: "10px", fontSize: "16px", fontWeight: "bold" }}>
                    Candidate Name:
                </div>
                <div style={{ fontSize: "16px" }}>
                    {user.firstName} {user.lastName}
                </div>
            </div>
            <div className='question-numbers' style={{ marginLeft: "24px" }}>
                <div style={{ marginRight: "10px", fontSize: "16px", fontWeight: "bold" }}>
                    Exam Name:
                </div>
                <div style={{ fontSize: "16px" }}>
                    {exam.name}
                </div>
            </div>
            <div className='question-numbers' style={{ marginLeft: "24px" }}>
                <div style={{ marginRight: "10px", fontSize: "16px", fontWeight: "bold" }}>
                    Subject Name:
                </div>
                <div style={{ fontSize: "16px" }}>
                    {subject.name}
                </div>
            </div>
        </div>
        </>
    )
};

export default UserInfoComponent;