const questions = [
    {
        qid: 1,
        question: "If $\\frac{\\sin \\theta+\\cos \\theta}{\\sin \\theta-\\cos \\theta}=\\frac{17}{7}$, then find the value of $\\left(\\sin ^{4} \\theta-\\cos ^{4} \\theta\\right)$",
        choices: [
            "$119$",
            "$\\frac{12}{5}$",
            "$\\frac{119}{169}$",
            "$\\frac{2}{3}$"
        ],
        correctAnswer: 2,
        videoLink: ""
    },
    {
        qid: 2,
        question: "If $a=\\cos 2 \\alpha+i \\sin 2 \\alpha$ and $b=\\cos 2 \\beta+i \\sin 2 \\beta$ then",
        choices: [
            "$\\sqrt{a b}=\\cos (\\alpha+\\beta)-i \\sin (\\alpha+\\beta)$",
            "$\\sqrt{a b}=\\cos (\\alpha+\\beta)+i \\sin (\\alpha+\\beta)$",
            "$\\sqrt{a b}=\\sin (\\alpha+\\beta)+i \\cos (\\alpha+\\beta)$",
            "$\\sqrt{a b}=\\sin (\\alpha+\\beta)-i \\cos (\\alpha+\\beta)$"
        ],
        correctAnswer: 1,
        videoLink: ""
    },
    {
        qid: 3,
        question: "If $y=x^{3}+x^{2}+x+1$, then $y$",
        choices: [
            "has a local minimum",
            "has a local maximum",
            "neither has a local minimum nor local maximum",
            "both local minimum and local maximum"
        ],
        correctAnswer: 2,
        videoLink: ""
    },
    {
        qid: 4,
        question: "The mean of 50 items is 100 . At the time of calculations, two items 80 and 190 were wrongly taken as 90 and 20 . What is the correct value of the mean?",
        choices: [
            "$102.5$",
            "$103.2$",
            "$103.5$",
            "$104.2$"
        ],
        correctAnswer: 1,
        videoLink: ""
    },
    {
        qid: 5,
        question: "If $f: \\mathbb{R} \\rightarrow \\mathbb{R}$ is defined by $f(x)=3 x^{2}-5$ and $g: \\mathbb{R} \\rightarrow \\mathbb{R}$ by $g(x)=\\frac{x}{x^{2}+1}$, then $g \\circ f(x)$ is",
        choices: [
            "$\\frac{3 x^{2}-5}{9 x^{4}-30 x^{2}+26}$",
            "$\\frac{3 x^{2}+5}{9 x^{4}-6 x^{2}+26}$",
            "$\\frac{3 x^{2}}{x^{4}+2 x^{2}-4}$",
            "$\\frac{3 x^{2}}{9 x^{4}+30 x^{2}-2}$"
        ],
        correctAnswer: 0,
        videoLink: ""
    },
    {
        qid: 6,
        question: "The number of vectors of unit length perpendicular to vectors $\\vec{a}=(1,1,0)$ and $\\vec{b}=(0,1,1)$ is",
        choices: [
            "one",
            "two",
            "three",
            "infinite"
        ],
        correctAnswer: 1,
        videoLink: ""
    },
    {
        qid: 7,
        question: "Let $A(3,0,-1), B(2,10,6)$ and $C(1,2,1)$ be the vertices of a triangle and $\\mathrm{M}$ be the mid-point of $\\mathrm{AC}$. If $\\mathrm{G}$ divides $\\mathrm{BM}$ in the ratio 2: 1 , then $\\cos (\\angle G O A)$ (O being the origin) is equal to",
        choices: [
            "$\\frac{1}{\\sqrt{15}}$",
            "$\\frac{1}{2 \\sqrt{15}}$",
            "$\\frac{1}{\\sqrt{30}}$",
            "$\\frac{1}{6 \\sqrt{10}}$"
        ],
        correctAnswer: 0,
        videoLink: ""
    },
    {
        qid: 8,
        question: "If $2 u=5 x$ is the relation between two variable $u$ and $x$, and harmonic means of $x$ is 0.8 , find the harmonic mean of $u$.",
        choices: [
            "$1.250$",
            "$1.500$",
            "$2$",
            "$0.32$"
        ],
        correctAnswer: 2,
        videoLink: ""
    },
    {
        qid: 9,
        question: "The variance of a series of numbers $2,3,11$ and $x$ is 12.25. Find the value of $x$.",
        choices: [
            "$6$ or $\\frac{14}{3}$",
            "$4$ or $\\frac{16}{3}$",
            "$6$ or $\\frac{16}{3}$",
            "$4$ or $\\frac{14}{3}$"
        ],
        correctAnswer: 0,
        videoLink: ""
    },
    {
        qid: 10,
        question: "If A, G, H be respectively, the A.M., G.M and H.M. of three positive numbers $a, b, c$; then the equation whose roots are these numbers is given by",
        choices: [
            "$x^{3}-3 A x^{2}+G^{3}(3 x-1)=0$",
            "$x^{3}-3 A x^{2}+3\\left(\\frac{G^{3}}{H}\\right) x-G^{3}=0$",
            "$x^{3}+3 A x^{2}+3\\left(\\frac{G^{3}}{H}\\right) x-G^{3}=0$",
            "$x^{3}-3 A x^{2}-3\\left(\\frac{G^{3}}{H}\\right) x+G^{3}=0$"
        ],
        correctAnswer: 1,
        videoLink: ""
    },
    {
        qid: 11,
        question: "If $\\vec{a}, \\vec{b}, \\vec{c}$ are non-coplanar unit vectors such that $\\vec{a} \\times(\\vec{b} \\times \\vec{c})=\\frac{(\\vec{b}+\\vec{c})}{\\sqrt{2}}$, then the angle between $\\vec{a}$ and $\\vec{b}$ is",
        choices: [
            "$\\frac{3 \\pi}{4}$",
            "$\\frac{\\pi}{4}$",
            "$\\frac{\\pi}{2}$",
            "$\\pi$"
        ],
        correctAnswer: 0,
        videoLink: ""
    },
    {
        qid: 12,
        question: "The equation of the bisector of the acute angle between the lines $3 x-4 y+7=0$ and $12 x+5 y-2=0$, is",
        choices: [
            "$99 x-27 y-81=0$",
            "$11 x-3 y+9=0$",
            "$21 x+77 y-101=0$",
            "$21 x+77 y+101=0$"
        ],
        correctAnswer: 1,
        videoLink: ""
    },
    {
        qid: 13,
        question: "If the tangent to the hyperbola $x^{2}-y^{2}=3$ is parallel to the straight line $2 x+y+8=0$, then the points are",
        choices: [
            "$(2,2),(1,2)$",
            "$(2,-1),(-2,1)$",
            "$(-2,-1),(1,2)$",
            "$(-2,-1),(-1,-2)$"
        ],
        correctAnswer: 1,
        videoLink: ""
    },
    {
        qid: 14,
        question: "Function $f(x)=(x+1)^{\\cot x}$ is continuous at $x=0$, then the value of $f(0)$ is",
        choices: [
            "$\\frac{1}{e}$",
            "$0$",
            "$e$",
            "$1$"
        ],
        correctAnswer: 2,
        videoLink: ""
    },
    {
        qid: 15,
        question: "Given below are two statements: One is labelled as Assertion A and the other is labelled as Reason R: Assertion A: Equation $5 x-7 y+z=11,6 x-8 y-z=15$ and $3 x+2 y-6 z=7$, then the system is consistent and has infinitely many solutions. Reason R: If $D=0$ then the 3 linear equations are consistent and have infinitely many solutions if $D_{1}=D_{2}=D_{3}=0$. In the light of the above statements, choose the correct answer from the options given below:",
        choices: [
            "Both $A$ and $B$ are true and $R$ is the correct explanation of $A$",
            "Both $A$ and $R$ are true but $R$ is NOT the correct explanation of $A$",
            "$A$ is true but $R$ is false",
            "$A$ is false but $R$ is true"
        ],
        correctAnswer: 3,
        videoLink: ""
    },
    {
        qid: 16,
        question: "If $\\vec{a}, \\vec{b}$ and $\\vec{c}$ are three non-coplanar vectors, then $(\\vec{a}+\\vec{b}+\\vec{c}) \\cdot[(\\vec{a}+\\vec{b}) \\times(\\vec{a}+\\vec{c})]$ equal to",
        choices: [
            "$0$",
            "$-[\\vec{a} \\vec{b} \\vec{c}]$",
            "$3[\\vec{a} \\vec{b} \\vec{c}]$",
            "$2[\\vec{a} \\vec{b} \\vec{c}]$"
        ],
        correctAnswer: 1,
        videoLink: ""
    },
    {
        qid: 17,
        question: "If $\\alpha$ and $\\beta$ are the roots of a quadratic equation $x^{2}+\\alpha x+\\beta=0$ where $\\beta \\neq 0$, then what is the value of $\\alpha+\\beta$ ?",
        choices: [
            "$3$",
            "$4$",
            "$-1$",
            "$-3$"
        ],
        correctAnswer: 2,
        videoLink: ""
    },
    {
        qid: 18,
        question: "The joint equation of the straight lines $x+y=1$ and $x-y=4$ is",
        choices: [
            "$x^{2}-y^{2}=-4$",
            "$x^{2}-y^{2}=4$",
            "$(x+y-1)(x-y-4)=0$",
            "$(x+y+1)(x-y+4)=0$"
        ],
        correctAnswer: 2,
        videoLink: ""
    },
    {
        qid: 19,
        question: "A beam is supported at its ends by supporters which are 12 meters apart. Since the load is concentrated at its centre, there is a deflection of $3 \\mathrm{~cm}$ at the centre and the deflected beam is in the shape of a parabola. How far from the centre is the deflection $1 \\mathrm{~cm}$ ?",
        choices: [
            "$2 \\sqrt{6} \\mathrm{~m}$",
            "$2 \\sqrt{3} \\mathrm{~m}$",
            "$2 \\sqrt{8} \\mathrm{~m}$",
            "$2 \\sqrt{5} \\mathrm{~m}$"
        ],
        correctAnswer: 0,
        videoLink: ""
    },
    {
        qid: 20,
        question: "The number of common tangents that can be drawn to the circle $x^{2}+y^{2}-4 x-6 y-3=0$ and $x^{2}+y^{2}+2 x+2 y+1=0$ is",
        choices: [
            "One",
            "Two",
            "Three",
            "Four"
        ],
        correctAnswer: 2,
        videoLink: ""
    },
    {
        qid: 21,
        question: "The area of region bounded by the curve $y^{2}=x$, the $y$-axis and between $y=2$ and $y=4$ is",
        choices: [
            "$\\frac{52}{3}$ sq. units.",
            "$\\frac{54}{3}$ sq. units.",
            "$\\frac{56}{3}$ sq. units.",
            "$\\frac{58}{3}$ sq. units."
        ],
        correctAnswer: 2,
        videoLink: ""
    },
    {
        qid: 22,
        question: "The area of the region bounded by the curve $x^{2}=4 y$ and the straight line ${ }^{x=4 y-2}$ is",
        choices: [
            "$\\frac{3}{8}$ sq. units.",
            "$\\frac{5}{8}$ sq. units.",
            "$\\frac{7}{8}$ sq. units.",
            "$\\frac{9}{8}$ sq. units."
        ],
        correctAnswer: 3,
        videoLink: ""
    },
    {
        qid: 23,
        question: "If $\\log _{3} 2, \\log _{3}\\left(2^{x}-5\\right)$ and $\\log _{3}\\left(2^{x}-\\frac{7}{2}\\right)$ are in A.P., then $x$ is equal to",
        choices: [
            "$2$",
            "$3$",
            "$4$",
            "$2,3$"
        ],
        correctAnswer: 1,
        videoLink: ""
    },
    {
        qid: 24,
        question: "A tower standing on a horizontal plane subtends a certain angle at a point $160 \\mathrm{~m}$ apart from the foot of the tower. On advancing $100 \\mathrm{~m}$ towards it, the tower is found to subtend an angle twice as before. Find the height of the tower?",
        choices: [
            "$80 \\mathrm{~m}$",
            "$100 \\mathrm{~m}$",
            "$160 \\mathrm{~m}$",
            "$200 \\mathrm{~m}$"
        ],
        correctAnswer: 0,
        videoLink: ""
    },
    {
        qid: 25,
        question: "If $\\tan \\alpha=\\frac{1}{3}, \\tan \\beta=\\frac{1}{7}$ and $2 \\alpha+\\beta=m$, then $\\sin 2 m$ is equal to",
        choices: [
            "$0$",
            "$1$",
            "$2$",
            "$3$"
        ],
        correctAnswer: 1,
        videoLink: ""
    },
    {
        qid: 26,
        question: "If an angle $\\alpha$ is divided into two-part $A$ and $B$ such that $A-B=x$ and $\\tan A: \\tan B=k: 1$, then the value of $\\sin x$ is",
        choices: [
            "$\\frac{k+1}{k-1} \\sin \\alpha$",
            "$\\frac{k}{k+1} \\sin \\alpha$",
            "$\\frac{k-1}{k+1} \\sin \\alpha$",
            "$\\frac{k+1}{k} \\cos \\alpha$"
        ],
        correctAnswer: 2,
        videoLink: ""
    },
    {
        qid: 27,
        question: "The base of a tower is $80 \\mathrm{~m}$ away a point $\\mathrm{A}$ on the ground. If the angle of elevation of the top of the tower from $\\mathrm{A}$ is $45^{\\circ}$. Calculate the height of the tower.",
        choices: [
            "$56.3 \\mathrm{~m}$",
            "$80.0 \\mathrm{~m}$",
            "$136.3 \\mathrm{~m}$",
            "$50.3 \\mathrm{~m}$"
        ],
        correctAnswer: 1,
        videoLink: ""
    },
    {
        qid: 28,
        question: "If the angle between the two lines $2 x^{2}+5 x y+3 y^{2}+6 x+7 y+4=0$ is represented by $\\tan ^{-1}(m)$, then $m$ is equal to",
        choices: [
            "$\\frac{1}{5}$",
            "$\\frac{2}{5}$",
            "$\\frac{3}{5}$",
            "$\\frac{1}{20}$"
        ],
        correctAnswer: 0,
        videoLink: ""
    },
    {
        qid: 29,
        question: "The lines $2 x+y-1=0, a x+3 y-3=0$ and $3 x+2 y-2=0$ are concurrent for",
        choices: [
            "for all $a$",
            "$a=4$ only",
            "$-1 \\leq a \\leq 3$",
            "$a>0$ only"
        ],
        correctAnswer: 0,
        videoLink: ""
    },
    {
        qid: 30,
        question: "Given below are two statements: One is labelled as assertion A and the other is labelled as Reason R: Assertion A: If the equation $x^{2}+p x+q=0$ has rational roots and $\\mathrm{p}$ and $\\mathrm{q}$ are integers, then the roots are integers. Reason R: A quadratic equation has rational roots if and only if its discriminant is a perfect square of a rational number. In the light of the above Statements, choose the most appropriate answer from the options given below:",
        choices: [
            "Both $\\mathrm{A}$ and $\\mathrm{R}$ are correct and $\\mathrm{R}$ is the correct explanation of $\\mathrm{A}$",
            "Both $\\mathrm{A}$ and $\\mathrm{R}$ are correct but $\\mathrm{R}$ is NOT the correct explanation of $\\mathrm{A}$",
            "$\\mathrm{A}$ is correct but $\\mathrm{R}$ is not correct",
            "$\\mathrm{A}$ is not correct but $\\mathrm{R}$ is correct"
        ],
        correctAnswer: 0,
        videoLink: ""
    },
    {
        qid: 31,
        question: "A bag contains 10 red beads (R) and 5 black beads (B). A bead is taken out of the bag at random, its color is noted and then it is not placed back in the bag. Calculate the probability that first two beads are red.",
        choices: [
            "$\\frac{4}{7}$",
            "$\\frac{5}{7}$",
            "$\\frac{1}{3}$",
            "$\\frac{3}{7}$"
        ],
        correctAnswer: 3,
        videoLink: ""
    },
    {
        qid: 32,
        question: "Which of the following is not true? A. If $a, b, c$ are in A.P. and $a, 2 b, c$ are in G.P. then $a, 4 b, c$ are in H.P. B. The number 361 is not a term of the sequence $a_{n}=n^{2}+2 n+1$. C. If $p, q$ are the roots of $(x-a)(x-b)-m=0$, then $a, b$ are roots of $(x-p)(x-q)+m=0$. D. The expression $9^{x}-3^{x}+1$ can assume all real values for $x \\in \\square$. Choose the correct answer from the options given below:",
        choices: [
            "A, B and D only",
            "B and C only",
            "A and C only",
            "B and D only"
        ],
        correctAnswer: 3,
        videoLink: ""
    },
    {
        qid: 33,
        question: "Which of the following is not true? A. Sum of the vectors directed from the vertices to the mid-point of opposite side of a triangle is zero. B. Medians of a triangle are not concurrent. C. The diagonals of a quadrilateral bisect each other iff it is a parallelogram. D. Vectors having the different terminal point are called co - terminus vectors. Choose the correct answer from the options given below:",
        choices: [
            "B and C only",
            "B and D only",
            "B, C and D only",
            "A and B only"
        ],
        correctAnswer: 1,
        videoLink: ""
    },
    {
        qid: 34,
        question: "Given below are two statements: Statement I: The vector equation of line passing through two points with position vector $\\vec{a}$ and $\\vec{b}$ is $\\vec{r}=\\vec{a}+\\lambda(\\vec{b}+\\vec{a})$. Statement II: The vector equation of a straight line passing through a fixed point with position vector $\\vec{a}$ and parallel to a given vector $\\vec{b}$ is $\\vec{r}=\\vec{a}+\\lambda \\vec{b}$, where $\\lambda$ is scalar. In the light of the above Statements, choose the most appropriate answer from the options given below:",
        choices: [
            "Both statement I and Statement II are correct",
            "Both statement I and Statement II are incorrect",
            "Statement I is correct but Statement II is incorrect",
            "Statement I is incorrect but Statement II is correct"
        ],
        correctAnswer: 3,
        videoLink: ""
    },
    {
        qid: 35,
        question: "Given below are two statements: Statement I: Let $\\vec{a}, \\vec{b}, \\vec{c}$ be three vectors. Then the scalar $(\\vec{a} \\times \\vec{b}) . \\vec{c}$ is called the scalar product of $\\vec{a}, \\vec{b}, \\vec{c}$ and is denoted by $[\\vec{a} \\vec{b} \\vec{c}]=\\vec{a} \\cdot(\\vec{b} \\times \\vec{c})$. Statement II: $\\vec{a} \\times \\vec{b}$ is vector which is $\\perp$ to $\\vec{a}$ and $\\vec{b}$ both. In the light of the above Statements, choose the most appropriate answer from the options given below:",
        choices: [
            "Both statement I and Statement II are correct",
            "Both statement I and Statement II are incorrect",
            "Statement I is correct but Statement II is incorrect",
            "Statement I is incorrect but Statement II is correct"
        ],
        correctAnswer: 0,
        videoLink: ""
    },
    {
        qid: 36,
        question: "Given below are two statements: Statement I: The locus of the middle points of chords of the circle $x^{2}+y^{2}=a^{2}$ which are parallel to the line $y=m x$ is $x-m y=0$. Statement II: The two tangents drawn from $(4,2)$ to $x^{2}+y^{2}=10$ are perpendicular. In the light of the above Statements, choose the most appropriate answer from the options given below:",
        choices: [
            "Both statement I and Statement II are correct",
            "Both statement I and Statement II are incorrect",
            "Statement I is correct but Statement II is incorrect",
            "Statement I is incorrect but Statement II is correct"
        ],
        correctAnswer: 3,
        videoLink: ""
    },
    {
        qid: 37,
        question: "Given below are two statements: Statement I: Three points with position vectors $\\vec{a}, \\vec{b}, \\vec{c}$ are collinear iff there exists scalars $x, y, z$ not all zero such that $x \\vec{a}+y \\vec{b}+z \\vec{c}=\\overrightarrow{0}$, where $x+y+z=0$. Statement II: Scalar product and vector product are both commutative. In the light of the above Statements, choose the most appropriate answer from the options given below:",
        choices: [
            "Both statement I and Statement II are correct",
            "Both statement I and Statement II are incorrect",
            "Statement I is correct but Statement II is incorrect",
            "Statement I is incorrect but Statement II is correct"
        ],
        correctAnswer: 2,
        videoLink: ""
    },
    {
        qid: 38,
        question: "Given below are two statements: Statement I: The gradient of a straight line is constant, i.e., it does not change. Statement II: The gradient of a straight line can be calculated by considering the coordinates of any two different points on the line. In the light of the above Statements, choose the most appropriate answer from the options given below:",
        choices: [
            "Both statement I and Statement II are correct",
            "Both statement I and Statement II are incorrect",
            "Statement I is correct but Statement II is incorrect",
            "Statement I is incorrect but Statement II is correct"
        ],
        correctAnswer: 0,
        videoLink: ""
    },
    {
        qid: 39,
        question: "Let $f(x)=x^{2}$ and $A$ denote the area of the region bounded by $f(x)$ and the $x$-axis, when $x$ varies from -1 to 1 . Which of the following statements is /are true? A. $f$ is continuous in $[-1,1]$ B. $f$ is not bounded in $[-1,1]$ C. A is nonzero and finite Choose the correct answer from the options given below:",
        choices: [
            "A and B only",
            "B and C only",
            "C only",
            "A and C only"
        ],
        correctAnswer: 3,
        videoLink: ""
    },
    {
        qid: 40,
        question: "Which of the following is not correct? A. If $A, B$ are two matrices such that $A B=A$ and $B A=B$, then $A$ and $B$ are idempotent. B. Every skew - symmetric matrix of odd order is invertible. C. If $A$ is a non - singular matrix, then $A^{-1}$ is skew - symmetric. D. The adjoint of a diagonal matrix is a diagonal matrix. Choose the correct answer from the options given below:",
        choices: [
            "A and D only",
            "B and C only",
            "B, C and D only",
            "C and D only"
        ],
        correctAnswer: 1,
        videoLink: ""
    },
    {
        qid: 41,
        question: "Consider the following statements: A. If $P=\\{m, n\\}$ and $Q=\\{n, m\\}$, then $P \\times Q=\\{(m, n),(n, m)\\}$. B. If $A$ and $B$ are the non-empty sets, then $A \\times B$ is a nonempty set of ordered pairs $(x, y)$ such that $x \\in A$ and $y \\in B$. C. If $A=\\{1,2\\}, B=\\{3,4\\}$, then $A \\times(B \\cap \\phi)=\\phi$. Choose the most appropriate answer from the options given below:",
        choices: [
            "A and B only",
            "B and C only",
            "A and C only",
            "A, B and C only"
        ],
        correctAnswer: 1,
        videoLink: ""
    },
    {
        qid: 42,
        question: "Which of the following assertions are correct? A. Adding 7 to each entry in a list adds 7 to the mean of the list. B. Adding 7 to each entry in a list adds 7 to the standard deviation of the list. C. Doubling each entry in a list doubles the mean of the list. D. Doubling each entry in a list leaves the standard deviation of the list unchanged. Choose the most appropriate answer from the options given below:",
        choices: [
            "A and B only",
            "B and C only",
            "A and C only",
            "C and D only"
        ],
        correctAnswer: 2,
        videoLink: ""
    },
    {
        qid: 43,
        question: "Match List - I with List - II and choose the correct answer from the given options.",
        tableData: {
            columnHeaders: ["List - I", "List - II"],
            rows: [
              ["A. $\\quad \\lim _{x \\rightarrow 0}(1+\\sin x)^{2 \\cot x}$", "I. $e^{-\\frac{1}{6}}$"],
              ["B. $\\quad \\lim _{x \\rightarrow 0} \\frac{e^{x}-(1+x)}{x^{2}}$", "II. e"],
              ["C. $\\lim _{x \\rightarrow 0}\\left(\\frac{\\sin x}{x}\\right)^{1 / x^{2}}$", "III. $e^{2}$"],
              ["D. $\\lim _{x \\rightarrow \\infty}\\left(\\frac{x+2}{x+1}\\right)^{x+3}$", "IV. $\\frac{1}{2}$"]
            ]
          },
        choices: [
            "A - III, B - IV, C - II, D - I",
            "A - III, B - IV, C - I, D - II",
            "A - I, B - II, C - III, D - IV",
            "A - II, B - I, C - III, D - IV"
        ],
        correctAnswer: 1,
        videoLink: ""
    },
    {
        qid: 44,
        question: "Given below are two statements: Statement I: For $n \\geq 1$, we have $\\frac{1}{1.2}+\\frac{1}{2.3}+\\frac{1}{3.4}+\\ldots+\\frac{1}{n(n+1)}=\\frac{n^{2}}{n+1}$. Statement II: $(1+x)^{n} \\geq(1+n x)$ for all-natural number $n$, where $x>-1$. In the light of the above statement, choose the most appropriate answer from the options given below:",
        choices: [
            "Both Statement I and Statement II are true",
            "Both Statement I and Statement II are false",
            "Statement I is true but statement II is false",
            "Statement I is false but statement II is true"
        ],
        correctAnswer: 3,
        videoLink: ""
    },
    {
        qid: 45,
        question: "If $a, b, c, d$ are in Harmonic progression then A. $a+d>b+c$ B. $a+c=b+d$ C. $a d>b c$ D. $a^{n}+c^{n}>2 b^{n}$ E. $a+d<b+c$ Choose the correct answer from the options given below:",
        choices: [
            "A, C and E only",
            "A, B and C only",
            "A, C and D only",
            "C, D and E only"
        ],
        correctAnswer: 2,
        videoLink: ""
    },
    {
        qid: 46,
        question: "Given below are two statements: One is labelled as assertion A and the other is labelled as Reason R: Assertion A: Sum of $n$ terms of series $1^{2}+3^{2}+5^{2}+\\ldots$ to $n$ terms is $\\frac{n}{3}\\left(4 n^{2}-1\\right)$. Reason R: Sum of the squares of first $n$ naturals numbers $1^{2}, 2^{2}, 3^{2}, \\ldots, n^{2}$ is $\\frac{n(n+1)(2 n+1)}{6}$ In the light of the above Statements, choose the correct answer from the options given below:",
        choices: [
            "Both $A$ and $R$ are true and $R$ is the correct explanation of $A$",
            "Both $A$ and $R$ are true but $R$ is NOT the correct explanation of $A$",
            "$A$ is true but $R$ is not false",
            "$A$ is false but $R$ is true"
        ],
        correctAnswer: 0,
        videoLink: ""
    },
    {
        qid: 47,
        question: "Given below are two statements: One is labelled as assertion A and the other is labelled as Reason R: Assertion A: $\\cos \\left(x^{2}\\right)$, is a periodic function. Reason R: If $f(x)$ is periodic function with periods T and if $a, b \\in \\square$ such that $a>0$ then $(a x+b)$ is periodic with period $\\frac{T}{a}$. In the light of the above Statements, choose the correct answer from the options given below:",
        choices: [
            "Both $A$ and $R$ are true and $R$ is the correct explanation of $A$",
            "Both $A$ and $R$ are true but $R$ is NOT the correct explanation of $A$",
            "$A$ is true but $R$ is not false",
            "$A$ is false but $R$ is true"
        ],
        correctAnswer: 3,
        videoLink: ""
    },
    {
        qid: 48,
        question: "Given below are two statements: Statement I: The sum of the series $2\\left\\{7^{-1}+3^{-1} \\cdot 7^{-3}+5^{-1} \\cdot 7^{-5}+\\ldots\\right\\}$ is $\\log _{e}\\left(\\frac{4}{3}\\right)$ Statement II: The value of e always lies between 2 and 3. In the light of the above Statements, choose the most appropriate answer from the options given below:",
        choices: [
            "Both statement I and Statement II are true",
            "Both statement I and Statement II are false",
            "Statement I is true but Statement II is false",
            "Statement I is false but Statement II is true"
        ],
        correctAnswer: 0,
        videoLink: ""
    },
    {
        qid: 49,
        question: "The mean deviation from the mean of the AP: $a, a+d, a+2 d, \\ldots, a+2 n d$, is",
        choices: [
            "$n(n+1) d$",
            "$\\frac{n(n+1)}{2 n+1} d$",
            "$\\frac{n(n+1)}{2 n} d$",
            "$\\frac{n(n-1)}{2 n+1} d$"
        ],
        correctAnswer: 1,
        videoLink: ""
    },
    {
        qid: 50,
        question: "Let $f:[0, \\pi] \\rightarrow \\mathbb{R}$ be defined as $f(x)=\\sin x$, then maximum value of $f(x)$ exists at",
        choices: [
            "$\\frac{\\pi}{2}$",
            "$\\frac{\\pi}{3}$",
            "$\\frac{\\pi}{6}$",
            "$\\frac{\\pi}{4}$"
        ],
        correctAnswer: 0,
        videoLink: ""
    }
];

export default questions;