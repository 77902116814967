import React, { useState, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const AlertModalComponent = ({ show, onHide, message }) => {

    return (
        <Modal show={show} centered>
            <Modal.Header>
                <Modal.Title>{message}</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="primary" onClick={onHide} >Sign Up</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AlertModalComponent;
