import '../css/sanmacs.webflow.css';
import { Routes, Route, useNavigate } from 'react-router-dom'
import React, { useState, useEffect, useContext } from 'react';

import { UserContext } from '../context/UserContext';
import { SharedDataProvider } from '../context/SharedDataContext';

import DashLayout from './dashboard/DashLayout';
import TestSectionComponent from './TestSectionComponent';
import QuestionPanel from './QuestionPanel';
import ExamSummaryComponent from './ExamSummaryComponent';
import ScoreCardComponent from './ScoreCardComponent';
import InstructionsComponent from './InstructionsComponent';
import MockResultsComponent from './MockResultsComponent';
import ExamSelectorComponent from './ExamSelectorComponent';
import AuthComponent from './auth/AuthComponent';

import axios from '../api/axios';
import MenuComponent from './MenuComponent';
import PreviousTestResultsComponent from './PreviousTestResultsComponent';

const TestPortalComponent = () => {

    const navigate = useNavigate();

    const { user } = useContext(UserContext);

    const [practiceSetId, setPracticeSetId] = useState('');

    const [questionsCount, setQuestionsCount] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [questionsStatus, setQuestionsStatus] = useState([]);
    const [selectedAnswers, setSelectedAnswers] = useState({});

    const [scoreCard, setScoreCard] = useState({});
    const [answerDecisionForSC, setAnswerDecisionForSC] = useState([]);

    const [previousMockResultQuestions, setPreviousMockResultQuestions] = useState([]);
    const [previousMockResultScorecard, setPreviousMockResultScorecard] = useState({});

    // Function to handle questions submission
    const handleQuestionsSubmit = () => {
        navigate('/mock-test-portal/exam-summary');
    };

    // Function to handle "Yes" button click in ExamSummaryComponent
    const handleYesSubmitConfirm = () => {
        const COMPUTE_RESULT = `/exams/practiceSet/computeResult`;

        var requestData = JSON.stringify({
            "userId": user._id,
            "practiceSetId": practiceSetId,
            "selectedAnswers": selectedAnswers
        });

        axios.post(COMPUTE_RESULT, requestData)
            .then(response => {
                setScoreCard(response?.data.scoreCard)
                setAnswerDecisionForSC(response?.data.answerDecisionForUI)
            })
            .catch(error => {
                console.error('Error fetching scorecard:', error);
            });

        navigate('/mock-test-portal/scorecard');
    };

    // Function to handle "No" button click in ExamSummaryComponent
    const handleNoSubmitConfirm = () => {
        navigate(-1);
    };

    const handleMockSelection = async (exam, subject, mock) => {

        const GET_PRACTICE_SET = `/exams/practiceSet/${mock}`;

        axios.get(GET_PRACTICE_SET)
            .then(response => {
                setQuestions(response?.data?.questions);
                setPracticeSetId(response?.data?._id);
            })
            .catch(error => {
                console.error('Error fetching exams:', error);
            });

        navigate('/mock-test-portal/acknowledge');
    }

    const handleSetPreviousMockResults = (data) => {
        setPreviousMockResultQuestions(data?.questions)
        setPreviousMockResultScorecard(data?.scoreCard)
        navigate(`/mock-test-portal/view-previous-mock-results/show`)
    }

    useEffect(() => {
        setQuestionsStatus(Array(questions.length).fill('not-visited'))
        setQuestionsCount(questions.length)
    }, [questions, previousMockResultQuestions]);

    console.log(selectedAnswers);

    return (
        <>
            <SharedDataProvider>
                <Routes>
                    <Route path="/" element={<DashLayout />}>
                        {/* Authentication */}
                        <Route index element={<AuthComponent showLogin={true} />} />
                        {/* Menu */}
                        <Route path="menu" element={<MenuComponent />} />
                        {/* Practice Area */}
                        <Route path="mock-selector" element={<ExamSelectorComponent setMockSelected={handleMockSelection} />} />
                        <Route path="acknowledge" element={<InstructionsComponent questionsCount={questionsCount} />} />
                        <Route path="begin-test" element={<QuestionPanel questions={questions} questionsStatus={questionsStatus} setQuestionsStatus={setQuestionsStatus} selectedAnswers={selectedAnswers} setSelectedAnswers={setSelectedAnswers} onSubmit={handleQuestionsSubmit} sectionTimer={84000 * questionsCount}/>} />
                        <Route path="begin-section-test" element={<TestSectionComponent questions={questions} questionsStatus={questionsStatus} setQuestionsStatus={setQuestionsStatus} selectedAnswers={selectedAnswers} setSelectedAnswers={setSelectedAnswers} onSubmit={handleQuestionsSubmit} />} />
                        <Route path="exam-summary" element={<ExamSummaryComponent questionsStatus={questionsStatus} onYesClick={handleYesSubmitConfirm} onNoClick={handleNoSubmitConfirm} />} />
                        <Route path="scorecard" element={<ScoreCardComponent questions={questions} selectedAnswers={selectedAnswers} scoreCard={scoreCard} answerDecisions={answerDecisionForSC} />} />
                        {/* Past Mock Test Results */}
                        <Route path="view-previous-mock-results" element={<PreviousTestResultsComponent showMockResults={handleSetPreviousMockResults} />} />
                        <Route path="view-previous-mock-results/show" element={<MockResultsComponent scoreCard={previousMockResultScorecard} questions={previousMockResultQuestions} />} />
                    </Route>
                </Routes>
            </SharedDataProvider>
        </>
    );
};

export default TestPortalComponent;