import React from 'react';
import InformationBannerComponent from '../ui-theme/InformationBannerComponent';

const DashHeader = () => {
  return (
    <InformationBannerComponent
      displayText={"Welcome to our Assessment Portal - a Test Practice Centre for CBTs."}
      backgroundColor={"rgb(235, 128, 42)"}
    />
  );
};

export default DashHeader;