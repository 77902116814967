import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { UserProvider } from './context/UserContext';
import NotificationBar from './components/ui-theme/NotificationBar';
import NavBar from './components/ui-theme/NavBar';
import PyqComponent from './components/PyqComponent.js';

import TestPortalComponent from './components/TestPortal.js';

function App() {
  return (
    <div>
      <NotificationBar />
      <NavBar />
      <UserProvider>
        <Routes>
          <Route path="*" element={<PyqComponent />} />
            <Route path="/mock-test-portal/*" element={<TestPortalComponent />} />
        </Routes>
      </UserProvider>
    </div>
  );
}

export default App;
