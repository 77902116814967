import React, { useState, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';

const OTPValidationComponent = ({ show, onHide, onVerification }) => {

    const [otpValue, setOtpValue] = useState('');
    const inputRef = useRef(null);
    const isAnyEmpty = otpValue.length !== 6;

    const handleInputChange = (e) => {
        const { value } = e.target;
        // Limit the input to only allow digits and maximum length of 6
        const newValue = value.replace(/\D/g, '').slice(0, 6);
        setOtpValue(newValue);
    };

    const handleVerification = () => {
        onVerification(otpValue);
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header>
                <Modal.Title>Enter One-Time Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form action="#">
                    <div>
                        <input
                            type="text"
                            value={otpValue}
                            onChange={handleInputChange}
                            maxLength="6"
                            autoFocus
                            ref={inputRef}
                        />
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Re-send OTP</Button>
                <Button variant="primary" onClick={handleVerification} disabled={isAnyEmpty}>Verify OTP</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default OTPValidationComponent;
