import '../css/sanmacs.webflow.css';
import React, { useState, useEffect } from 'react';

const QuizComponent = ({ heading, subheading, questions }) => {
  // Assume the questions data is imported from a file named 'questionsData.js'
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://polyfill.io/v3/polyfill.min.js?features=es6'; // Load Polyfill.io for ES6 support
    script.onload = () => {
      // Load MathJAX script
      const mathJaxScript = document.createElement('script');
      mathJaxScript.async = true;
      mathJaxScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.7/MathJax.js?config=TeX-MML-AM_CHTML';
      mathJaxScript.onload = () => {
        window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub]); // Typeset equations once MathJAX is fully loaded
      };
      document.body.appendChild(mathJaxScript);
    };
    document.body.appendChild(script);
  }, []);

  const createQuestionDiv = (questionObj, index) => {
    return (
      <div key={index} id={`question${index}`} className="question-container">
        <hr />
        <label style={{ fontSize: '16px' }}>Question.{index + 1}</label>
        <label className="pull-right" style={{ marginLeft: '15px' }}></label>
        <span style={{ display: 'none' }}>1</span>
        <hr />
        <div className="question-text">{questionObj.question}</div>
        {/* Render table before answer choices */}
        {questionObj.tableData && (
          <>
          <table border="1">
            <thead>
              <tr>
                {questionObj.tableData.columnHeaders.map((header, index) => (
                  <th key={index} style={{ textAlign: 'center', padding: '0px 5px 0px 5px' }}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {questionObj.tableData.rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td key={cellIndex} style={{ textAlign: 'center', padding: '0px 5px 0px 5px' }}>{cell}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <br />
          </>
        )}
        {/* Render image before the question if image path exists */}
        {questionObj.image && (
          <>
            <img src={questionObj.image.src} alt={`Question ${index + 1}`} height={questionObj.image.height} width={questionObj.image.width} />
            <br /><br />
          </>
        )}
        <div>
          {questionObj.choices.map((choice, i) => (
            <label key={i} className="answer-choice" id={`${index}-${i}`}>
              <input className="input-radio-button" type="radio" name={`answer${index + 1}`} value={i} />
              {typeof choice === 'string' ? (choice) : (<img src={choice.src} alt={`Choice ${i}`} height="75" width="75" />)}
            </label>
          ))}
        </div>
        <br />
        <button onClick={() => checkAnswer(questionObj.correctAnswer, `answer${index + 1}`, index, questionObj.videoLink)}>
          Check Your Answer
        </button>
        <div>
          <div id={`result${index + 1}`}></div>
          <a id={`video${index + 1}`}></a>
        </div>
      </div>
    );
  };

  const checkAnswer = (correctAnswer, groupName, questionIndex, videoLink) => {
    const selectedAnswer = document.querySelector(`input[name="${groupName}"]:checked`);
    if (selectedAnswer) {
      const userAnswer = parseInt(selectedAnswer.value, 10);
      const resultElement = document.getElementById(`result${questionIndex + 1}`);
      const videoElement = document.getElementById(`video${questionIndex + 1}`);
      const selectedChoice = document.getElementById(`${questionIndex}-${userAnswer}`);
      const correctChoice = document.getElementById(`${questionIndex}-${correctAnswer}`);

      resultElement.innerText = userAnswer === correctAnswer ? 'Correct! Well done.' : 'Incorrect.';
      resultElement.style.color = userAnswer === correctAnswer ? 'green' : 'red';

      if (videoLink !== '#') {
        videoElement.href = videoLink;
        videoElement.textContent = userAnswer === correctAnswer ? 'Watch a Shortcut' : 'Watch Video Solution';
        videoElement.setAttribute('target', '_blank');
      }

      const checkmark = document.createElement('span');
      checkmark.className = 'checkmark';
      checkmark.innerText = '✔';

      const cross = document.createElement('span');
      cross.className = 'cross';
      cross.innerText = '❌';

      if (userAnswer === correctAnswer) {
        selectedChoice.appendChild(checkmark);
      } else {
        selectedChoice.appendChild(cross);
        correctChoice.appendChild(checkmark);
      }

      const answerChoices = document.querySelectorAll(`input[name="${groupName}"]`);
      answerChoices.forEach((choice) => {
        choice.disabled = true;
      });
    } else {
      alert('Please select an answer before submitting.');
    }
  };

  return (
    <section class="section resources-hero">
        <div class="container-default w-container">
            <div class="well"> 
                <h2 class="text-center"><b><i class="fas fa-folder-open" style={{ fontSize: '26px' }}></i>{heading}</b></h2>
                <h4 class="text-center" style={{ color: '#ff0000' }}><b>{subheading}</b></h4>
                <div id="qus-pallete">
                    <div id="questions-container">{questions.map((question, index) => createQuestionDiv(question, index))}</div>
                </div>
            </div>
        </div>
    </section>
  );
};

export default QuizComponent;