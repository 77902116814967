import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../context/UserContext';
import { useContext } from 'react';
import '../css/menu.css';
import LogoutButton from './buttons/LogoutButtonComponent';

const MenuComponent = () => {

    const navigate = useNavigate();

    const { user } = useContext(UserContext);

    const previousMocksNotExists = !user.mocksAttempted || Object.keys(user.mocksAttempted).length === 0;

    const handleStartMock = () => {
        navigate("/mock-test-portal/mock-selector")
    }

    const handleViewPreviousMockResults = () => {
        navigate("/mock-test-portal/view-previous-mock-results")
    }

    return (
        <>
        <LogoutButton />
        <div className="horizontal-bars-container">
            <Button className="horizontal-bar" onClick={() => handleStartMock()}>
                {/* Content for the first button */}
                Start A New Mock Test
            </Button>
            <Button className="horizontal-bar" disabled={previousMocksNotExists} onClick={() => handleViewPreviousMockResults()}>
                {/* Content for the second button */}
                View Previous Mock Test Results
            </Button>
        </div>
        </>
    );
}

export default MenuComponent;