const questions = [
    {
        qid: 1,
        question: "Half of a large Pizza is cut into 4 equal-sized pieces, and the other half is cut into 6 equal – sized pieces. If a person were to eat 1 of the larger pieces and 2 of the smaller pieces, what fraction of the pizza would remain uneaten?",
        choices: [
          "$\\frac{5}{12}$",
          "$\\frac{17}{24}$",
          "$\\frac{7}{12}$",
          "$\\frac{13}{24}$"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=UMKiTdh43CM&t=235s"
      },
      {
        qid: 2,
        question: "In a business, A and C invested amounts in the ratio 2 : 1, whereas the ratio between amounts invested by A and B was 3 : 2. If Rs. 1, 57, 300 was their profit, how much amount did B receive?",
        choices: [
          "Rs. 24,200",
          "Rs. 72,600",
          "Rs. 36,300",
          "Rs. 48,400"
        ],
        correctAnswer: 3,
        videoLink: "https://www.youtube.com/watch?v=UMKiTdh43CM&t=392s"
      },
      {
        qid: 3,
        question: "Company A produced toy trucks at a cost of Rs. 50.00 each for the first 100 trucks and Rs. 35.00 for each additional truck. If 500 toy trucks were produced by Company A and sold for Rs. 100.00 each what was Company A’s gross profit?",
        choices: [
          "Rs. 31,000",
          "Rs. 15,000",
          "Rs. 21,000",
          "Rs. 13,000"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=UMKiTdh43CM&t=566s"
      },
      {
        qid: 4,
        question: "A shopkeeper fixes the marked price of an item 35 % above its cost price. The percentage of discount allowed to gain 8 % is",
        choices: [
          "43 %",
          "27 %",
          "20 %",
          "31 %"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=UMKiTdh43CM&t=800s"
      },
      {
        qid: 5,
        question: "Choose the odd one out",
        choices: [
          "Mohammed Rafi",
          "K.L. Saigal",
          "Nausad",
          "Adnan Sami"
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=UMKiTdh43CM&t=994s"
      },
      {
        qid: 6,
        question: "Which of the following options is the correct alphabetical order of the four words given as: Rumbustious, Rumanian, Rumour, Rumple",
        choices: [
          "Rumanian, Rumple, Rumour,	Rumbustious	",
          "Rumour, Rumbustious,	Rumple, Rumanian",
          "Rumanian, Rumbustious, Rumour, Rumple",
          "Rumple, Rumour, Rumbustious, Rumanian"
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=_8iLMA9YDYs&t=135s"
      },
      {
        qid: 7,
        question: "If ‘ORANGE’ is coded as ‘MTYPEG’ in a certain language, then how will ‘CHILLY’ be coded in that language?",
        choices: [
          "AJGNNW",
          "AJGNJA",
          "AJKNNW",
          "AJGOOW"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=_8iLMA9YDYs&t=228s"
      },
      {
        qid: 8,
        question: "Choose the odd one out from the following",
        choices: [
          "Morarji Desai",
          "V.P. Singh",
          "Narendra Modi",
          "Sardar Patel"
        ],
        correctAnswer: 3,
        videoLink: "https://www.youtube.com/watch?v=_8iLMA9YDYs&t=648s"
      },
      {
        qid: 9,
        question: "Which will be the missing pattern in the series? Redraw figure",
        image: {
          src: "images/questions/cuet-2022-reasoning/q9.png",
          height: "75",
          width: "275"
        },
        choices: [
          {
            text: "choice 1",
            src: "images/questions/cuet-2022-reasoning/q9-option1.png"
          },
          {
            text: "choice 2",
            src: "images/questions/cuet-2022-reasoning/q9-option2.png"
          },
          {
            text: "choice 3",
            src: "images/questions/cuet-2022-reasoning/q9-option3.png"
          },
          {
            text: "choice 4",
            src: "images/questions/cuet-2022-reasoning/q9-option4.png"
          }
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=_8iLMA9YDYs&t=727s"
      },
      {
        qid: 10,
        question: "If ‘MANGO’ is coded as ‘KCLIM’ in a certain language then how will ‘SWEETY’ be coded in that language",
        choices: [
          "QYCGRA",
          "QYCCRA",
          "QYGCRA",
          "YQCGRA"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=_8iLMA9YDYs&t=1138s"
      },
      {
        qid: 11,
        question: "Which among the following words comes first in a dictionary? Shrub, Shudder, Shroud, Shuttle",
        choices: [
          "Shrub",
          "Shuttle",
          "Shroud",
          "Shudder"
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=b5Jxzf71WwU&t=120s"
      },
      {
        qid: 12,
        question: "Which of the following options is the correct alphabetical order of the words four words given below? Sport, Squash, Sporadic, Sprout",
        choices: [
          "Sport, Sprout, Sporadic, Squash",
          "Sporadic, Sport, Sprout, Squash",
          "Sporadic, Sport, Squash, Sprout",
          "Squash, Sprout, Sport, Sporadic"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=b5Jxzf71WwU&t=198s"
      },
      {
        qid: 13,
        question: "What will be the Next Pattern in the series",
        image: {
          src: "images/questions/cuet-2022-reasoning/q13.png",
          height: "75",
          width: "275"
        },
        choices: [
          {
            text: "choice 1",
            src: "images/questions/cuet-2022-reasoning/q13-option1.png"
          },
          {
            text: "choice 2",
            src: "images/questions/cuet-2022-reasoning/q13-option2.png"
          },
          {
            text: "choice 3",
            src: "images/questions/cuet-2022-reasoning/q13-option3.png"
          },
          {
            text: "choice 4",
            src: "images/questions/cuet-2022-reasoning/q13-option4.png"
          }
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=b5Jxzf71WwU&t=308s"
      },
      {
        qid: 14,
        question: "Complete the series by filling in the missing number 2, 5, 14, 57, 284, ?",
        choices: [
          "1420",
          "1421",
          "1704",
          "1705"
        ],
        correctAnswer: 3,
        videoLink: "https://www.youtube.com/watch?v=b5Jxzf71WwU&t=487s"
      },
      {
        qid: 15,
        question: "A + B means A is daughter or B means A is son on B A – B means A is the wife of B means. Statement I: S is father of P Statement II: P is daughter of P In the light of the above statements, choose the correct answer from the options given below.",
        choices: [
          "Both Statement I and Statement II are true",
          "Both Statement I and Statement II are false",
          "Statement I is true but Statement II is false",
          "Statement I is false but Statement II is true"
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=b5Jxzf71WwU&t=585s"
      },
      {
        qid: 16,
        question: "Given the following statements and five possible conclusions. All scientists working in America are talented. Some Indian scientists are working in America. A. None of Indian scientists are talented. B. Some talented Indian scientists have migrated to America. C. All talented scientists are Indians. D. Some Indian scientists are talented. E. Scientists working India are not talented. Choose the correct answer from the options given below:",
        choices: [
          "Conclusions A and D only are correct.",
          "Conclusion B and D only are correct.",
          "Conclusions A and E only are correct.",
          "Conclusions B and E only are correct."
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=3aTuVF87DEE&t=87s"
      },
      {
        qid: 17,
        question: "Ministers arrived at the public function in their cars. Consider the following statements. A. All ministers are rich. B. Minister have official cars in general. C. Minister usually participate in public functions. D. Only rich Ministers arrive in cars. E. Person with cars can only be a Minister. Choose the correct answer from the options given below",
        choices: [
          "A and B only",
          "B and D only",
          "B and C only",
          "C and E only"
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=3aTuVF87DEE&t=388s"
      },
      {
        qid: 18,
        question: "Pointing towards a boy, a girl said, “He is the son of the daughter of the father of my uncle”. How is the boy related to the girl?",
        choices: [
          "Brother",
          "Uncle",
          "Son-in-law",
          "Nephew"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=3aTuVF87DEE&t=622s"
      },
      {
        qid: 19,
        question: "If A + B means A is the mother of B; A – B means A is the brother of B; A % B means A is the father of B and  means A is the sister of B, which of the following shows that P is the maternal uncle of Q?",
        choices: [
          "$Q - N + M \\times P$",
          "$P + S \\times N - Q$",
          "$P - M + N \\times Q$",
          "$Q - S \\% P$"
        ],
        correctAnswer: 2,
        videoLink: "https://www.youtube.com/watch?v=3aTuVF87DEE&t=963s"
      },
      {
        qid: 20,
        question: "If Z = 52 and ACR = 48, then RAT will be equal to",
        choices: [
          "$72$",
          "$78$",
          "$92$",
          "$64$"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=3aTuVF87DEE&t=1301s"
      },
      {
        qid: 21,
        question: "There are equations that have become wrong due to the incorrect order of signs. From the four alternatives, given below, find out the correct order of signs. So that the equation becomes right 8 + 9 = 9 – 81.",
        choices: [
          "$-, \\times, +$",
          "$+, -, \\div$",
          "$=, +, -$",
          "$\\times, +, -$"
        ],
        correctAnswer: 3,
        videoLink: "https://www.youtube.com/watch?v=x_c6pW3KCn0&t=99s"
      },
      {
        qid: 22,
        question: "If $+$ means $*$, $/$ means $-$, $*$ means $/$ and $-$ means $+$, what will be the value of $4+11/5-55=?$",
        choices: [
          "$-48.5$",
          "$-11$",
          "$79$",
          "None of these"
        ],
        correctAnswer: 3,
        videoLink: "https://www.youtube.com/watch?v=x_c6pW3KCn0&t=329s"
      },
      {
        qid: 23,
        question: "If $(25)^{7.5} \\times(5)^{2.5} \\div(125)^{1.5}=5^{x}$, then $x=$",
        choices: [
            "$13$",
            "$8.5$",
            "$16$",
            "$17.5$"
        ],
        correctAnswer: 0,
        videoLink: "https://www.youtube.com/watch?v=x_c6pW3KCn0&t=467s"
      },
      {
        qid: 24,
        question: "Given below are two statements: One is labelled as Assertion A and the other is labelled as Reason R. Assertion A: In a class of 40 students 22 drink Sprite, 10 drink Sprite but not Pepsi. Then the number of students who drink both Sprite and Pepsi is 15. Reason R: For any two finite sets A and B, In the light of the above statements, choose the most appropriate answer from the options given below.",
        choices: [
          "Both A and R are correct and R is the correct explanation of A.",
          "Both A and R are correct but R is not the correct explanation of A.",
          "A is correct but R is not correct.",
          "A is not correct but R is correct."
        ],
        correctAnswer: 3,
        videoLink: "https://www.youtube.com/watch?v=x_c6pW3KCn0&t=782s"
      },
      {
        qid: 25,
        question: "What is the value of $\\left(\\tan ^{2}(90-\\theta)-\\sin ^{2}(90-\\theta)\\right) \\operatorname{cosec}(90-\\theta) \\cot ^{2}(90-\\theta)$",
        choices: [
          "$0$",
          "$1$",
          "$-1$",
          "$2$"
        ],
        correctAnswer: 1,
        videoLink: "https://www.youtube.com/watch?v=x_c6pW3KCn0&t=1094s"
      }
];

export default questions; 