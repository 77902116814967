import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AlertBar from '../components/ui-theme/AlertBar'
import QuizComponent from './QuizComponent';

import cuet_2023_shift2_math from '../data/cuet-2023-shift2-math.js'
import cuet_2023_shift2_reasoning from '../data/cuet-2023-shift2-reasoning.js'
import cuet_2023_shift3_math from '../data/cuet-2023-shift3-math.js'
import cuet_2023_shift3_reasoning from '../data/cuet-2023-shift3-reasoning.js'
import cuet_2022_math from '../data/cuet-2022-math.js'
import cuet_2022_reasoning from '../data/cuet-2022-reasoning.js'
import nimcet_2023_math from '../data/nimcet-2023-math.js'

const PyqComponent = () => {
  return (
    <>
    <AlertBar />
    <Routes>
        <Route path="/cuet-2023-shift2-math" element={
          <QuizComponent 
            heading="CUET Previous Year Questions (PYQs)" 
            subheading="CUET 2023 [SHIFT 2] Actual Paper (Mathematics Section)" 
            questions={cuet_2023_shift2_math} 
          />
        } />
        <Route path="/cuet-2023-shift2-reasoning" element={
          <QuizComponent 
            heading="CUET Previous Year Questions (PYQs)" 
            subheading="CUET 2023 [SHIFT 2] Actual Paper (Thinking and Decision Making Section)" 
            questions={cuet_2023_shift2_reasoning} 
          />
        } />
        <Route path="/cuet-2023-shift3-math" element={
          <QuizComponent 
            heading="CUET Previous Year Questions (PYQs)" 
            subheading="CUET 2023 [SHIFT 3] Actual Paper (Mathematics Section)" 
            questions={cuet_2023_shift3_math} 
          />
        } />
        <Route path="/cuet-2023-shift3-reasoning" element={
          <QuizComponent 
            heading="CUET Previous Year Questions (PYQs)" 
            subheading="CUET 2023 [SHIFT 3] Actual Paper (Thinking and Decision Making Section)" 
            questions={cuet_2023_shift3_reasoning} 
          />
        } />
        <Route path="/cuet-2022-math" element={
          <QuizComponent 
            heading="CUET Previous Year Questions (PYQs)" 
            subheading="CUET 2022 Actual Paper (Mathematics Section)" 
            questions={cuet_2022_math} 
          />
        } />
        <Route path="/cuet-2022-reasoning" element={
          <QuizComponent 
            heading="CUET Previous Year Questions (PYQs)" 
            subheading="CUET 2022 Actual Paper (Thinking and Decision Making Section)" 
            questions={cuet_2022_reasoning} 
          />
        } />
        <Route path="/nimcet-2023-math" element={
          <QuizComponent 
            heading="NIMCET Previous Year Questions (PYQs)" 
            subheading="NIMCET 2023 Actual Paper (Mathematics Section)" 
            questions={nimcet_2023_math} 
          />
        } />
    </Routes>
    </>
  );
};

export default PyqComponent;
