import { useRef, useState, useEffect } from "react";
import OTPValidationComponent from './OTPValidationComponent.js';
import LoadingComponent from '../helper/LoadingComponent.js';
import ErrorHandlingModal from "../helper/ErrorHandlingModal.js";
import axios from '../../api/axios.js';

import '../../css/otpverification.css'

const GENERATE_OTP_URL = '/users/sendOtp';
const REGISTER_URL = '/users/signup'
const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const PHONE_REGEX = /^\d{10}$/; // Regex for 10 digits

const SignUpComponent = ({ handleAuthentication }) => {

    const [showErrorModal, setShowErrorModal] = useState(false);

    const userRef = useRef();
    const errRef = useRef();

    // State variables for OTP modal
    const [showOtpModal, setShowOtpModal] = useState(false);

    const [firstName, setFirstName] = useState('');
    const [firstNameFocus, setFirstNameFocus] = useState(false);
    const [lastName, setLastName] = useState('');
    const [lastNameFocus, setLastNameFocus] = useState(false);

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [phone, setPhone] = useState('');
    const [validPhone, setValidPhone] = useState(false);
    const [phoneFocus, setPhoneFocus] = useState(false);

    const [college, setCollege] = useState('');
    const [collegeFocus, setCollegeFocus] = useState(false);
    const [city, setCity] = useState('');
    const [cityFocus, setCityFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email));
    }, [email])

    useEffect(() => {
        setValidPhone(PHONE_REGEX.test(phone));
    }, [phone]);

    // Function to handle signup form submission
    const handleSignup = async (e) => {
        e.preventDefault();

        try {
            setLoading(true)

            var requestData = JSON.stringify({ "phone": phone, "requestSource": "register" })
            const response = await axios.post(GENERATE_OTP_URL, requestData);

            if (response?.status === 200) {
                setShowOtpModal(true); // Show OTP modal for verification
            } else if (response?.status === 208) {
                console.log("Phone number already associated to an account")
                setErrMsg("Phone number already associated to an account")
            } else {
                setShowErrorModal(true);
            }
        } catch (err) {
            setErrMsg('Failed to generate One Time Password')
            setShowErrorModal(true);
        } finally {
            setLoading(false)
        }
    };

    // Function to handle OTP verification
    const handleVerification = async (otpValue) => {
        try {
            
            // If OTP verification successful, proceed with signup
            const signUpResponse = await axios.post(REGISTER_URL, JSON.stringify({
                firstName,
                lastName,
                email,
                phone,
                college,
                city,
                "oneTimePassword": otpValue,
            }));
            
            if (signUpResponse?.status === 200) {
                setSuccess(true);
                handleAuthentication(signUpResponse?.data);
            } else {
                setShowErrorModal(true);
            }

            //clear state and controlled inputs
            setFirstName('');
            setLastName('');
            setEmail('');
            setPhone('');
            setCollege('');
            setCity('');

        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 409) {
                setErrMsg('Username Taken');
            } else {
                setErrMsg('Registration Failed')
            }
            setShowErrorModal(true);
        }
    };

    // Function to handle OTP modal close
    const handleCloseOtpModal = () => {
        setShowOtpModal(false);
        // Reset OTP values
    };

    // If signedUp is false, render the signup form
    return (
        <div>
            <form onSubmit={handleSignup}>
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="firstname" className="auth-label">First Name</label>
                        <input
                            type="text"
                            id="firstname"
                            className="form-control" 
                            placeholder="Please enter first name"
                            ref={userRef}
                            autoComplete="off"
                            onChange={(e) => setFirstName(e.target.value)}
                            value={firstName}
                            required
                            onFocus={() => (true)}
                            onBlur={() => setFirstNameFocus(false)}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="lastname" className="auth-label">Last Name</label>
                        <input
                            type="text"
                            id="lastname"
                            className="form-control" 
                            placeholder="Please enter last name"
                            ref={userRef}
                            autoComplete="off"
                            onChange={(e) => setLastName(e.target.value)}
                            value={lastName}
                            required
                            onFocus={() => (true)}
                            onBlur={() => setLastNameFocus(false)}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="email" className="auth-label">Email</label>
                    <input
                        type="text"
                        id="email"
                        className="form-control" 
                        placeholder="Please enter email address"
                        ref={userRef}
                        autoComplete="off"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        required
                        onFocus={() => (true)}
                        onBlur={() => setEmailFocus(false)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="phone"  className="auth-label">Phone</label>
                    <input
                        type="text"
                        id="phone"
                        pattern="\d{10}"
                        maxLength="10"
                        className="form-control" 
                        placeholder="(Do not add '+91' and OTP is sent on WhatsApp)"
                        ref={userRef}
                        autoComplete="off"
                        onChange={(e) => setPhone(e.target.value)}
                        value={phone}
                        required
                    />
                </div>
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="college" className="auth-label">College</label>
                        <input
                            type="text"
                            id="college"
                            className="form-control" 
                            placeholder="Please enter college name"
                            ref={userRef}
                            autoComplete="off"
                            onChange={(e) => setCollege(e.target.value)}
                            value={college}
                            required
                            onFocus={() => (true)}
                            onBlur={() => setCollegeFocus(false)}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="city" className="auth-label">City</label>
                        <input
                            type="text"
                            id="city"
                            className="form-control" 
                            placeholder="Please enter city"
                            ref={userRef}
                            autoComplete="off"
                            onChange={(e) => setCity(e.target.value)}
                            value={city}
                            required
                            onFocus={() => (true)}
                            onBlur={() => setCityFocus(false)}
                        />
                    </div>
                </div>
                <div className="form-check"></div>
                <button type="submit" className="auth-button auth-button-block">Get Started</button>
            </form>

            {loading && <LoadingComponent displayText={"Please Wait..."} />}

            <OTPValidationComponent
                show={showOtpModal}
                onHide={handleCloseOtpModal}
                onVerification={handleVerification}
            />

            <ErrorHandlingModal show={showErrorModal} onHide={setShowErrorModal} />
        </div>
    );
};

export default SignUpComponent;
