import React, { useState, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';

const ErrorHandlingModal = ({ show, onHide }) => {

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header>
                <Modal.Title>We are sorry!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Something went wrong. Please try again later.
            </Modal.Body>
            <Modal.Footer>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                <p>If you are still facing issues, please report the issue. </p>
                <a href="https://forms.gle/ZR8hLiRoVD73u2fS9" target='_blank'>
                    <button className="btn btn-warning" style={{ marginLeft: "12px" }}>
                        Report Issue
                    </button>
                </a>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default ErrorHandlingModal;
