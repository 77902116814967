import '../../css/normalize.css';
import '../../css/sanmacs.webflow.css';
import '../../css/webflow.css';

const InformationBannerComponent = ({ displayText, backgroundColor, textColor, url, fontSize, policies}) => {

    const content = (
        <section className="notification-section notification-bar-landscape">
            <div className="top-bar-wrapper" style={{ backgroundColor: `${backgroundColor}` }}>
                <div className="top-bar-padding">
                    <div className="container-default w-container">
                        <div className="top-bar-text list" style={{ lineHeight: "24px", ...(textColor && { color: textColor }), fontWeight: "800", ...(fontSize && { fontSize: fontSize }) }}>
                            {displayText}
                            {url && (
                                <a href={url} target='_blank'>
                                    <button className="btn btn-secondary" style={{ marginLeft: "12px" }}>
                                        Report Issue
                                    </button>
                                </a>
                            )}
                            {policies && (
                                <p>
                                    <a href="https://www.sanmacs.com/policies/privacy-policy" style={{color: "white"}}>Privacy Policy</a> 
                                    <span>&nbsp; and &nbsp;</span>  
                                    <a href="https://www.sanmacs.com/policies/terms-conditions" style={{color: "white"}} >Terms and Conditions</a>
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
    return content
}

export default InformationBannerComponent