import React from 'react';
import InformationBannerComponent from '../ui-theme/InformationBannerComponent';

const DashFooter = () => {
    return (
        <>
            <InformationBannerComponent
                displayText={"This is a beta version. Please fill the following form to report any technical issues."}
                backgroundColor={"rgb(235, 128, 42)"}
                url={"https://forms.gle/jXuN1PMrbj8zHtLD6"}
            />
            <InformationBannerComponent
                displayText={"© All Rights Reserved - Sanmacs Global"}
                backgroundColor={"var(--primary-main)"}
                policies={true}
            />
            <InformationBannerComponent
                displayText={"Disclaimer: Allthe information on this website is published in good faith. Sanmacs does not make any warranties about the completeness, reliability and accuracy of the information. Any action you take on this website is strictly at your own risk."}
                backgroundColor={"rgb(187, 187, 187)"}
                textColor={"black"}
                fontSize={"11px"}
            />
        </>
    );
};

export default DashFooter;