import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from '../api/axios';
import LoadingComponent from './helper/LoadingComponent.js';

import '../css/PurchaseModal.css';

const PURCHASE_URL = '/buy-now';

const PurchaseModal = ({ setShowBuyNowModal, setProcessing, user, subject, mockTests }) => {

    console.log(subject);
    
    const [showModal, setShowModal] = useState(true);
    const [selectedMockTests, setSelectedMockTests] = useState([]);
    const [errMsg, setErrMsg] = useState('');

    //const totalPrice = (subject.name === "SCQP09") ? selectedMockTests.length * 79 : selectedMockTests.length * 49;
    const totalPrice = selectedMockTests.length *  subject.mockTestPrice;

    const handleClose = () => {
        setShowModal(false);
        setShowBuyNowModal(false);
    };

    const handleToggleMockTest = (mockTestId) => {
        if (selectedMockTests.includes(mockTestId)) {
            setSelectedMockTests(selectedMockTests.filter(id => id !== mockTestId));
        } else {
            setSelectedMockTests([...selectedMockTests, mockTestId]);
        }
    };

    const handleBuyNow = async () => {
        try {

            setProcessing(true);
            handleClose();

            // Perform purchase with selected mock tests
            const requestData = {
                "userId": user._id,
                "mockTestIds": selectedMockTests,
                "amount": totalPrice
            };

            const response = await axios.post(PURCHASE_URL, requestData);
            if (response.status === 200) {
                const url = response.data; // Get URL from response
                window.open(url, '_blank'); // Open URL in new tab
            }
        } catch (error) {
            setErrMsg('Error occurred'); // Handle error
        } finally {
            //setProcessing(false); // Close processing modal
        }
    };

    return (
        <>
            <Modal show={showModal} onHide={handleClose} backdrop="static" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Buy Mock Tests - {subject.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>Select the mock tests you want to purchase:</p>
                        {mockTests.map((mockTest, index) => (
                            <div key={mockTest._id} className="mock-test-item">
                                { /* <label htmlFor={mockTest}>Mock Test - {index + 1} ----- @ Rs. {(subject.name === "SCQP09") ? 79 : 49}</label> */ }
                                <label htmlFor={mockTest._id}>{mockTest.name} ----- @ Rs. {subject.mockTestPrice}</label>
                                <input
                                    type="checkbox"
                                    id={mockTest._id}
                                    checked={selectedMockTests.includes(mockTest)}
                                    onChange={() => handleToggleMockTest(mockTest)}
                                />
                                {index !== mockTests.length - 1 && <hr />}
                            </div>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p>Total Amount: Rs. {totalPrice}</p>
                    <Button variant="primary" disabled={totalPrice === 0} onClick={handleBuyNow}>Buy Now</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PurchaseModal;
