import { useRef, useState, useEffect, useContext } from 'react';
import OTPValidationComponent from './OTPValidationComponent.js';
import LoadingComponent from '../helper/LoadingComponent.js';
import ErrorHandlingModal from '../helper/ErrorHandlingModal.js';
import axios from '../../api/axios.js';

import '../../css/otpverification.css'
import '../../css/App.css'
import AlertModalComponent from '../helper/AlertModalComponent.js';

const GENERATE_OTP_URL = '/users/sendOtp';
const LOGIN_URL = '/users/login';
const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const PHONE_REGEX = /^\d{10}$/; // Regex for 10 digits

const LoginComponent = ({ handleAuthentication }) => {

    const [showErrorModal, setShowErrorModal] = useState(false);

    const userRef = useRef();
    const errRef = useRef();

    // Alert Modal
    const [showAlertModal, setShowAlertModal] = useState(false);

    // State variables for OTP modal
    const [showOtpModal, setShowOtpModal] = useState(false);

    const [user, setUser] = useState({});

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [phone, setPhone] = useState('');
    const [validPhone, setValidPhone] = useState(false);
    const [phoneFocus, setPhoneFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email));
    }, [email, user])

    useEffect(() => {
        setValidPhone(PHONE_REGEX.test(phone));
    }, [phone]);

    // Function to handle signup form submission
    const handleSignin = async (e) => {
        e.preventDefault();
        
        try {
            setLoading(true)

            var requestData = JSON.stringify({ "phone": phone, "requestSource": "login" })
            const response = await axios.post(GENERATE_OTP_URL, requestData);

            if (response?.status === 200) {
                setShowOtpModal(true); // Show OTP modal for verification
            } else if (response?.status === 208) {
                console.log("Phone number already associated to an account")
                setErrMsg("Phone number already associated to an account")
            } else {
                setShowErrorModal(true);
            }

        } catch (err) {
            
            if (err?.response?.status === 404 && err?.response?.data === "User Does Not Exist") {
                setShowAlertModal(true);
            }

            setErrMsg('Failed to generate One Time Password')
            setShowErrorModal(true);

        } finally {
            setLoading(false)
        }
    };

    // Function to handle OTP verification
    const handleVerification = async (otpValue) => {
        try {
            
            // If OTP verification successful, proceed with signup
            const signUpResponse = await axios.post(LOGIN_URL, JSON.stringify({
                email,
                phone,
                "oneTimePassword": otpValue
            }));
            
            if (signUpResponse?.status === 200) {
                setSuccess(true);
                handleAuthentication(signUpResponse?.data);
            } else {
                setShowErrorModal(true);
            }

            //clear state and controlled inputs
            setEmail('');
            setPhone('');

        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 409) {
                setErrMsg('Username Taken');
            } else {
                setErrMsg('Registration Failed')
            }
            setShowErrorModal(true);
        }
    };

    // Function to handle OTP modal close
    const handleCloseOtpModal = () => {
        setShowOtpModal(false);
        // Reset OTP values
    };

    // Function to handle OTP modal close
    const handleCloseAlert = () => {
        setShowAlertModal(false);
        // Reset OTP values
    };

    return (
        <div>
            <form onSubmit={handleSignin}>
                <div className="form-group">
                    <label htmlFor="email" className="auth-label">Email</label>
                    <input
                        type="text"
                        id="email"
                        className="form-control" 
                        placeholder="Please enter email address"
                        ref={userRef}
                        autoComplete="off"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        required
                        onFocus={() => (true)}
                        onBlur={() => setEmailFocus(false)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="phone" className="auth-label">Phone</label>
                    <input
                        type="text"
                        id="phone"
                        pattern="\d{10}"
                        maxLength="10"
                        className="form-control" 
                        placeholder="(Do not add '+91' and OTP is sent on WhatsApp)"
                        ref={userRef}
                        autoComplete="off"
                        onChange={(e) => setPhone(e.target.value)}
                        value={phone}
                        required
                        aria-invalid={validPhone ? "false" : "true"}
                        aria-describedby="phonenote"
                        onFocus={() => setPhoneFocus(true)}
                        onBlur={() => setPhoneFocus(false)}
                    />
                </div>
                <div className="form-check"></div>
                <button type="submit" className="auth-button auth-button-block">Log in</button>
            </form>

            {loading && <LoadingComponent displayText={"Please Wait..."} />}

            <OTPValidationComponent
                show={showOtpModal}
                onHide={handleCloseOtpModal}
                onVerification={handleVerification}
            />

            <AlertModalComponent
                show={showAlertModal}
                onHide={handleCloseAlert}
                message={"Please use the Phone Number you signed up with. If you did not sign up yet, please sign up first."}
            />

            <ErrorHandlingModal show={showErrorModal} onHide={setShowErrorModal} />
        </div>
    );
};

export default LoginComponent;