import '../../css/normalize.css';
import '../../css/sanmacs.webflow.css';
import '../../css/webflow.css';

const NotificationBar = ({ displayText }) => {
    const content = (
        <section className="notification-section notification-bar-landscape">
            <div className="top-bar-wrapper">
                <div className="top-bar-padding">
                    <div className="container-default w-container">
                        <div className="top-bar-text list">Preparing students for MCA&nbsp;Entrance Exams and CUETs since 1994.&nbsp;
                            <a href="https://www.sanmacs.com/courses" className="top-bar-join-us-now-link">Learn More</a> |&nbsp; 
                            <a href="https://www.sanmacs.com/enquire-now" className="top-bar-join-us-now-link">Enquire&nbsp;Now!</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
    return content
}

export default NotificationBar