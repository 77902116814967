import React from 'react';
import { useNavigate } from 'react-router-dom';

const HomeButton = () => {
  const navigate = useNavigate();

  const handleHome = () => {
    // Perform logout actions here, such as clearing user data from local storage or making a logout API request

    // After performing logout actions, navigate to the home page
    navigate('/mock-test-portal/menu');
  };

  return (
    <>
    <button style={{marginLeft: '12px'}} onClick={handleHome}>Home</button>
    </>
  );
};

export default HomeButton;