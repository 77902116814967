import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../../context/UserContext';
import SignUpComponent from './SignUpComponent';
import LoginComponent from './LoginComponent';
import '../../css/authenticationform.css'; // Import the CSS file with provided styles

const AuthComponent = ({ showLogin }) => {

    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState('signup');
    const { setUser } = useContext(UserContext);

    const handleAuthentication = (user) => {
        setUser(user);
        navigate('/mock-test-portal/menu');
    }

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className="auth-form">
            <ul className="tab-group">
                <li className={`tab ${activeTab === 'signup' ? 'active' : ''}`} onClick={() => handleTabClick('signup')}>
                    <button>Sign Up</button>
                </li>
                {showLogin ? (
                    <li className={`tab ${activeTab === 'login' ? 'active' : ''}`} onClick={() => handleTabClick('login')}>
                        <button>Log In</button>
                    </li>
                ) : (
                    <li className="tab"> <button>Log In</button></li> // Render disabled login tab
                )}
            </ul>

            <div className="tab-content">
                <div id="signup" className={`tab-pane ${activeTab === 'signup' ? 'active' : ''}`}>
                    <h1 style={{ textAlign: 'center', color: 'white', paddingBottom: '16px' }}>Sign Up for Free</h1>
                    <SignUpComponent handleAuthentication={handleAuthentication} />
                </div>

                <div id="login" className={`tab-pane ${activeTab === 'login' ? 'active' : ''}`}>
                <h1 style={{ textAlign: 'center', color: 'white', paddingBottom: '16px' }}>Welcome Back!</h1>
                    <LoginComponent handleAuthentication={handleAuthentication} />
                </div>
            </div>
        </div>
    );
};

export default AuthComponent;
