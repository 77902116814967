import React, { useState, useEffect } from 'react';
import '../../css/normalize.css';
import '../../css/webflow.css';
import '../../css/sanmacs.webflow.css';

const AlertBar = () => {

    const [position, setPosition] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
        setPosition(prevPosition => {
            // Increase the position by a larger value for faster speed
            const newPosition = prevPosition + 1.5;

            // Reset position to 0 when it reaches the end
            return newPosition >= window.innerWidth ? 0 : newPosition;
        });
        }, 20); // Decrease the interval for faster speed

        return () => clearInterval(intervalId);
    }, []);

    const content = (
        <section className="alert-section overflow-notification-bar">
            <a href="enquire-now" className="elements-overflow-wrapper w-inline-block">
                <div className="elements-overflow-wrapper w-inline-block">
                    <div className="top-bar-overflow-wrapper">
                        <div className="top-bar-overflow-padding">
                            <div className="moving-text-band">
                                <div className="text" style={{ transform: `translateX(-${position}px)` }}>
                                    Admissions&nbsp;&nbsp;Open!&nbsp;&nbsp;•&nbsp;&nbsp;Crash&nbsp;&nbsp;course&nbsp;&nbsp;(Lightning&nbsp;&nbsp;Course)&nbsp;&nbsp;•&nbsp;&nbsp;All&nbsp;&nbsp;India&nbsp;&nbsp;Test&nbsp;&nbsp;Series&nbsp;&nbsp;(AITSC)&nbsp;&nbsp;•&nbsp;<strong className="bold-text">**Limited&nbsp;&nbsp;seats.&nbsp;&nbsp;Enquire&nbsp;&nbsp;Now**</strong>&nbsp;&nbsp;•
                                    Admissions&nbsp;&nbsp;Open!&nbsp;&nbsp;•&nbsp;&nbsp;Crash&nbsp;&nbsp;course&nbsp;&nbsp;(Lightning&nbsp;&nbsp;Course)&nbsp;&nbsp;•&nbsp;&nbsp;All&nbsp;&nbsp;India&nbsp;&nbsp;Test&nbsp;&nbsp;Series&nbsp;&nbsp;(AITSC)&nbsp;&nbsp;•&nbsp;<strong className="bold-text">**Limited&nbsp;&nbsp;seats.&nbsp;&nbsp;Enquire&nbsp;&nbsp;Now**</strong>&nbsp;&nbsp;•
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </section>
    );
    return content
}

export default AlertBar