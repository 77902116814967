import '../css/sanmacs.webflow.css';
import React, { useState, useEffect } from 'react';
import { MathJax } from 'better-react-mathjax';

const QuestionComponent = ({ questions,
    boxIndex,
    updateBoxIndex,
    questionsStatus,
    setQuestionsStatus,
    selectedAnswers,
    setSelectedAnswers
    
    }) => {

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(boxIndex);
    const [currentQuestion, setCurrentQuestion] = useState(questions[currentQuestionIndex]);

    const [userSelectedChoice, setUserSelectedChoice] = useState(null);
    const [markedForReview, setMarkedForReview] = useState(new Set());

    useEffect(() => {
        // This will update currentQuestionIndex if boxIndex changes
        if (currentQuestionIndex !== boxIndex) {
            setCurrentQuestionIndex(boxIndex);
        }
    }, [boxIndex, currentQuestionIndex]);

    // Add another useEffect for updating currentQuestion when questions or currentQuestionIndex changes
    useEffect(() => {
        setCurrentQuestion(questions[currentQuestionIndex]);
    }, [questions, currentQuestionIndex]);

    useEffect(() => {
        RemoveMathJax();
        LoadMathJax();

        setUserSelectedChoice(null);

        if (questionsStatus[boxIndex] === 'not-visited') {
            // Update the question status to "not-answered"
            const updatedQuestionsStatus = [...questionsStatus];
            updatedQuestionsStatus[boxIndex] = 'not-answered';
            setQuestionsStatus(updatedQuestionsStatus);
        }

    }, [currentQuestionIndex, boxIndex, questionsStatus, setQuestionsStatus]);

    // Function to handle selecting an answer for a question
    const handleSelectAnswer = (questionIndex, question, selectedChoice) => {
        setSelectedAnswers(prevState => ({
            ...prevState,
            [question._id]: selectedChoice
        }));
    };

    const handleReviewQuestions = (questionIndex, question, answerState) => {
        if (answerState === "review") {
            // Add the questionIndex to the markedForReview set
            setMarkedForReview(new Set([...markedForReview, question._id]));
        } else if (answerState === "save" && markedForReview.has(question._id)) {
            // Remove the questionIndex from the markedForReview set
            const updatedMarkedForReview = new Set(markedForReview);
            updatedMarkedForReview.delete(question._id);
            setMarkedForReview(updatedMarkedForReview);
        }
    };

    const handleSaveAndNext = (questionIndex, question, selectedAnswer) => {
        if (selectedAnswer) {
            const selectedChoice = parseInt(selectedAnswer.value);
            // Save the selected answer choice
            // For example:
            // Update the state to save the selected answer
            handleSelectAnswer(questionIndex, question, selectedChoice);
            handleReviewQuestions(questionIndex, question, "save");

            const updatedQuestionStatus = [...questionsStatus];
            updatedQuestionStatus[questionIndex] = 'answered'; // Update to the appropriate status
            setQuestionsStatus(updatedQuestionStatus);

            // Move to the next question
            const nextQuestionIndex = (questionIndex < questions.length - 1) ? questionIndex + 1 : questionIndex;
            setCurrentQuestionIndex(nextQuestionIndex);
            updateBoxIndex(nextQuestionIndex);

            const nextQuestion = questions[nextQuestionIndex];
            setCurrentQuestion(nextQuestion);

            // Reset the selected answer choice for the current question
            selectedAnswer.checked = false;
            setUserSelectedChoice(null);

            // Scroll to the top of the page to show the next question
            //window.scrollTo(0, 0);
        } else {
            alert('Please select an answer before moving to the next question.');
        }
    };

    const handleClear = (questionIndex, selectedAnswer) => {
        if (selectedAnswer) {
            // Reset the selected answer choice for the current question
            selectedAnswer.checked = false;
            setUserSelectedChoice(null);

            // Scroll to the top of the page to show the next question
            //window.scrollTo(0, 0);
        } else {
            alert('Please select an answer before moving to the next question.');
        }
    };

    const handleSaveAndMarkForReview = (questionIndex, question, selectedAnswer) => {
        if (selectedAnswer) {
            const selectedChoice = parseInt(selectedAnswer.value);
            handleSelectAnswer(questionIndex, question, selectedChoice);
            handleReviewQuestions(questionIndex, question, "review");

            const updatedQuestionStatus = [...questionsStatus];
            updatedQuestionStatus[questionIndex] = 'answered-marked-for-review'; // Update to the appropriate status
            setQuestionsStatus(updatedQuestionStatus);

            // Move to the next question
            const nextQuestionIndex = (questionIndex < questions.length - 1) ? questionIndex + 1 : questionIndex;
            setCurrentQuestionIndex(nextQuestionIndex);
            updateBoxIndex(nextQuestionIndex);

            const nextQuestion = questions[nextQuestionIndex];
            setCurrentQuestion(nextQuestion);

            // Reset the selected answer choice for the current question
            selectedAnswer.checked = false;
            setUserSelectedChoice(null);

            // Scroll to the top of the page to show the next question
            //window.scrollTo(0, 0);
        } else {
            alert('Please select an answer before moving to the next question.');
        }
    };

    const handleMarkForReviewAndNext = (questionIndex, question, selectedAnswer) => {
        handleReviewQuestions(questionIndex, question, "review");

        const updatedQuestionStatus = [...questionsStatus];
        updatedQuestionStatus[questionIndex] = 'marked-for-review'; // Update to the appropriate status
        setQuestionsStatus(updatedQuestionStatus);

        // Move to the next question
        const nextQuestionIndex = (questionIndex < questions.length - 1) ? questionIndex + 1 : questionIndex;
        setCurrentQuestionIndex(nextQuestionIndex);
        updateBoxIndex(nextQuestionIndex);

        const nextQuestion = questions[nextQuestionIndex];
        setCurrentQuestion(nextQuestion);

        // Scroll to the top of the page to show the next question
        //window.scrollTo(0, 0);
    };

    const LoadMathJax = () => {
        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://polyfill.io/v3/polyfill.min.js?features=es6'; // Load Polyfill.io for ES6 support
        script.onload = () => {
            // Load MathJAX script
            const mathJaxScript = document.createElement('script');
            mathJaxScript.async = true;
            mathJaxScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.7/MathJax.js?config=TeX-MML-AM_CHTML';
            mathJaxScript.onload = () => {
                window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub]); // Typeset equations once MathJAX is fully loaded
            };
            document.body.appendChild(mathJaxScript);
        };
        document.body.appendChild(script);
    }

    const RemoveMathJax = () => {
        var polyfillScript = document.querySelector('script[src="https://polyfill.io/v3/polyfill.min.js?features=es6"]');
        var mathJaxScript = document.querySelector('script[src="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.7/MathJax.js?config=TeX-MML-AM_CHTML"]');

        if (polyfillScript && polyfillScript.parentNode === document.body) {
            document.body.removeChild(polyfillScript);
        }

        if (mathJaxScript && mathJaxScript.parentNode === document.body) {
            document.body.removeChild(mathJaxScript);
        }
    };

    const scrollToBottom = (index) => {
        const questionContainer = document.getElementById(`question${index}-container`);
        if (questionContainer) {
            questionContainer.scrollTop = questionContainer.scrollHeight;
        }
    };

    const scrollToTop = (index) => {
        const questionContainer = document.getElementById(`question${index}-container`);
        if (questionContainer) {
            questionContainer.scrollTop = 0; // Scroll to the top of the container
        }
    };

    const createQuestionDiv = (questionObj, index) => {

        const selectedChoice = selectedAnswers[index];

        return (
            <>
                <hr />
                <label style={{ fontSize: '16px' }}>Question.{index + 1}</label>
                <label className="pull-right" style={{ marginLeft: '15px' }}></label>
                <span style={{ display: 'none' }}>1</span>
                <hr />
                <div key={index} id={`question${index}`} className="question-container">
                <div style={{ height: '300px', overflowY: 'scroll' }} id={`question${index}-container`}>
                    <button style={{ float: 'right' }} onClick={() => scrollToBottom(index)}>
                        Click to view end of question.
                    </button>
                    <div className="question-text">{questionObj.question}</div>
                    {/* Render table before answer choices */}
                    {questionObj.tableData && (Object.keys(questionObj.tableData).length !== 0) && (
                        <>
                            <table border="1" style={{ marginBottom: '16px' }} class="table table-bordered">
                                <thead>
                                    <tr>
                                        {questionObj.tableData.columnHeaders.map((header, index) => (
                                            <th key={index} style={{ textAlign: 'center', padding: '0px 5px 0px 5px' }}>{header}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {questionObj.tableData.rows.map((row, rowIndex) => (
                                        <tr key={rowIndex}>
                                            {row.map((cell, cellIndex) => (
                                                <td key={cellIndex} style={{ textAlign: 'center' }}>{cell}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <br />
                        </>
                    )}
                    {/* Render image before the question if image path exists */}
                    {questionObj.image && (Object.keys(questionObj.image).length !== 0) && (
                        <>
                            <img src={`/${questionObj.image.src}`} alt={`Question ${index + 1}`} height={"350px"} width={questionObj.image.width || "50%"} />
                            <br /><br />
                        </>
                    )}
                    <button style={{ float: 'right' }} onClick={() => scrollToTop(index)}>
                        Click to view the start of question.
                    </button>
                </div>
                    <br />
                    <div style={{ marginBottom: '24px' }}>
                        {questionObj.choices.map((choice, i) => (
                            <label key={i} className="answer-choice" id={`${index}-${i}`}>
                                <input className="input-radio-button"
                                    type="radio"
                                    name={`answer${index + 1}`}
                                    value={i}
                                    checked={(userSelectedChoice == null) ? (selectedChoice === i) : (userSelectedChoice === i)}
                                    onChange={() => setUserSelectedChoice(i)}
                                />
                                {typeof choice === 'string' ? (choice) : (<img src={`/${choice.src}`} alt={`Choice ${i}`} height="75" width="75" />)}
                            </label>
                        ))}
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            <MathJax>
                <div className="question-container">
                    <div style={{ marginTop: '8px', marginBottom: '8px' }}>Please note: Scroll on the question if the question is not fully visible.</div>
                    <div id="questions-container">
                        {createQuestionDiv(currentQuestion, currentQuestionIndex)}
                        {/* Buttons */}
                        <div className="question-btn-panel">
                            <button className="btn btn-success btn-sm" style={{ marginRight: '10px' }} onClick={() => handleSaveAndNext(currentQuestionIndex, currentQuestion, document.querySelector(`input[name="answer${currentQuestionIndex + 1}"]:checked`))}>Save and Next</button>
                            <button className="btn btn-light btn-sm btn-outline-dark" style={{ marginRight: '10px' }} onClick={() => handleClear(currentQuestionIndex, currentQuestion, document.querySelector(`input[name="answer${currentQuestionIndex + 1}"]:checked`))}>Clear</button>
                            <button className="btn btn-warning btn-sm" style={{ marginRight: '10px', backgroundColor: "#f0ad4e", borderColor: "#f0ad4e", color: "white" }} onClick={() => handleSaveAndMarkForReview(currentQuestionIndex, currentQuestion, document.querySelector(`input[name="answer${currentQuestionIndex + 1}"]:checked`))}>Save and Mark for Review</button>
                            <button className="btn btn-primary btn-sm" style={{ marginRight: '10px' }} onClick={() => handleMarkForReviewAndNext(currentQuestionIndex, currentQuestion, document.querySelector(`input[name="answer${currentQuestionIndex + 1}"]:checked`))}>Mark for Review and Next</button>
                        </div>
                    </div>
                </div>
            </MathJax>
        </>
    );
};

export default QuestionComponent;