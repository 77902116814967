import '../css/sanmacs.webflow.css';
import React, { useState, useEffect } from 'react';
import UserInfoComponent from './helper/UserInfoComponent';
import QuestionComponent from './QuestionComponent';
import QuestionNumberBox from './QuestionNumberBox';
import CountdownComponent from './CountdownComponent';

const QuestionPanel = ({    section, 
                            questions,
                            questionsStatus,
                            setQuestionsStatus,
                            selectedAnswers,
                            setSelectedAnswers,
                            onSubmit,
                            sectionTimer
                        }) => {

    const [boxIndex, setBoxIndex] = useState(0);

    const handleQuestionBoxClick = (index) => {
        setBoxIndex(index);
    };

    const handleBack = () => {
        const previous = boxIndex > 0 ? boxIndex - 1 : boxIndex;
        setBoxIndex(previous);
    };

    const handleNext = () => {
        const next = boxIndex < questions.length - 1 ? boxIndex + 1 : boxIndex;
        setBoxIndex(next);
    };

    const handleSubmit = () => {
        onSubmit();
    };

    const [data, setData] = useState({ date: Date.now(), delay: 210000 });
    const handleTimeUp = () => {
        onSubmit(); // Trigger handleSubmit when time is up
    };

    useEffect(() => {
        setData({ date: Date.now(), delay: sectionTimer })
        setBoxIndex(0);
        const handleBeforeUnload = (event) => {
            //event.preventDefault();
            const confirmationMessage = "Are you sure you want to leave? Your answers will be lost."
            event.returnValue = confirmationMessage; // Standard-compliant browsers will display this message
            return confirmationMessage; // Required for compatibility with older browsers
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [questions]);

    // Assuming boxIndex is the state that determines the first question to display
    useEffect(() => {
        // Update the boxIndex to point to the first question of the new section
        setBoxIndex(0);
    }, [section]);

    return (
        <>
            {!section && <UserInfoComponent />}
            <CountdownComponent
                data={data}
                setData={setData}
                handleTimeUp={handleTimeUp}
            />
            <div className="container-default w-container">
                <div className="well">
                    <div style={{ display: 'flex', height: '100%' }}>
                        {/* Left side: QuestionComponent */}
                        <div style={{ flex: '3' }}>
                            <QuestionComponent
                                questions={questions}
                                boxIndex={boxIndex}
                                updateBoxIndex={setBoxIndex}
                                questionsStatus={questionsStatus}
                                setQuestionsStatus={setQuestionsStatus}
                                selectedAnswers={selectedAnswers}
                                setSelectedAnswers={setSelectedAnswers}
                            />
                            <div className="panel-footer">
                                <div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div>
                                            <button className="btn btn-light btn-sm btn-outline-dark" style={{ marginRight: '10px' }} onClick={handleBack}>{"<< "}Back</button>
                                            <button className="btn btn-light btn-sm btn-outline-dark" style={{ marginRight: '10px' }} onClick={handleNext}>Next{" >>"}</button>
                                        </div>
                                        <button className="btn btn-success" onClick={handleSubmit}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Right side: Question boxes */}
                        <div style={{ flex: '1', maxWidth: '350px', paddingLeft: "56px" }}>
                            <QuestionNumberBox
                                questions={questions}
                                handleQuestionBoxClick={handleQuestionBoxClick}
                                questionsStatus={questionsStatus}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default QuestionPanel;
