import React, { useState } from 'react';
import UserInfoComponent from './helper/UserInfoComponent';
import LogoutButton from './buttons/LogoutButtonComponent';
import HomeButton from './buttons/HomeButton';

const ScoreCardComponent = ({ questions, selectedAnswers, scoreCard, answerDecisions }) => {

    return (
        <>
        <div style={{ display: 'flex', marginBottom: '12px', marginLeft: '12px'}}>
            <LogoutButton />
            <HomeButton />
        </div>
        <UserInfoComponent />
        <div className="panel-body table-responsive">
            <h3 className="text-center">Your ScoreCard</h3>
            <table className="table table-bordered" id="tbl">
                <tbody>
                    <tr>
                        <td>Total Question</td>
                        <th id="lblRTotalQuestion">{questions.length}</th>
                        <td>Total Attempted</td>
                        <th id="lblRTotalAttempted">{scoreCard.questionsAttempted}</th>
                    </tr>
                    <tr>
                        <td>Correct Answers</td>
                        <th id="lblRTotalCorrect">{scoreCard.correctAnswerCount}</th>
                        <td>Incorrect Answers</td>
                        <th id="lblRTotalWrong">{scoreCard.incorrectAnswerCount}</th>
                    </tr>
                    <tr>
                        <td>Score</td>
                        <td id="lblRScore">{scoreCard.totalScore}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div className="col-sm-12">
            <table id="theadres" className="table table-bordered dataTable no-footer" role="grid" aria-describedby="theadres_info">
                <thead>
                    <tr role="row">
                        <th className="sorting" tabIndex="0" aria-controls="theadres" rowspan="1" colSpan="1" aria-label="Question No.: activate to sort column ascending" style={{ width: '0px' }}>Question No.</th>
                        <th className="sorting" tabIndex="0" aria-controls="theadres" rowspan="1" colSpan="1" aria-label="selected Option: activate to sort column ascending" style={{ width: '0px' }}>selected Option</th>
                        <th className="sorting" tabIndex="0" aria-controls="theadres" rowspan="1" colSpan="1" aria-label="Status: activate to sort column ascending" style={{ width: '0px' }}>Status</th>
                        <th className="sorting" tabIndex="0" aria-controls="theadres" rowspan="1" colSpan="1" aria-label="Correct Option: activate to sort column ascending" style={{ width: '0px' }}>Correct Option</th>
                    </tr>
                </thead>
                <tbody id="tbodyResult">
                    {questions.map((question, index) => (
                        <tr key={index} role="row" className={index % 2 === 0 ? 'even' : 'odd'}>
                            <td>Question {index + 1}:</td>
                            <td>{selectedAnswers[question._id] + 1 || '---'}</td>
                            <td>{(answerDecisions[index] === 1) ? "Correct" : (answerDecisions[index] === -1) ? "Incorrect" : '---'}</td>
                            <td>{question.correctAnswer + 1}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
        </>
    )
};

export default ScoreCardComponent;